import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWACoreTemplates } from 'actions/wabriefings/waCoreTemplates';
import constants from 'constants/index';
import { getBrandCountries, getBrands, getLanguages } from 'actions/dashboard';
import EmailMultiSelectFilter from 'components/organisms/EmailMultiSelectFilter';
import SearchIcon from 'images/find.png';
import ToggleButton from 'pages/EmailCampaignTemplate/ToggleButton';
import { get } from 'lodash';

const getSortedFiltersData = (filterList, item, p1 = []) => {
  const list = [...filterList];
  const priority = {};
  // create priority list
  for (let i = 0; i < p1.length; i++) {
    priority[p1[i]] = i;
  }
  if (item) {
    priority[item.value] = p1.length;
  }
  list.sort(
    (a, b) =>
      priority.hasOwnProperty(b.code) - priority.hasOwnProperty(a.code) ||
      priority[a.code] - priority[b.code]
  );
  return list;
};

const WATemplateSearch = ({ id, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [resetId, setResetId] = useState(0);
  const {
    visible = true,
    collapsible,
    setIsVisible,
    enteredText,
    setEnteredText,
    userinfo,
    setUserInfo,
    handleChange,
    isRowSearch,
    waCommsData,
    isEmailCollection,
    isEmailTemplate,
    resetFilterPills = () => {}
  } = props;
  const dispatch = useDispatch();

  /**
   * Trigger search
   * @param {*} query
   */
  const handleSearch = () => {
    dispatch(getWACoreTemplates({ filter: userinfo, text: enteredText }));
  };

  /**
   * Reset query
   * @param {*} evt
   */
  const clearFilter = evt => {
    let resetObject = {
      waType: [],
      countries: [],
      brands: [],
      languages: [],
      templateCards: [],
      templateType: [],
      isEmailCollection: isEmailCollection ? true : false
    };
    setUserInfo(resetObject);
    resetFilterPills();
    dispatch(getWACoreTemplates({ filter: resetObject, text: '' }));
    setEnteredText('');
    // to reset the text in multi select filters
    setResetId(Math.floor(Math.random() * 100 + 1));
  };

  /**
   * on load get brand and countries data required for filter
   */
  useEffect(() => {
    dispatch(getBrandCountries());
    dispatch(getBrands());
    dispatch(getLanguages());
  }, []);

  useEffect(() => {
    if (!userinfo?.isFirstLoad) {
      handleSearch();
      setEnteredText(enteredText);
    }
  }, [userinfo]);

  useEffect(() => {
    if (props.isReset) {
      clearFilter();
      props.resetTrigger();
    }
  }, [props.isReset]);

  const { waCoreTemplates, countries, brands, languages } = useSelector(
    state => ({
      waCoreTemplates: state.waCoreTemplates,
      countries: state.dashboard.countries,
      brands: state.dashboard.brands,
      languages: state.dashboard.languages
    })
  );

  // const { waCoreTemplates: waTemplates } = useSelector(state => ({
  //   waCoreTemplates: state.waCoreTemplates,
  // }));

  const sortedFilters = useMemo(() => {
    if (waCommsData && !isEmailCollection) {
      const waCountry = get(waCommsData, 'country');
      const waBrand = get(waCommsData, 'brand');
      const waLanguage = get(waCommsData, 'language');
      return {
        countries: getSortedFiltersData(
          countries,
          waCountry,
          userinfo?.countries
        ),
        brands: getSortedFiltersData(brands, waBrand, userinfo?.brands),
        languages: getSortedFiltersData(
          languages,
          waLanguage,
          userinfo?.languages
        )
      };
    }
    return {
      countries: getSortedFiltersData(countries, null, userinfo?.countries),
      brands: getSortedFiltersData(brands, null, userinfo?.brands),
      languages: getSortedFiltersData(languages, null, userinfo?.languages)
    };
  }, [waCommsData, countries, brands, languages, userinfo]);

  if (!visible) {
    return (
      <div className="email-template-search container" style={{ padding: 0 }}>
        <ToggleButton visible={visible} setIsVisible={setIsVisible} />
      </div>
    );
  }

  return (
    <>
      {/* search textbox */}
      <div className="email-template-search container">
        {/* search filter - Email Type */}
        <div className="email-template-search-box">
          <div className="cw-listing d-flex flex-grow-1 align-items-center">
            <div className="d-flex flex-grow-1 align-items-center">
              <div className="input-clear">
                <input
                  className="search-input cross"
                  onChange={e => setEnteredText(e.target.value)}
                  placeholder={
                    isRowSearch ? 'Search for tiles' : 'Search for templates'
                  }
                  autoComplete="off"
                  value={enteredText}
                  onKeyPress={e =>
                    e.key === 'Enter' && enteredText ? handleSearch() : null
                  }
                />
                {enteredText.trim().length > 0 && (
                  <span
                    className="input-clear__cross"
                    onClick={() => setEnteredText('')}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                )}
              </div>
              <button className="search-button" onClick={handleSearch}>
                <img src={SearchIcon} alt="Search" />
              </button>
            </div>
          </div>
        </div>
        {collapsible ? (
          <ToggleButton visible={visible} setIsVisible={setIsVisible} />
        ) : null}
        <div className="row email-template-search__info">
          <div className="col-md-9">
            {waCoreTemplates.data.type === 'fallback_blank' ? (
              <span className="email-template-search__info--text">
                0 {'templates'}
              </span>
            ) : null}
            {waCoreTemplates.data.type === 'api_success' ? (
              <span className="email-template-search__info--text">
                {waCoreTemplates?.data?.data?.pagination?.total} {'templates'}
              </span>
            ) : null}
          </div>
          <div className="col-md-3">
            {waCoreTemplates?.data?.isFilter ||
            (isEmailTemplate && waCoreTemplates?.data?.data?.isFilter) ? (
              <button
                className="cw-link-btn cw-formdraft-link"
                onClick={clearFilter}
              >
                Clear all
              </button>
            ) : null}
          </div>
        </div>

        {/* search filter - Country */}
        <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={sortedFilters.countries}
            onChangeHandler={handleChange}
            fieldname="countries"
            heading="Countries"
            placeholder="Search for country.."
            count={userinfo?.countries?.length || 0}
            filters={userinfo?.countries}
            collapse={true}
            isActive={activeIndex === 0}
            onShow={() => setActiveIndex(activeIndex === 0 ? -1 : 0)}
            resetText={resetId}
          />
        </div>

        {/* search filter - Brand */}
        <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={sortedFilters.brands}
            onChangeHandler={handleChange}
            fieldname="brands"
            heading="Brands"
            placeholder="Search for brands.."
            count={userinfo?.brands?.length || 0}
            filters={userinfo?.brands}
            collapse={true}
            isActive={activeIndex === 1}
            onShow={() => setActiveIndex(activeIndex === 1 ? -1 : 1)}
            resetText={resetId}
          />
        </div>

        {/* search filter - Languages */}
        <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={sortedFilters.languages}
            onChangeHandler={handleChange}
            fieldname="languages"
            heading="Languages"
            placeholder="Search for language.."
            count={userinfo?.languages?.length || 0}
            filters={userinfo?.languages}
            collapse={true}
            isActive={activeIndex === 2}
            onShow={() => setActiveIndex(activeIndex === 2 ? -1 : 2)}
            resetText={resetId}
          />
        </div>

        {/* search filter - Campaign Type */}
        {props.isRawShow ? null : (
          <div className="email-template-filter">
            <EmailMultiSelectFilter
              filterListData={constants.WA_TYPE_OPTIONS}
              onChangeHandler={handleChange}
              fieldname="waType"
              heading="Campaign type"
              hideSearch={true}
              count={userinfo?.waType?.length || 0}
              filters={userinfo?.waType}
              collapse={false}
              isActive={activeIndex === 3}
              onShow={() => setActiveIndex(activeIndex === 3 ? -1 : 3)}
              resetText={resetId}
            />
          </div>
        )}

        {/* search filter - Template cards */}
        {props.isRawShow ? null : (
          <div className="email-template-filter">
            <EmailMultiSelectFilter
              filterListData={[
                { label: 'Single card messages', value: 'single' },
                { label: 'Carousal messages', value: 'carousel' }
              ]}
              onChangeHandler={handleChange}
              fieldname="templateCards"
              heading="Template cards"
              hideSearch={true}
              count={userinfo?.templateCards?.length || 0}
              filters={userinfo?.templateCards}
              collapse={false}
              isActive={activeIndex === 4}
              onShow={() => setActiveIndex(activeIndex === 4 ? -1 : 4)}
              resetText={resetId}
            />
          </div>
        )}

        {/* search filter - Template type */}
        {props.isRawShow ? null : (
          <div className="email-template-filter">
            <EmailMultiSelectFilter
              filterListData={[
                {
                  label: 'Text',
                  value: 'headerText'
                },
                {
                  label: 'Image',
                  value: 'headerImage'
                },
                {
                  label: 'Video',
                  value: 'headerVideo'
                },
                {
                  label: 'Document',
                  value: 'headerDocument'
                },
                {
                  label: 'Location',
                  value: 'headerLocation'
                }
              ]}
              onChangeHandler={handleChange}
              fieldname="templateType"
              heading="Template type"
              hideSearch={true}
              count={userinfo?.templateType?.length || 0}
              filters={userinfo?.templateType}
              collapse={false}
              isActive={activeIndex === 5}
              onShow={() => setActiveIndex(activeIndex === 5 ? -1 : 5)}
              resetText={resetId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default WATemplateSearch;
