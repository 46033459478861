import classNames from 'classnames';
import { Input } from 'components/atoms/Input';
import React from 'react';
import { components } from 'react-select';
import { Icon } from 'semantic-ui-react';

const InputWithCustomOption = ({
  id,
  name,
  value,
  onChange,
  icon,
  labelClass,
  label,
  isFontAwesome,
  className,
  subLabel,
  isImage,
  imageSrc
}) => {
  return (
    <>
      {isImage ? (
        <img src={imageSrc} alt={label} />
      ) : isFontAwesome ? (
        <i className={icon}></i>
      ) : (
        <Icon name={icon} size="large" />
      )}
      <p className="ml-10">{label}</p>
    </>
  );
};

export const CustomOptionV2 = ({
  getStyles,
  isFocused,
  isSelected,
  children,
  innerProps,
  isDisabled,
  ...rest
}) => {
  const onClickMultiOption = e => {
    rest.selectOption({ ...rest.data });
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <components.Option
      {...rest}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
      isDisabled={isDisabled}
    >
      <div onClick={onClickMultiOption} className="d-flex align-items-center">
        <InputWithCustomOption
          id={children}
          name={rest.selectProps.name}
          label={children}
          subLabel={rest.data.subLabel}
          value={isSelected}
          onChange={() => {}}
          icon={rest.data.icon}
          isFontAwesome={rest.data.isFontAwesome}
          isImage={rest.data.isImage}
          imageSrc={rest.data.imageSrc}
        />
      </div>
    </components.Option>
  );
};
