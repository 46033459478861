import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
import WACampaignScheduleDetails from 'pages/WACampaignScheduleDetails';

const WALiveModel = ({
  modalIsOpen,
  closeModal,
  closeModelLiveClick,
  waDetailsOverview,
  pathUid
}) => {
  const {
    name = null,
    waType,
    country = {},
    brand = {},
    isCrossBrand = false
  } = waDetailsOverview;
  const campaignName = name || 'Untitled';
  const [success, setSuccess] = useState(false);

  const brandDisplay = isCrossBrand
    ? 'Cross brand'
    : Array.isArray(brand)
    ? brand[0].name
    : brand.label;

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModelLiveClick}
        className="cw-modal cw-modal--schedule-sms-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button
          className="cw-modal--close"
          title="Close"
          onClick={closeModelLiveClick}
        >
          <i className="fas fa-times"></i>
        </button>
        <h3 className="cw-heading--senary">Mark WhatsApp campaign live</h3>
        {success ? (
          <>
            {' '}
            <p className="mb-10 mt-10 email-summary-font">
              {' '}
              Thank you, for marking the campaign as live.
            </p>
            <p className="email-summary-font">
              We will notify the requestor that the WhatsApp campaign is Live in
              ACS!
            </p>
            <button
              type="submit"
              onClick={closeModal}
              className="btn btn btn-primary mt-30"
            >
              Close
            </button>
          </>
        ) : (
          <>
            <p className="mb-10 mt-10 email-summary-font">
              {' '}
              This WhatsApp send scheduled for today. Please mark the campaign
              live once it is live in ACS .
            </p>
            <p className="font-weight-bold email-summary-font">
              Campaign summary
            </p>
            <h2 className={`cw-email-heading`}>
              <span className="email-highlight campaign-break-word">
                {campaignName}
              </span>
            </h2>
            <p className="email-summary-font">
              {constants.WA_TYPE_LABELS[waType]}
            </p>
            <p className="email-summary-font">
              {country.label}, {brandDisplay}
            </p>
            <br />
            <WACampaignScheduleDetails
              pathUid={pathUid}
              closeModal={closeModelLiveClick}
              smsDetailsOverview={waDetailsOverview}
              setSuccess={setSuccess}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default WALiveModel;
