import React from 'react';
import { get } from 'lodash';
import ExternalLinkIcon from '../../../images/external-link.png';

export const getCaroCardProperties = (data, WAHeaderTypeLabel) => {
  const properties = [];
  const headerType = get(data, 'cardHeader.type');
  properties.push({
    label: 'Card header',
    value: WAHeaderTypeLabel[headerType],
    mustShow: true
  });
  if (headerType === 'headerImage') {
    const url = get(data, 'cardHeader.headerImage');
    properties.push({
      label: 'Header image',
      value: url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
          <div className="external-link-wrapper">
            <img
              src={ExternalLinkIcon}
              alt="External Link"
              className="icon external-link-icon"
            />
          </div>
        </a>
      ) : (
        ''
      ),
      mustShow: true
    });
  }

  if (headerType === 'headerVideo') {
    properties.push({
      label: 'Header video',
      value: get(data, 'cardHeader.headerVideo'),
      mustShow: true
    });
  }
  properties.push({
    label: 'Body text',
    value: get(data, 'cardBodyText'),
    mustShow: true
  });

  properties.push({
    label: 'Meta template',
    value: get(data, 'cardBodyTextSerialFormated'),
    mustShow: true
  });

  const waDesignButtons = get(data, 'buttons', []);

  if (waDesignButtons.length) {
    waDesignButtons.forEach((item, index) => {
      properties.push({
        label: `Button ${index + 1}`,
        value: `${get(item, 'type.value')} - ${get(item, 'buttonName')} - ${get(
          item,
          'buttonValue'
        )}`,
        mustShow: true
      });
    });
  }
  return properties;
};

const CardDisplay = ({ data, WAHeaderTypeLabel }) => {
  const properties = getCaroCardProperties(data, WAHeaderTypeLabel);

  return (
    <div className="mb-2">
      {properties.map(property => (
        <div
          className="email-tabular__row row"
          key={'campaigndetails_' + property.label}
        >
          <div className="col-sm-3">
            <strong className="email-tabular__label">{property.label}</strong>
            <span className="email-tabular__subLabel">{property.subLabel}</span>
          </div>
          <div className={`col-sm-9 email-tabular__value`}>
            {property.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardDisplay;
