import React, { useRef } from 'react';
import MobileDeviceFrame from '../MobileDeviceFrame';

export default function Card({
  thumbnail,
  template,
  uid,
  hideOnError,
  title,
  gradient,
  style: styleProps = {},
  imageStyle,
  noShadow,
  button = {}
}) {
  const { label, onClick } = button;
  const mobileFrameRef = useRef();
  const style = {
    ...styleProps,
    ...(noShadow ? { boxShadow: 'none', borderRadius: 0 } : {}),
    paddingTop: '1px',
    paddingBottom: '1px'
  };

  if (!thumbnail && gradient) {
    return <GradientWithMesssage style={style} gradient={gradient} />;
  }

  if (hideOnError && !thumbnail) {
    return null;
  }
  return (
    <div className="email-card" style={style}>
      {label ? (
        <button className="email-card-button btn btn-primary" onClick={onClick}>
          {label}
        </button>
      ) : null}
      <MobileDeviceFrame
        ref={mobileFrameRef}
        data={{ ...template.waDesign, brand: template.brand }}
        showActions={false}
        showNote={false}
      />
    </div>
  );
}

const GradientWithMesssage = ({
  style,
  gradient,
  message = 'Preview will be available once designed'
}) => {
  return (
    <div
      className="email-card--blank"
      style={{
        ...style,
        background: gradient
      }}
    >
      <p className="preview-not-availabe card-error mt-10">{message}</p>
    </div>
  );
};
