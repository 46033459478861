import {
  ASYNC_WA_COMMS_IN_PROGRESS,
  SET_WA_COMMS,
  UNSET_WA_COMMS
} from '../../actionTypes';

const initialState = {
  waCommsData: {},
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ASYNC_WA_COMMS_IN_PROGRESS: {
      return { ...state, isLoading: action.isLoading };
    }

    case SET_WA_COMMS: {
      let commsDetails = action.payload;
      return {
        ...state,
        waCommsData: { ...state.waCommsData, ...commsDetails }
      };
    }

    case UNSET_WA_COMMS: {
      return { ...state, waCommsData: {} };
    }

    default:
      return state;
  }
}
