import React, { useEffect } from 'react';
import WATemplateThumbnail from 'components/molecules/WATemplateThumbnail';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Pagination from 'components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { SET_SELECTED_TEMPLATE } from 'actionTypes';
import { isEmpty, get } from 'lodash';
import Loader from 'components/Loader';
import { checkIsEmailAdminOrACS } from 'selectors';

const WATemplateList = ({
  templates: waTemplates,
  userEmail,
  userName,
  onReset,
  total,
  recordShowStart,
  recordShowto,
  page,
  match,
  limit,
  onPageChange,
  handleTemplatePreview,
  hideOverflow,
  hideOverlay,
  hideSelectBtn,
  hideBlankTemplate,
  hideNameDescirption,
  hideOverflowSelectBtn,
  hideResumeTemplate,
  isEmailTemplate,
  waCommsData
}) => {
  const { isLoading } = waTemplates;

  const dispatch = useDispatch();

  useEffect(() => {
    const uid = match.params.id;
  }, []);

  const givenTemplate = get(waCommsData, 'waDesign');
  const { authorizedUserData } = useSelector(state => state.authorized);
  const doesGivenTemplateExists =
    !isEmpty(givenTemplate) && !!get(givenTemplate, 'templateId');
  const isEmailAdminOrACS = checkIsEmailAdminOrACS(authorizedUserData);
  let templates = waTemplates.data.data && waTemplates.data.data.data;

  if (hideBlankTemplate && templates && templates.length) {
    templates = templates.filter(e => e.templateName !== 'blank-template');
  }

  return (
    <div className="email-template-list">
      {isLoading ? <Loader /> : null}
      <p className="mb-3">
        {!isLoading && waTemplates.data.type === 'fallback_blank' ? (
          <span className="no-record-title">
            {isEmailTemplate
              ? 'No matching live emails found'
              : 'No matching templates found'}
          </span>
        ) : null}
      </p>
      {!isLoading && waTemplates.data.type === 'api_success' && (
        <>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 991: 2, 1023: 2, 1200: 3 }}
          >
            {!doesGivenTemplateExists ? (
              <Masonry gutter={'15px'}>
                {templates.map(template => {
                  return (
                    <WATemplateThumbnail
                      key={template._id}
                      type="template"
                      doesGivenTemplateExists={doesGivenTemplateExists}
                      template={template}
                      userEmail={userEmail}
                      userName={userName}
                      handleTemplatePreview={handleTemplatePreview}
                      hideOverflow={hideOverflow}
                      hideOverlay={hideOverlay}
                      hideSelectBtn={hideSelectBtn}
                      hideNameDescirption={hideNameDescirption}
                      hideOverflowSelectBtn={hideOverflowSelectBtn}
                      isEmailTemplate={isEmailTemplate}
                      isEmailAdminOrACS={isEmailAdminOrACS}
                    />
                  );
                })}
              </Masonry>
            ) : (
              <Masonry gutter={'15px'}>
                {templates.slice(0, 1).map(template => {
                  return (
                    <WATemplateThumbnail
                      key={template._id}
                      type="template"
                      doesGivenTemplateExists={doesGivenTemplateExists}
                      template={template}
                      userEmail={userEmail}
                      userName={userName}
                      handleTemplatePreview={handleTemplatePreview}
                      hideOverflow={hideOverflow}
                      hideOverlay={hideOverlay}
                      hideSelectBtn={hideSelectBtn}
                      hideNameDescirption={hideNameDescirption}
                      hideOverflowSelectBtn={hideOverflowSelectBtn}
                      isEmailAdminOrACS={isEmailAdminOrACS}
                    />
                  );
                })}
                {doesGivenTemplateExists && !hideResumeTemplate ? (
                  <WATemplateThumbnail
                    isSelectedTemplate
                    key={givenTemplate._id}
                    type="template"
                    doesGivenTemplateExists={doesGivenTemplateExists}
                    template={givenTemplate}
                    userEmail={userEmail}
                    userName={userName}
                    handleTemplatePreview={handleTemplatePreview}
                    hideOverflow={hideOverflow}
                    hideOverlay={hideOverlay}
                    hideSelectBtn={hideSelectBtn}
                    hideNameDescirption={hideNameDescirption}
                    hideOverflowSelectBtn={hideOverflowSelectBtn}
                    isEmailTemplate={isEmailTemplate}
                    isEmailAdminOrACS={isEmailAdminOrACS}
                  />
                ) : null}
                {templates.slice(1).map(template => {
                  return (
                    <WATemplateThumbnail
                      key={template._id}
                      type="template"
                      doesGivenTemplateExists={doesGivenTemplateExists}
                      template={template}
                      userEmail={userEmail}
                      userName={userName}
                      handleTemplatePreview={handleTemplatePreview}
                      hideOverflow={hideOverflow}
                      hideOverlay={hideOverlay}
                      hideSelectBtn={hideSelectBtn}
                      hideNameDescirption={hideNameDescirption}
                      hideOverflowSelectBtn={hideOverflowSelectBtn}
                      isEmailTemplate={isEmailTemplate}
                      isEmailAdminOrACS={isEmailAdminOrACS}
                    />
                  );
                })}
              </Masonry>
            )}
          </ResponsiveMasonry>
          {isLoading || isNaN(recordShowStart) ? (
            <span>&nbsp;</span>
          ) : (
            <Pagination
              itemLabel="templates"
              pageNumber={Number(page)}
              totalRecords={total}
              limit={limit}
              recordShowStart={recordShowStart}
              recordShowto={recordShowto}
              onPageChange={onPageChange}
            />
          )}
        </>
      )}
      {waTemplates.data.type === 'fallback_blank' && (
        <div className="d-flex">
          {waTemplates.data.data &&
            waTemplates.data.data.map(template => {
              return (
                <WATemplateThumbnail
                  key={template._id}
                  type="template"
                  doesGivenTemplateExists={doesGivenTemplateExists}
                  givenTemplate={givenTemplate}
                  handleTemplatePreview={handleTemplatePreview}
                  template={template}
                  isfallback={true}
                  onReset={onReset}
                  userEmail={userEmail}
                  userName={userName}
                  isEmailTemplate={isEmailTemplate}
                  isEmailAdminOrACS={isEmailAdminOrACS}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default withRouter(WATemplateList);
