import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import CampaignRequestInfoSchema from './waCampaignRequestInfoSchema';

const WACampaignRequestInfo = ({
  pathUid,
  closeModal,
  setToggle,
  smsDetails,
  toggle
}) => {
  const {
    isLoading,
    uid,
    deliverySchedule,
    status,
    smsType,
    requestInfoText
  } = smsDetails;
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal,
    setToggle,
    defaultValues: deliverySchedule || {},
    smsType,
    status,
    requestInfoText,
    toggle
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={CampaignRequestInfoSchema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default WACampaignRequestInfo;
