import React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import ImageTemplateBlank from 'images/email-template-blank.png';
import { getDateInDDmmmYYYY, getDeliveryCondition } from 'selectors';
import constants from '../../constants';
import EmailStatus from 'components/atoms/EmailStatus/EmailStatus';
import { EmailBrandDisplay } from 'components/atoms';
import { Icon } from 'semantic-ui-react';
import CampaignPreview from 'images/campaign-preview.svg';

const PREVIEW_TYPE = {
  CAMPAIGN: 'campaign',
  TEMPLATE: 'template'
};

const TemplatePreviewOverlay = ({
  isOpen,
  onCloseModal,
  handleCloseForm,
  campaignName,
  category,
  brand,
  country,
  isCrossBrand,
  status,
  requestersEmail,
  previewOf = PREVIEW_TYPE.TEMPLATE,
  primaryButtonTitle = 'Select',
  selectedTemplateForPreview: template,
  onPreviewSelect,
  hideSelectBtn,
  campaignUpdatedAt,
  deliverySchedule,
  abTesting,
  winningVariant,
  requestorsName
}) => {
  const { performABTests, abTestElements, noOfABVariants } = abTesting || {};
  const isABTestingOnContent =
    performABTests && abTestElements === 'emailContent';
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  Modal.setAppElement('#root');
  const hasTimestamp = item => {
    if (item?.updatedAt) {
      return true;
    } else if (item?.createdAt) {
      return true;
    } else {
      return false;
    }
  };
  const getTimestamp = item => {
    if (['BEE', 'CW'].includes(item.templateType)) {
      return getDateInDDmmmYYYY(item.templateCreatedOn);
    } else {
      return getDateInDDmmmYYYY(
        previewOf === PREVIEW_TYPE.CAMPAIGN ? campaignUpdatedAt : item.createdAt
      );
    }
  };

  const getCategory = givenTemplate => {
    return previewOf === PREVIEW_TYPE.CAMPAIGN
      ? category
      : constants.emailType[givenTemplate?.category] || givenTemplate?.category;
  };

  const variantOptions = [...constants.EMAIL_ABTEST_VARIANT_OPTIONS]
    .slice(0, noOfABVariants)
    .map(option => ({
      ...option,
      label:
        winningVariant === option.value
          ? option.label + ' (Winner)'
          : option.label
    }));

  const defaultVariantSelection = isABTestingOnContent
    ? winningVariant
      ? variantOptions.find(option => option.value === winningVariant)
      : variantOptions[0]
    : null;

  const [selectedVarinat, setSelectedVariant] = React.useState(
    defaultVariantSelection
  );

  let templatePreviewSrc = template?.thumbnail;

  if (
    template?.name?.trim() === 'blank-template' &&
    previewOf === PREVIEW_TYPE.TEMPLATE
  ) {
    templatePreviewSrc = ImageTemplateBlank;
  }

  if (selectedVarinat && template?.variantThumbnails?.length < noOfABVariants) {
    if (selectedVarinat.value === 'variantA' && !winningVariant) {
      templatePreviewSrc = template?.thumbnail;
    } else {
      templatePreviewSrc = null;
    }
  }

  if (selectedVarinat && template?.variantThumbnails?.length > 0) {
    templatePreviewSrc = template?.variantThumbnails?.find(
      option => option.variant === selectedVarinat.value
    )?.image;
  }

  const brandName = Array.isArray(template?.brand)
    ? template?.brand?.map(b => b.label)
    : template?.brand?.label;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--template-preview"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="template-preview-overlay">
        <div>
          <button
            className={`cw-modal--close ${
              previewOf === PREVIEW_TYPE.CAMPAIGN ? 'close-icon' : ''
            }`}
            title="Close"
            onClick={() => {
              setSelectedVariant(defaultVariantSelection);
              handleCloseForm();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3
            className={`cw-heading--primary template-title mt-2 ${
              previewOf === PREVIEW_TYPE.TEMPLATE ? 'mb-20' : 'mb-10'
            }`}
          >
            <strong
              className="mb-1"
              style={{
                flex: 1,
                minWidth: 0,
                flexBasis: 0,
                wordBreak: 'break-word',
                lineHeight: 'normal'
              }}
            >
              {previewOf === PREVIEW_TYPE.TEMPLATE
                ? template.title
                : campaignName}
            </strong>
            {status ? (
              <div className="ml-2">
                <EmailStatus small status={status} />
              </div>
            ) : null}
          </h3>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div
            className={`${
              previewOf === PREVIEW_TYPE.CAMPAIGN ? PREVIEW_TYPE.CAMPAIGN : ''
            }`}
            style={{ flex: 1, minWidth: 0, flexBasis: 0 }}
          >
            <p className="template-category-text">{getCategory(template)}</p>
            {template?.brand && template.country && (
              <p className="template-info-text">
                <EmailBrandDisplay
                  brand={brandName}
                  country={template.country?.label}
                  isCrossBrand={
                    template.templateUsage === 'crossBrand' ||
                    template.isCrossBrand
                  }
                  showAllBrands={true}
                />
              </p>
            )}
            {brand && country && (
              <p className="template-info-text">
                <EmailBrandDisplay
                  brand={brand}
                  country={country}
                  isCrossBrand={isCrossBrand}
                  showAllBrands={true}
                />
              </p>
            )}
          </div>
          {!hideSelectBtn && (
            <button
              className="btn btn-primary ml-2 mt-2"
              style={
                previewOf === PREVIEW_TYPE.CAMPAIGN
                  ? { width: 100 }
                  : { marginTop: 15 }
              }
              onClick={() => {
                onPreviewSelect && onPreviewSelect(template);
              }}
            >
              {primaryButtonTitle}
            </button>
          )}
        </div>

        {previewOf === PREVIEW_TYPE.CAMPAIGN && (
          <p className="template-info-text">
            {getDeliveryCondition(deliverySchedule, isABTestOnSendTime).map(
              text => (
                <div>{text}</div>
              )
            )}
          </p>
        )}

        {isABTestingOnContent && (
          <div className="form-group" aria-live="polite">
            <Select
              className="react-select-container mt-3"
              classNamePrefix="react-select"
              name="thumbnailVariant"
              placeholder={'Select variant'}
              options={variantOptions}
              value={selectedVarinat}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              onChange={val => setSelectedVariant(val)}
            />
          </div>
        )}

        <div className="mt-25 mb-15 text-center template-preview-box">
          {templatePreviewSrc ? (
            <img
              src={templatePreviewSrc}
              className="template-preview-image"
              alt={template.title}
            />
          ) : (
            <>
              <img src={CampaignPreview} alt={`${campaignName} preview`} />
              <p className="mt-20 text-center preview-text">
                Preview will be available once designed
              </p>
            </>
          )}
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <div className="text-right">
            {(template?.createdBy?.emailId ||
              template?.requestorsName || 
              requestersEmail || requestorsName) && (
              <p className="template-meta-info">
                Requestor:{' '}
                {previewOf === PREVIEW_TYPE.TEMPLATE
                  ? template?.createdBy?.name ||
                     template?.requestorsName  || 
                    requestorsName || ''
                  : requestorsName}
              </p>
            )}
            {hasTimestamp(template) && (
              <p className="template-meta-info">
                Updated on: {getTimestamp(template)}
              </p>
            )}
            {template?.creditCount && (
              <p className="template-meta-info">
                <Icon color="grey" name="chart line" size="large" /> Used{' '}
                {template.creditCount}{' '}
                {template.creditCount > 1 ? ' times' : ' time'}
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplatePreviewOverlay;
