import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import CampaignSheduleSchema from './waCampaignScheduleSchema';

const WACampaignScheduleDetails = ({
  pathUid,
  closeModal,
  smsDetailsOverview,
  setSuccess
}) => {
  const {
    isLoading,
    uid,
    deliverySchedule,
    abTesting,
    status,
    waType,
    isCrossBrand,
    nmiAssignedToRequestor,
    communicationDetail,
    shareCostEstimate
  } = smsDetailsOverview;
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal,
    defaultValues: deliverySchedule || {},
    abTesting,
    waType,
    setSuccess,
    status,
    isCrossBrand,
    nmiAssignedToRequestor,
    communicationDetail,
    shareCostEstimate: shareCostEstimate || {}
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={CampaignSheduleSchema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default WACampaignScheduleDetails;
