import React from 'react';
import { get } from 'lodash';
import constants from 'constants/config';

const profileFieldLabelMap2 = {
  mobileNumber: 'Mobile Number',
  email: 'Email Address'
};

export const GetCondValidationMessage = ({
  elem,
  isProfileField,
  isEpsilonMarket,
  index,
  noMargin
}) => {
  const requiredFields = get(
    elem,
    'validation.conditionalRequired.fields',
    null
  );
  const condition = get(elem, 'validation.conditionalRequired.condition', null);
  const similarConsentList = get(elem, 'validation.conditionalRequired.similarConsentList', null);
  const fieldList = [];
  const currentFieldLabel = (similarConsentList && similarConsentList.length > 1)? similarConsentList.map((item, j) => {
    if(j > 0) {
      return (<>
        {' '}
        or{' '}
        <strong>
          {item}
        </strong>
      </>)
    } else {
      return (
        <strong>
          {item}
        </strong>)
    }
    
  }) :  (<strong>{elem.label}</strong>);

  if (requiredFields) {
    requiredFields.map((item, i) => {
      if (i > 0) {
        fieldList.push(
          <>
            {' '}
            {condition}{' '}
            <strong>
              {isProfileField
                ? constants.CONSENTS_KEY_LABEL_MAP[item]
                : profileFieldLabelMap2[item]}
            </strong>
          </>
        );
      } else {
        fieldList.push(
          <strong>
            {isProfileField
              ? constants.CONSENTS_KEY_LABEL_MAP[item]
              : profileFieldLabelMap2[item]}
          </strong>
        );
      }
    });
  }
  return (
    <label
      htmlFor={isEpsilonMarket ? `${elem.optInType}_${index}` : undefined}
      style={noMargin? { marginLeft: '0px', marginBottom: '0px' } : { marginLeft: '60px' }}
    >
      Conditionally mandatory: if {fieldList} is provided then,{' '}
      {currentFieldLabel} is mandatory
    </label>
  );
};

const ConditionallyMandatoryMassage = ({
  elem,
  isEpsilonMarket,
  handleEpsilonMandatoryRequiredCheckbox,
  profile,
  index,
  isProfileField,
  noMargin,
  incentiveType
}) => {
  const validationMessageText = get(
    elem,
    'validation.conditionalRequired.validationMessage'
  );

  const isIncentiveSamples = incentiveType === "Product samples"

  if (isProfileField) {
    return (
      (elem.dataType === 'email' || elem.dataType === 'mobileNumber') &&
      !profile[index].mandatoryToggle &&
      validationMessageText && (
        <div className={noMargin? undefined : "cond-required-text"}>
          <GetCondValidationMessage elem={elem} noMargin={noMargin} isProfileField />
        </div>
      )
    );
  } else {
    return (
      validationMessageText &&
      elem.consentType === 'optional' && (
        <div className={noMargin? undefined : "form-group cond-required-text"}>
          {isEpsilonMarket || isIncentiveSamples ? (
            <>
              <div className="form-check">
                <div className="cols-sm-4 mb-10">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      elem.validation && elem.validation.mandatory
                        ? true
                        : false
                    }
                    // defaultChecked={el.isChecked}
                    // disabled={el.disabled}
                    onChange={e => {
                      handleEpsilonMandatoryRequiredCheckbox(e, index);
                    }}
                    name={`${elem.optInType}_${index}`}
                    id={`${elem.optInType}_${index}`}
                  />
                  <GetCondValidationMessage
                    elem={elem}
                    isEpsilonMarket={true}
                    index={index}
                    noMargin={noMargin}
                  />
                </div>
              </div>
            </>
          ) : (
            <GetCondValidationMessage elem={elem} noMargin={noMargin}/>
          )}
        </div>
      )
    );
  }
};

export default ConditionallyMandatoryMassage;
