import React, { forwardRef, useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';
import { CustomOptionV2 } from '../ReactSelectOption';
import moment from 'moment';
import classNames from 'classnames';
import { EnhancedTextArea } from 'components/molecules/EnhancedTextArea';
import { Icon } from 'semantic-ui-react';
import Select from 'react-select';
import GenerateImageInput from './GenerateImageInput';
import banSolid from '../../../images/ban-solid.png';
import fontSolid from '../../../images/font-solid.png';
import imageRegular from '../../../images/image-regular.png';
import fileVideoRegular from '../../../images/file-video-regular.png';
import fileAltRegular from '../../../images/file-alt-regular.png';
import locationRegular from '../../../images/location-dot-solid.png';
const waHeaderList = [
  {
    id: 'headerNone',
    value: 'headerNone',
    icon: 'fas fa-ban drop-down-icon',
    //icon: 'dont',
    label: 'None',
    isImage: true,
    imageSrc: banSolid
  },
  {
    id: 'headerText',
    value: 'headerText',
    //icon: 'font',
    icon: 'fas fa-font drop-down-icon',
    label: 'Text',
    isImage: true,
    imageSrc: fontSolid
  },
  {
    id: 'headerImage',
    value: 'headerImage',
    //icon: 'image outline',
    icon: 'far fa-image drop-down-icon',
    label: 'Image',
    isImage: true,
    imageSrc: imageRegular
  },
  {
    id: 'headerVideo',
    value: 'headerVideo',
    //icon: 'file video outline',
    icon: 'far fa-file-video drop-down-icon',
    label: 'Video',
    isImage: true,
    imageSrc: fileVideoRegular
  },
  {
    id: 'headerDocument',
    value: 'headerDocument',
    //icon: 'file alternate outline',
    icon: 'far fa-file-alt drop-down-icon',
    label: 'Document',
    isImage: true,
    imageSrc: fileAltRegular
  },
  {
    id: 'headerLocation',
    value: 'headerLocation',
    icon: 'fa-solid fa-location-dot',
    label: 'Location',
    isImage: true,
    imageSrc: locationRegular
  }
];

const getOptionFromList = value => {
  return waHeaderList.filter(item => item.value === value);
};

const WaToggleHeaderTextField = ({
  control,
  watchInput,
  errors,
  name,
  register,
  dateFormat,
  defaultValue,
  setValue,
  abTesting,
  disabled
}) => {
  let callBackFn = null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setTabImageUrl] = useState();
  const defaultType = get(defaultValue, 'type');
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });
  const currentDate = moment();

  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  const handleSubmitImagePath = (url, frm) => {
    console.log('called---', url, frm);
    setIsModalOpen(false);
    setTabImageUrl(url);
    setValue(`${name}.headerImage`, url);
  };

  return triggerType === 'headerNone' ? (
    ''
  ) : triggerType === 'headerText' ? (
    <div className="pt-0">
      <div className="pt-2 ml-22 pt-0 cw-form-nested">
        <EnhancedTextArea
          name={`${name}.headerText`}
          id="headerText"
          defaultValue={(defaultValue && defaultValue['headerText']) || ''}
          control={control}
          label="Header text"
          errors={errors}
          maxLength={60}
          maxLengthDisplay={60}
          setValue={setValue}
          flow="wa"
          isDynamicCoupon={false}
          optOutTextLength={0}
          insertingURLMain={false}
          hideInsertUrl={true}
          fnameOnlyOnce={true}
          showEmojiButton={true}
          showAddVariables={true}
          disabled={disabled}
          defaultValueDynamicVar={defaultValue?.headerTextDynamicVariables}
        />
      </div>
    </div>
  ) : triggerType === 'headerImage' ? (
    <>
      <Controller
        name={`${name}.headerImage`}
        control={control}
        defaultValue={get(defaultValue, `headerImage`)}
        render={({ field }) => (
          <GenerateImageInput
            {...field}
            errors={errors}
            defaultValue={defaultValue}
            setValue={setValue}
            fieldPrefix={`${name}`}
            disabled={disabled}
          />
        )}
      />
    </>
  ) : triggerType === 'headerVideo' ? (
    <>
      <p>headerVideo show</p>
    </>
  ) : triggerType === 'headerDocument' ? (
    <>
      <p>headerDocument</p>
    </>
  ) : triggerType === 'headerLocation' ? (
    <>
      <div className="pt-0">
        <div className="pt-2 ml-22 pt-0 cw-form-nested">
          {/* Location Name */}
          <div className="mt-10">
            <Controller
              name={`${name}.headerLocation.locationName`}
              control={control}
              defaultValue={get(defaultValue, `headerLocation.locationName`)}
              render={({ field }) => (
                <div>
                  <label htmlFor="locationName">Location Name</label>
                  <Input
                    id="locationName"
                    defaultValue={get(
                      defaultValue,
                      `headerLocation.locationName`
                    )}
                    {...field}
                    type="text"
                    label="Location Name"
                    placeholder="Enter location name"
                    hasError={
                      !!get(errors, `${name}.headerLocation.locationName`)
                    }
                    maxLength={100}
                    disabled={disabled}
                  />
                  <FieldError
                    name={`${name}.headerLocation.locationName`}
                    errors={errors}
                  />
                </div>
              )}
            />
          </div>

          {/* Location Address */}
          <div className="mt-10">
            <Controller
              name={`${name}.headerLocation.locationAddress`}
              control={control}
              defaultValue={get(defaultValue, `headerLocation.locationAddress`)}
              render={({ field }) => (
                <div>
                  <label htmlFor="locationAddress">Location Address</label>
                  <Input
                    id="locationAddress"
                    defaultValue={get(
                      defaultValue,
                      `headerLocation.locationAddress`
                    )}
                    {...field}
                    type="text"
                    label="Location Address"
                    placeholder="Enter location address"
                    hasError={
                      !!get(errors, `${name}.headerLocation.locationAddress`)
                    }
                    maxLength={250}
                    disabled={disabled}
                  />
                  <FieldError
                    name={`${name}.headerLocation.locationAddress`}
                    errors={errors}
                  />
                </div>
              )}
            />
          </div>

          {/* Location Latitude */}
          <div className="mt-10">
            <Controller
              name={`${name}.headerLocation.locationLatitude`}
              defaultValue={get(
                defaultValue,
                `headerLocation.locationLatitude`
              )}
              control={control}
              render={({ field }) => (
                <div>
                  <label htmlFor="locationLatitude">
                    Location Latitude
                    <small className="form-text">
                      Latitude should be in decimal degrees format and range
                      between -90 and +90. For e.g., 41.40338
                    </small>
                  </label>
                  <Input
                    id="locationLatitude"
                    defaultValue={get(
                      defaultValue,
                      `headerLocation.locationLatitude`
                    )}
                    {...field}
                    type="text"
                    maxLength={10}
                    label="Location Latitude"
                    placeholder="Enter location latitude"
                    hasError={
                      !!get(errors, `${name}.headerLocation.locationLatitude`)
                    }
                    onChange={e => {
                      const value = e.target.value;
                      const regex = /^[+-]?\d*\.?\d*$/;
                      if (regex.test(value)) {
                        field.onChange(e);
                      }
                      field.onChange(e);
                    }}
                    disabled={disabled}
                  />
                  <FieldError
                    name={`${name}.headerLocation.locationLatitude`}
                    errors={errors}
                  />
                </div>
              )}
            />
          </div>

          {/* Location Longitude */}
          <div className="mt-10">
            <Controller
              name={`${name}.headerLocation.locationLongitude`}
              control={control}
              defaultValue={get(
                defaultValue,
                `headerLocation.locationLongitude`
              )}
              render={({ field }) => (
                <div>
                  <label htmlFor="locationLongitude">
                    Location Longitude
                    <small className="form-text">
                      Longitude should be in decimal degrees format and range
                      between -180 and +180. For e.g., 2.17403
                    </small>
                  </label>
                  <Input
                    id="locationLongitude"
                    defaultValue={get(
                      defaultValue,
                      `headerLocation.locationLongitude`
                    )}
                    {...field}
                    type="text"
                    label="Location Longitude"
                    placeholder="Enter location longitude"
                    maxLength={10}
                    hasError={
                      !!get(errors, `${name}.headerLocation.locationLongitude`)
                    }
                    onChange={e => {
                      const value = e.target.value;
                      const regex = /^[+-]?\d*\.?\d*$/;
                      if (regex.test(value)) {
                        field.onChange(e);
                      }
                      field.onChange(e);
                    }}
                    disabled={disabled}
                  />
                  <FieldError
                    name={`${name}.headerLocation.locationLongitude`}
                    errors={errors}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

const WaToggleRadioInput = forwardRef(
  ({ name, value, waType, onChange }, ref) => {
    console.log(
      '>>>>>> WaToggleRadioInput: name-',
      name,
      ' value:',
      value,
      ' waType:',
      waType
    );
    const handleOnChange = e => {
      onChange(e.target.value);
    };
    return (
      <div className="themes-container d-flex flex-wrap">
        <label
          htmlFor="headerNone"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerNone'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              type="radio"
              id="headerNone"
              value="headerNone"
              checked={value === 'headerNone'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="dont" size="large" />
              <p className="theme-label text-size-small">{'None'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerText"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerText'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              type="radio"
              id="headerText"
              value="headerText"
              checked={value === 'headerText'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="font" size="large" />
              <p className="theme-label text-size-small">{'Text'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerImage"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerImage'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerImage"
              value="headerImage"
              type="radio"
              checked={value === 'headerImage'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="image outline" size="large" />
              <p className="theme-label text-size-small">{'Image'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerVideo"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerVideo'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerVideo"
              value="headerVideo"
              type="radio"
              checked={value === 'headerVideo'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="file video outline" size="large" />
              <p className="theme-label text-size-small">{'Video'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerDocument"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerDocument'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerDocument"
              value="headerDocument"
              type="radio"
              checked={value === 'headerDocument'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="file alternate outline" size="large" />
              <p className="theme-label text-size-small">{'Document'}</p>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

export const WaToggleInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  waType,
  abTesting,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const headerTypeError = get(errors, `${name}.type`);
  const hasError = !!(errors && errorMessages);
  const hasHeaderError = !!(errors && headerTypeError);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const getSelectedOption = value => {
    console.log('=========== 1111 ==========');
    if (value === 'newsletter' || value === 'coreinfo') {
      return 'fixedDateAndtime';
    } else if (value === 'welcomeSMS' || value === 'birthdaySMS') {
      return 'dynamicTrigger';
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (defaultValue) {
      if (
        get(defaultValue, 'type') === 'fixedDateAndtime' &&
        (waType === 'welcomeSMS' || waType === 'birthdaySMS')
      ) {
        setValue(`${name}.type`, 'dynamicTrigger');
      } else {
        setValue(
          `${name}.type`,
          get(defaultValue, 'waHeader.type', 'headerNone')
        );
      }
    } else {
      if (waType) {
        const selectedOption = getSelectedOption(waType);
        if (selectedOption) {
          setValue(`${name}.type`, selectedOption);
        }
      }
    }
  }, [defaultValue, waType]);

  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(defaultValue, `${'carousel'}.messageType`)
  });
  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(defaultValue, `templateUse`)
  });

  const isInputDisabled = templateUse === 'reuse';
  if (messageType && messageType.value === 'carousel') return null;

  return (
    <div
      className={classNames(
        'form-group email-secondary-theme',
        props.formGroupWrapperClass
      )}
      aria-live="polite"
    >
      <label htmlFor={props.id}>{label}</label>

      <div>
        {/* <Controller
          name={`${name}.type`}
          control={control}
          // defaultValue={get(defaultValue, `${name}.type`)}
          defaultValue={defaultValue?.waHeader?.type || 'headerNone'}
          render={({ field }) => (
            <WaToggleRadioInput {...field} waType={waType} />
          )}
        /> */}
        <Controller
          name={`${name}.type`}
          control={control}
          defaultValue={defaultValue?.waHeader?.type || 'headerNone'}
          render={({ field }) => (
            <Select
              className={classNames('react-select-container', {
                'reactselect-invalid': hasHeaderError
              })}
              classNamePrefix="react-select"
              {...field}
              options={waHeaderList}
              placeholder="Select header type"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition={'absolute'}
              components={{
                Option: CustomOptionV2
              }}
              value={getOptionFromList(field.value)}
              onChange={val => {
                field.onChange(val.value);
              }}
              defaultValue={defaultValue?.waHeader?.type || 'headerNone'}
              isDisabled={isInputDisabled}
            />
          )}
        />
        <WaToggleHeaderTextField
          control={control}
          watchInput={`${name}.type`}
          errors={errors}
          register={register}
          name={name}
          dateFormat={props.dateFormat}
          defaultValue={defaultValue?.waHeader}
          setValue={setValue}
          abTesting={abTesting}
          disabled={isInputDisabled}
        />
      </div>

      <FieldError errors={errors} name={`${name}.type`} />
    </div>
  );
};
