import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '../../atoms/Input';
import {
  useFieldArray,
  useWatch,
  Controller,
  useController
} from 'react-hook-form';
import { FieldError } from '../../atoms/FieldError';
import { get, set, cloneDeep } from 'lodash';
import Select from 'react-select';
import { FormReactSelect } from '../../molecules/FormReactSelect';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import classNames from 'classnames';
import WAButtonGuidelines from 'components/molecules/WAButtonGuidelines';
import { WAButtons } from 'components/molecules/FormFieldArray/WAButtons';
import { ChildList } from './ChildList';
import { AddButtonMenu } from './AddButtonMenu';

export const WaNestedButtons = ({
  name,
  type,
  rules,
  register,
  label,
  subLabel,
  errors,
  control,
  defaultValue,
  maxRow,
  isMulti,
  waDesign,
  getValues,
  setValue,
  ...props
}) => {
  const defaultValuesInit = {
    waButtons: [
      {
        value: '0',
        id: 'nonQuickReply',
        nestedArray: [] //nestedArray: [{ value: "0", id: "11" }]
      },
      {
        value: '0',
        id: 'quickReply',
        nestedArray: [] //nestedArray: [{ value: "1", id: "21" }]
      }
    ]
  };

  const { fields, append, move, remove, replace } = useFieldArray({
    control,
    name: name
  });

  useEffect(() => {
    const val =
      defaultValue && defaultValue.length
        ? defaultValue
        : defaultValuesInit.waButtons;
    // sets the DB value
    replace(val);
    // setValue(name, val);
  }, [defaultValue]);

  const childrenRef = React.useRef({});
  const watchFieldArray = useWatch({ control, name: name });
  // const controlledFields = fields.map((field, index) => {
  //   return { ...field, ...watchFieldArray[index] };
  // });
  const watchLTO = useWatch({
    control,
    name: 'waOffer.waLtoDetails.ltoEnable'
  });
  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(waDesign, `templateUse`)
  });
  const isInputDisabled = templateUse === 'reuse';
  useEffect(() => {
    if (watchLTO) {
      // add copy btn in buttons
      if (getValues && props.waCouponType) {
        const data = getValues();
        const btnList1 = get(data, 'waButtons');
        const btnList2 =
          btnList1 && btnList1.findIndex(item => (item.id = 'nonQuickReply'));
        if (btnList2 !== -1) {
          const oldBtnlist = get(data, `waButtons.${btnList2}.nestedArray`);
          if (oldBtnlist) {
            const newBtnList = [
              ...oldBtnlist,
              {
                type: 'Copy code button',
                buttonName: 'Coupon code',
                buttonValue:
                  props.waCouponType === 'staticCoupon'
                    ? props.waCouponCode
                    : ''
              }
            ];
            setValue(`waButtons.${btnList2}.nestedArray`, newBtnList);
          }
        }
      }
    } else {
      // remove btn if not lto
      if (!props.waCouponType && !props.waCouponCode) {
        const data = getValues();
        const btnList1 = get(data, 'waButtons');
        const btnList2 =
          btnList1 && btnList1.findIndex(item => (item.id = 'nonQuickReply'));
        if (btnList2 !== -1) {
          const oldBtnlist = get(data, `waButtons.${btnList2}.nestedArray`);
          if (oldBtnlist) {
            const newBtnList = [...oldBtnlist].filter(
              item => item.type !== 'Copy code button'
            );
            setValue(`waButtons.${btnList2}.nestedArray`, newBtnList);
          }
        }
      }
    }
  }, [watchLTO]);
  const setReorder = React.useCallback(
    (index, reorderCallback) => {
      childrenRef.current[index] = reorderCallback;
    },
    [childrenRef]
  );
  const [
    isButtonGuidelinesModalOpen,
    setIsButtonGuidelinesModalOpen
  ] = useState(false);

  const [buttonCount, setButtonCount] = useState(0);
  const [waButtonItem, setWAButtonItem] = useState([]);

  // to hide add button
  const hideAddButton = !(
    fields.length >= (isMulti && maxRow ? maxRow : props.waCouponType ? 5 : 4)
  );

  const countObjectsByType = (sourceArray, elementType) => {
    let elementTypeCount = 0;
    let totalNestedArrayCount = 0;
    sourceArray &&
      sourceArray.length &&
      sourceArray.forEach(item => {
        if (!item.nestedArray) return;
        totalNestedArrayCount += item.nestedArray.length;
        elementTypeCount += item.nestedArray.filter(
          nestedItem => nestedItem.type === elementType
        ).length;
      });
    return {
      elementTypeCount,
      totalNestedArrayCount
    };
  };

  const currentVal = getValues();
  const { elementTypeCount, totalNestedArrayCount } = countObjectsByType(
    currentVal.waButtons,
    'quickreply'
  );
  useEffect(() => {
    setButtonCount(totalNestedArrayCount);
    setWAButtonItem(currentVal.waButtons);
  }, [totalNestedArrayCount]);

  /******** added bug WTG-15112 *******/
  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(waDesign, `${'carousel'}.messageType`)
  });
  if (messageType && messageType.value === 'carousel' && !isMulti) return null;
  /********bug WTG-15112 ******/

  // - Flag to show a common error for row
  const showCommonErrors = props.showCommonErrors;
  // - Delete button
  const ButtonRemove = ({ index }) => {
    return (
      <div className="form-group row-action">
        <button
          type="button"
          className="btn-circle btn-remove ml-10 mt-30"
          onClick={() => remove(index)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  };
  const handleGuidelinesModal = e => {
    e.preventDefault();
    setIsButtonGuidelinesModalOpen(true);
  };
  const ButtonGuidelinesLink = () => {
    return (
      <button className="btn btn-primary-link" onClick={handleGuidelinesModal}>
        See guidelines
      </button>
    );
  };

  //--------- Drag N Drop using react-beautiful-dnd -------------------------
  const reorder = result => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    if (type === 'parentContainer') {
      move(sourceIndex, destIndex);
    } else if (type && type.includes('childContainer') && source.droppableId) {
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };

  const addQuickReply = append => {
    append({ value: '0' });
  };

  //-------- new child

  return (
    <div className={classNames(props.formGroupWrapperClass)}>
      {label && (
        <label htmlFor={props.id}>
          {label}
          {subLabel && (
            <div className="description mb-20">
              {subLabel} {!isMulti ? <ButtonGuidelinesLink /> : null}
            </div>
          )}
        </label>
      )}
      <div className="wa-field-array-nested">
        {!totalNestedArrayCount ? (
          <p className="no-button-text">No buttons have been added</p>
        ) : (
          <DragDropContext onDragEnd={reorder}>
            <Droppable droppableId="parent" type="parentContainer">
              {provided => (
                <ul
                  className="container"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {fields.map((item, index) => {
                    const errorMessages = get(errors, `${name}.${index}`);
                    const hasError = !!(errors && errorMessages);
                    const nestedElementLength =
                      currentVal.waButtons[index].nestedArray.length;
                    const nestedElementType = currentVal.waButtons[index].id;
                    return (
                      <Draggable
                        key={`parentContainer.${item.id}`}
                        draggableId={item.id}
                        index={index}
                        isDragDisabled={isInputDisabled}
                      >
                        {provided => (
                          <li
                            key={`parentContainer.row.${item.id}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={
                              hasError
                                ? 'd-flex justify-content-start align-items-center mt-10 wa-buttons-row-nested row errors'
                                : 'd-flex justify-content-start align-items-center mt-10 wa-buttons-row-nested row'
                            }
                          >
                            {nestedElementLength ? (
                              <>
                                <div className="w-100 row-nested-header">
                                  <span
                                    className={
                                      nestedElementLength
                                        ? 'parent-drag'
                                        : 'parent-drag hidden'
                                    }
                                    {...provided.dragHandleProps}
                                  >
                                    <i className="fas fa-align-justify cw-color--primary"></i>
                                  </span>
                                  <p className="row-nested-header_text">
                                    {nestedElementType === 'quickReply'
                                      ? `Quick reply buttons`
                                      : `Other buttons`}
                                  </p>
                                </div>
                                {/* Nested buttons  */}
                                <ChildList
                                  setReorder={setReorder}
                                  nestIndex={index}
                                  addButtonHandler={addQuickReply}
                                  errors={errors}
                                  getValues={getValues}
                                  setValue={setValue}
                                  {...{ control, register }}
                                  disabled={isInputDisabled}
                                />
                              </>
                            ) : null}
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
      {/* <!-- Split dropup button --> */}
      <AddButtonMenu
        getValues={getValues}
        setValue={setValue}
        waCouponType={props.waCouponType}
        waCouponCode={props.waCouponCode}
        buttonCount={buttonCount}
        waButtonItem={waButtonItem}
        disabled={isInputDisabled}
      />

      <WAButtonGuidelines
        isOpen={isButtonGuidelinesModalOpen}
        handleCloseForm={() => setIsButtonGuidelinesModalOpen(false)}
      />
    </div>
  );
  //--------- DND using react-beautiful-dnd -------------------------
};

// TODO: make it external
const Inputvalue = ({
  control,
  watchValue,
  name,
  index,
  register,
  onChangeHandler
}) => {
  const value = useWatch({ control, name: watchValue });
  //onChangeHandler(value)
  switch (value.value) {
    case 'Website URL':
      return (
        <>
          <label htmlFor="">{'Website URL'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Target URL"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    case 'Copy coupon code':
      return (
        <>
          <label htmlFor="">{'Copy coupon code'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Coupon Code"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    case 'Phone number':
      return (
        <>
          <label htmlFor="">{'Phone number'}</label>
          <input
            className="form-control"
            type="number"
            placeholder="Phone Number"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={20}
            form="novalidatedform"
          />
        </>
      );
    case 'Unsubscribe quick reply':
      return (
        <>
          <label htmlFor="">{'Unsubscribe quick reply'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Unsubscribe quick reply"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    default:
      return null;
  }
};
