import CheckboxDropDown from '../../../../components/CheckboxDropDown';
import React from 'react';
import FilterWrapper from '../FilterWrapper/FilterWrapper';

export default function CountryFilter({
  asyncInProgress,
  name,
  value,
  onChange,
  options,
  isEmailCampaign,
  classNamePrefix
}) {
  return (
    <FilterWrapper>
      <CheckboxDropDown
        isMulti={true}
        className={
          isEmailCampaign
            ? 'react-multi-select-container'
            : 'cw-dashboard-filter'
        }
        classNamePrefix={classNamePrefix}
        isEmailCampaign={isEmailCampaign}
        menuIsOpen={false}
        name={name || 'brandcountry'}
        label={'Country'}
        hideSelectedOptions={false}
        placeholder={'Country'}
        isDisabled={asyncInProgress}
        isLoading={asyncInProgress}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={false}
      />
    </FilterWrapper>
  );
}
