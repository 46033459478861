import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '../../atoms/Input';
import {
  useFieldArray,
  useWatch,
  Controller,
  useController
} from 'react-hook-form';
import { FieldError } from '../../atoms/FieldError';
import { get, set, cloneDeep } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';

export const ChildList = ({
  name,
  type,
  rules,
  register,
  label,
  subLabel,
  errors,
  defaultValue,
  maxRow,
  isMulti,
  waDesign,
  nestIndex,
  control,
  setReorder,
  addButtonHandler,
  getValues,
  setValue,
  ...props
}) => {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `waButtons.${nestIndex}.nestedArray`
  });

  React.useEffect(() => {
    setReorder(`child-${nestIndex}`, (from, to) => {
      move(from, to);
    });
  }, [nestIndex, setReorder, move]);

  const buttonText = {
    'Quick reply - Others': 'Quick reply text',
    'Quick reply - Unsubscribe': 'Unsubscribe text',
    'Copy code button': 'Copy code button text',
    'Website URL': 'Website URL',
    'Phone number button': 'Phone number'
  };

  const buttonRowConfig = {
    'Quick reply - Others': 'Quick reply text',
    'Quick reply - Unsubscribe': 'Quick reply - Unsubscribe',
    'Copy code button': 'Copy code button text',
    'Website URL': {
      label: 'Website URL',
      buttonName: { maxLength: '25', buttonType: 'text', isDisabled: false },
      buttonValue: { maxLength: '2000', buttonType: 'text', isDisabled: false }
    },
    'Phone number button': 'Phone number'
  };

  const ButtonRemove = ({ index }) => {
    return (
      <div className="form-group row-action">
        <button
          type="button"
          className="btn-circle btn-remove ml-10 mt-30"
          onClick={() => remove(index)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  };

  return (
    <div className="child-wrap">
      <Droppable
        droppableId={`child-${nestIndex}`}
        type={`childContainer.${nestIndex}`}
      >
        {provided => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {fields && fields.length
              ? fields.map((item, k) => {
                  const errorMessages = get(
                    errors,
                    `waButtons.${nestIndex}.nestedArray.${k}`
                  );
                  const hasError = !!(errors && errorMessages);

                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={k}
                      isDragDisabled={props.disabled}
                    >
                      {provided => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={
                            'd-flex justify-content-start align-items-center row row-child'
                          }
                        >
                          <span
                            className={'child-drag'}
                            {...provided.dragHandleProps}
                          >
                            <i className="fas fa-align-justify cw-color--primary"></i>
                          </span>
                          <div className={'col-4 form-group'}>
                            <label>Button type</label>
                            <input
                              type="text"
                              name={`waButtons.${nestIndex}.nestedArray.${k}.type`}
                              defaultValue={item.type}
                              {...register(
                                `waButtons.${nestIndex}.nestedArray.${k}.type`
                              )}
                              className={'form-control'}
                              maxLength={25}
                              disabled={true}
                            />
                          </div>
                          <div className={'col-4 form-group'}>
                            <label>Button name</label>
                            {item.type === 'Copy code button' ? (
                              <>
                                <ReactTooltip
                                  id="copyCodeTooltip"
                                  place="right"
                                  type="info"
                                  className={`cw-toolip custom-cw-toolip w-auto`}
                                />
                                <span
                                  data-tip="Copy code button text is not configurable and is fixed by META for various languages."
                                  data-for="copyCodeTooltip"
                                  className="btn-primary-link"
                                >
                                  <i className="fas fa-question-circle"></i>
                                </span>
                              </>
                            ) : null}
                            <Controller
                              name={`waButtons.${nestIndex}.nestedArray.${k}.buttonName`}
                              control={control}
                              defaultValue={item.type}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  {...field}
                                  className={
                                    errorMessages && errorMessages['buttonName']
                                      ? 'form-control cw-error-focus is-invalid'
                                      : 'form-control'
                                  }
                                  maxLength={25}
                                  disabled={
                                    props.disabled ||
                                    item.type === 'Copy code button'
                                  }
                                  onChange={e => {
                                    field.onChange(e.target.value);
                                    if (
                                      item.type ===
                                        'Quick reply - Unsubscribe' ||
                                      item.type === 'Quick reply - Others'
                                    ) {
                                      setValue(
                                        `waButtons.${nestIndex}.nestedArray.${k}.buttonValue`,
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className={'col-4 form-group'}>
                            <label>{buttonText[item.type]}</label>
                            <input
                              type={'text'}
                              name={`waButtons.${nestIndex}.nestedArray.${k}.buttonValue`}
                              defaultValue={item.value}
                              {...register(
                                `waButtons.${nestIndex}.nestedArray.${k}.buttonValue`,
                                item.type === 'Phone number button'
                                  ? {
                                      maxLength: {
                                        value: 20,
                                        message:
                                          'Phone number cannot exceed 20 digits'
                                      },
                                      pattern: {
                                        value: /^\d+$/,
                                        message:
                                          'Only numeric values are allowed'
                                      }
                                    }
                                  : {}
                              )}
                              className={
                                errorMessages && errorMessages['buttonValue']
                                  ? 'form-control cw-error-focus is-invalid'
                                  : 'form-control'
                              }
                              maxLength={
                                item.type === 'Website URL'
                                  ? 2000
                                  : item.type === 'Copy code button'
                                  ? 15
                                  : item.type === 'Phone number button'
                                  ? 20
                                  : 25
                              }
                              onInput={e => {
                                e.target.value =
                                  item.type === 'Phone number button'
                                    ? e.target.value
                                        .replace(/\D/g, '')
                                        .slice(0, 20)
                                    : e.target.value;
                              }}
                              disabled={
                                props.disabled ||
                                item.type === 'Quick reply - Others' ||
                                item.type === 'Quick reply - Unsubscribe' ||
                                item.type === 'Copy code button'
                              }
                            />{' '}
                            {/* {item.type === 'Quick reply - Others' ||
                            item.type === 'Quick reply - Unsubscribe' ? (
                              <p className="hint-text email-label-subtext">
                                Same as button name
                              </p>
                            ) : null} */}
                            {!props.disabled && <ButtonRemove index={k} />}
                          </div>

                          <div className={'col-4'}></div>
                          <div className={'col-4'}>
                            <FieldError
                              errors={errors}
                              name={`waButtons.${nestIndex}.nestedArray.${k}.buttonName`}
                            />
                          </div>
                          <div className={'col-4'}>
                            <FieldError
                              errors={errors}
                              name={`waButtons.${nestIndex}.nestedArray.${k}.buttonValue`}
                            />
                          </div>
                        </li>
                      )}
                    </Draggable>
                  );
                }) //fields.map
              : null}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </div>
  );
};
