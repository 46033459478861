//import React from 'react';
import React from 'react';
import { connect } from 'react-redux';
import logo from '../images/CW_logo.svg';
//import logo from '../images/SSO-logo.png';

import constant from '../constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Notification from './organisms/Notification/Notification';
import moment from 'moment';
import { toggleSidebar } from 'actions/app';
import NavUserMenu from 'components/organisms/NavUserMenu/NavUserMenu';
import HeaderNavMenu from './organisms/HeaderNavMenu';
import { getHeaderNavMenuItems } from '../selectors';
import { UnstyledButton } from './atoms/UnstyledButton';

//export default Header;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownExpand: false,
      notificationClass: this.getNotificationClass()
    };
    this.expandCollapseRef = React.createRef();
  }

  handleUserDropdown = () => {
    this.setState({ dropdownExpand: !this.state.dropdownExpand });
  };

  getClassNames = (className, isShort, shrink, isNoHeader) => {
    return classNames({
      [className]: true,
      [className + '--short']: isShort,
      [className + '--shrink']: !isShort && shrink,
      [className + '--hide']: isNoHeader
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.campaignConfig.adminConfig !==
      this.props.campaignConfig.adminConfig
    ) {
      const notificationClass = this.getNotificationClass();
      this.setState({
        notificationClass: notificationClass
          ? notificationClass
          : 'notification-hidden '
      });
    }
  }

  getNotificationClass = () => {
    const isClosedByUser = sessionStorage.getItem('closedByUser');
    let notificationClass = 'notification-hidden ';
    const { adminConfig } = this.props.campaignConfig;
    if (adminConfig && adminConfig.length > 0) {
      for (const item of adminConfig) {
        if (item.enabled) {
          const toCompareDate = moment();
          const startDate = moment(new Date(item.startDate), 'DD/MM/YYYY');
          const endDate = moment(new Date(item.endDate), 'DD/MM/YYYY');
          notificationClass =
            toCompareDate.isBetween(startDate, endDate) && !isClosedByUser
              ? 'notification-visible '
              : 'notification-hidden ';
          if (toCompareDate.isBetween(startDate, endDate) && !isClosedByUser) {
            return notificationClass;
          }
        }
      }
    }
    return notificationClass;
  };
  notificationCloseHandler = () => {
    this.setState({ notificationClass: 'notification-hidden ' });
  };

  render() {
    const {
      location,
      authorizedUserData,
      shortHeaderRoutes,
      noHeaderRoutes,
      shrink,
      campaignConfig: { adminConfig } = {}
    } = this.props;

    const isShort = shortHeaderRoutes.some(route =>
      location.pathname.includes(route)
    );

    const isNoHeader = noHeaderRoutes.some(route =>
      location.pathname.includes(route)
    );

    return (
      <div
        className={
          this.state.notificationClass +
          this.getClassNames('cw-header-wrapper', isShort, shrink, isNoHeader) +
          ' pinkpurple'
        }
      >
        <div
          className={`cw-print-hide ${this.getClassNames(
            'cw-header-container',
            isShort,
            shrink
          )}`}
        >
          <Notification
            notificationHandler={this.notificationCloseHandler}
            notifications={adminConfig}
          />
          <div className="d-flex">
            <UnstyledButton
              className="nav-hamburger"
              onClick={() => {
                this.props.toggleSidebar();
              }}
            >
              {this.props.isSideBarOpen ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-bars"></i>
              )}
            </UnstyledButton>

            <div
              className={`cw-section  ${this.getClassNames(
                'cw-header',
                isShort,
                shrink
              )}`}
              id="cw-scrollTop"
            >
              <div className="d-flex align-items-center flex-grow-1">
                <a
                  href="/campaign-wizard"
                  className={`${this.getClassNames(
                    'cw-header__text',
                    isShort,
                    shrink
                  )}`}
                  title="Campaign Wizard"
                >
                  <img
                    src={logo}
                    alt="Campaign Wizard logo"
                    className={this.getClassNames('cw-logo', isShort, shrink)}
                  />
                  <span>Campaign Wizard</span>
                </a>

                {/* {authorizedUserData.group ===
                constant.userManagementLinkAccessProfile &&
              authorizedUserData.loginAccess ? (
                <div
                  onClick={this.handleUserDropdown}
                  style={{ color: '#007DBB', cursor: 'pointer' }}
                >
                  Admin
                  <i
                    className={`fas ml-1 ${
                      this.state.dropdownExpand
                        ? 'fa-chevron-up'
                        : 'fa-chevron-down'
                    }`}
                    ref={this.expandCollapseRef}
                  ></i>
                  <div className="dropdown">
                    <div
                      className={`dropdown-menu ${
                        this.state.dropdownExpand ? 'expand' : 'collapse'
                      }`}
                    >
                      <a
                        className="dropdown-item bold"
                        href="/campaign-wizard/user"
                      >
                        User Management
                      </a>
                      <hr className="hrnew" />
                      <a
                        className="dropdown-item bold"
                        href="/campaign-wizard/brand-mgmt"
                      >
                        Brand Management
                      </a>
                      <hr className="hrnew" />
                      <a
                        className="dropdown-item bold"
                        href="/campaign-wizard/report-mgmt-v2"
                      >
                        Reports
                      </a>
                    </div>
                  </div>
                </div>
              ) : null} */}
                <HeaderNavMenu
                  menuItems={getHeaderNavMenuItems(authorizedUserData)}
                />
              </div>

              {authorizedUserData.group ===
                constant.userManagementLinkAccessProfile &&
              authorizedUserData.loginAccess ? (
                <NavUserMenu userType={authorizedUserData.group} />
              ) : (
                <NavUserMenu userType={authorizedUserData.group} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  shrink: PropTypes.bool,
  shortHeaderRoutes: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = state => ({
  authorizedUserData: state.authorized.authorizedUserData,
  isSideBarOpen: state.app.isSideBarOpen,
  campaignConfig: state.app.campaignConfig
});

export default withRouter(connect(mapStateToProps, { toggleSidebar })(Header));
