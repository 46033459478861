import axios from 'axios';
import _, { orderBy } from 'lodash';
import constant from '../constants';
import { filterToQuery } from '../selectors';
const httpProtocolPrefix =
  process.env.NODE_ENV === 'development' ? 'http' : 'https';

let controller = new AbortController();

// TODO : change according to the WA dashboard filter api
export async function getWABriefingList(filters = {}) {
  const response = await axios.get(
    `${constant.serviceUrls.WA_COMMS_URL}/list${filterToQuery(filters)}`
  );

  return response.data;
}

export async function createWABriefing(data) {
  try {
    const response = await axios.post(constant.serviceUrls.WA_COMMS_URL, data);
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function getWABriefingbyUid(uid, options) {
  try {
    if (!uid) {
      throw new Error('uid is required!');
    }
    let response = await axios.get(
      `${constant.serviceUrls.WA_COMMS_URL}/${uid}` + filterToQuery(options)
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function deleteWABriefingbyUid(data) {
  try {
    if (!data.uid) {
      throw new Error('uid is required!');
    }
    let response = await axios.post(
      `${constant.serviceUrls.WA_COMMS_URL}/delete`,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function updateWABriefing(uid, data) {
  try {
    let response = await axios.post(
      `${constant.serviceUrls.WA_COMMS_URL}/${uid}`,
      data
    );
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function downloadWACostEstimate(uid) {
  try {
    let response = await axios.get(
      `${constant.serviceUrls.DOWNLOAD_WA_COST_ESTIMATE}?uid=${uid}`,
      { responseType: 'blob' }
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function downloadWAPurchaseOrder(uid) {
  try {
    let response = await axios.get(
      `${constant.serviceUrls.DOWNLOAD_WA_PURCHASE_ORDER}?uid=${uid}`,
      { responseType: 'blob' }
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function uploadWACoupon(data) {
  try {
    const response = await axios.post(
      constant.serviceUrls.WA_COMMS_URL + '/couponupload',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('uploadWACoupon e: ', e.message);
    throw e;
  }
}

export async function waUploadFileWithStatusService(data, jsonData) {
  try {
    let response = await axios.post(
      `${constant.serviceUrls.WA_COMMS_URL}/updateFileUploadAndStatus`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function getWACoreTemplates(query, pageNumber) {
  let filter =
    typeof query === 'object'
      ? '?search=' + query.text + '&' + paramsSerializer(query.filter)
      : query
      ? '?search=' + query
      : '';
  filter += `${filter ? '&' : '?'}page=${pageNumber}`;

  controller.abort();
  controller = new AbortController();
  const response = await axios.get(
    `${constant.serviceUrls.WA_CORE_TEMPLATES_URL}` + filter,
    { signal: controller.signal }
  );
  return response.data;
}

function paramsSerializer(params) {
  return Object.entries(Object.assign({}, params))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export async function saveWACoreTemplate(data) {
  try {
    const response = await axios.post(
      `${constant.serviceUrls.WA_CORE_TEMPLATES_URL}/save`,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function deleteWACoreTemplate(id) {
  const response = await axios.get(
    `${constant.serviceUrls.WA_CORE_TEMPLATES_URL}/${id}/delete`
  );
  return response.data;
}

export async function selectWATemplate(uid, data) {
  console.log('uid: ', uid);
  console.log('data: ', data);
  try {
    let response = await axios.post(
      `${constant.serviceUrls.WA_COMMS_URL}/select-template/${uid}`,
      data
    );
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function getWATemplateById(id, getByTemplateId) {
  const response = await axios.get(
    `${constant.serviceUrls.WA_CORE_TEMPLATES_URL}/${id}${
      getByTemplateId ? '?field=templateId' : ''
    }`
  );
  return response.data;
}
