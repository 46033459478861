import React from 'react';
import WAChatHeader from '../WAChatMessage/WAChatHeader';
import WAChatBody from '../WAChatMessage/WAChatBody';
import WAChatFooter from '../WAChatMessage/WAChatFooter';
import WAChatButtons from '../WAChatMessage/WAChatButtons';
import WAChatLTO from '../WAChatMessage/WAChatLTO';
import moment from 'moment';
import waBgImg from '../../../../images/whatsapp_background.jpg';

import { get } from 'lodash';
const WAScreen = ({ data }) => {
  const showMessageBox = () => {
    if (get(data, 'waBodyText')) {
      return true;
    }

    if (get(data, 'waFooterText')) {
      return true;
    }

    if (get(data, 'waHeader.type')) {
      if (get(data, 'waHeader.type') !== 'none') {
        return true;
      }
    }
    return false;
  };

  const getSendDate = () => {
    const trigger = get(data, 'deliverySchedule.waTrigger.type');
    let date = moment().format('D MMMM yyyy');
    if (trigger === 'fixedDateAndtime') {
      const sendDate = get(
        data,
        'deliverySchedule.waTrigger.details.waDeliveryDate'
      );
      if (sendDate) {
        date = moment(sendDate).format('D MMMM yyyy');
      }
    }
    if (trigger === 'dynamicTrigger') {
      const sendDate = get(
        data,
        'deliverySchedule.waTrigger.details.triggerConditionStartDate'
      );
      if (sendDate) {
        date = moment(sendDate).format('D MMMM yyyy');
      }
    }
    return date;
  };

  const hasCarousel = get(data, 'carousel.messageType.value') === 'carousel';
  const hasCarouselCards = get(data, 'carousel.carouselCards', []).length;
  const carousalMainMessage = get(data, 'carousel.mainMessageBubbleText');

  const hasLTOOffer = get(data, 'waOffer.waLtoDetails.ltoEnable', false);

  const waReplaceTags = (waBodyTextDynamicVariables, waBodyTextFormated) => {
    waBodyTextDynamicVariables?.forEach(variable => {
      const { tagValue, value } = variable;
      waBodyTextFormated = waBodyTextFormated.replace(
        new RegExp(tagValue, 'g'),
        value
      );
    });
    return waBodyTextFormated;
  };
  let waBodyFormatted = '';
  let waCarousalMainMessageFormatted = '';

  if (data?.waBodyTextDynamicVariables && data?.waBodyText) {
    waBodyFormatted = waReplaceTags(
      data.waBodyTextDynamicVariables,
      data.waBodyText
    );
  }

  if (carousalMainMessage) {
    waCarousalMainMessageFormatted = waReplaceTags(
      data.carousel.mainMessageBubbleTextDynamicVariables,
      carousalMainMessage
    );
  }

  return (
    <div className="screen">
      <div className="screen-container">
        <div className="status-bar">
          <div className="time">2:30</div>
          <div className="battery">
            <i className="zmdi zmdi-battery"></i>
          </div>
          <div className="network">
            <i className="zmdi zmdi-network"></i>
          </div>
          <div className="wifi">
            <i className="zmdi zmdi-wifi-alt-2"></i>
          </div>
          <div className="star">
            <i className="zmdi zmdi-star"></i>
          </div>
        </div>
        <div className="chat">
          <div className="chat-container">
            <div className="user-bar">
              <div className="back">
                <i className="zmdi zmdi-arrow-left"></i>
              </div>
              <div className="avatar">
                <img
                  src="/campaign-wizard/unilever_logo_CW_icon.png"
                  alt="Unilever logo"
                />
              </div>
              <div className="name">
                <span>{get(data, 'brand.label', 'Unilever')}</span>
                <span className="status">Business account</span>
              </div>
              <div className="actions more">
                <i className="zmdi zmdi-more-vert"></i>
              </div>
            </div>
            <div
              className="conversation d-flex flex-column"
              style={{ backgroundImage: `url(${waBgImg})` }}
            >
              <div className="conversation-container">
                <div className="date-pin-container">
                  <div className="date-pin">{getSendDate()}</div>
                </div>

                {/* <div className="meta-alert-container">
                  <div className="meta-alert">
                    <span>
                      <i className="fas fa-info-circle"></i>
                    </span>{' '}
                    This business uses a secure service from Meta to manage this
                    chat. Tap to learn more.
                  </div>
                </div> */}
                {!hasCarousel && showMessageBox() && (
                  <div className="message-container received">
                    <div className="tempo-chat">
                      <WAChatHeader
                        data={get(data, 'waHeader')}
                        hasLTOOffer={hasLTOOffer}
                      />
                      <WAChatLTO
                        data={get(data, 'waOffer')}
                        hasLTOOffer={hasLTOOffer}
                        headerType={get(data, 'waHeader.type')}
                        waButtons={get(data, 'waButtons')}
                      />
                      <WAChatBody
                        data={waBodyFormatted || get(data, 'waBodyText')}
                        isHTML={true}
                      />
                      <WAChatFooter data={get(data, 'waFooterText')} />
                      <span className="metadata">
                        <span className="time">
                          {moment().format('H:mm A')}
                        </span>
                      </span>
                    </div>
                    <WAChatButtons data={get(data, 'waButtons')} />
                  </div>
                )}
                {hasCarousel && carousalMainMessage && (
                  <div className="message-container received">
                    <div className="tempo-chat">
                      <WAChatBody
                        data={
                          waCarousalMainMessageFormatted || carousalMainMessage
                        }
                        isHTML={true}
                      />
                      <span className="metadata">
                        <span className="time">
                          {moment().format('H:mm A')}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                {hasCarousel && hasCarouselCards && (
                  <div className="wa-carousel">
                    {get(data, 'carousel.carouselCards', []).map(
                      (item, index) => {
                        return (
                          <div
                            className="message-container received wa-carousel-mesg"
                            key={`${index}`}
                          >
                            <div className="tempo-chat">
                              <WAChatHeader
                                data={get(item, 'cardHeader')}
                                hasLTOOffer={false}
                              />
                              <WAChatBody
                                data={waReplaceTags(
                                  get(item, 'cardBodyTextDynamicVariables'),
                                  get(item, 'cardBodyText')
                                )}
                              />
                            </div>
                            <WAChatButtons
                              data={get(item, 'buttons')}
                              isCarousel
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
              <div className="conversation-compose">
                <div className="emoji">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    id="smiley"
                    x="3147"
                    y="3209"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z"
                      fill="#7d8489"
                    />
                  </svg>
                </div>
                <input
                  className="input-msg"
                  name="input"
                  placeholder="Message"
                  autoComplete="off"
                  readOnly
                ></input>
                <div className="attachment">
                  <i className="zmdi zmdi-attachment-alt"></i>
                </div>
                <div className="photo">
                  <i className="zmdi zmdi-camera"></i>
                </div>
                <button className="send">
                  <div className="circle">
                    <i className="zmdi zmdi-mic"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mob-nav-btn">
          <div className="back">
            <i className="fas fa-caret-left fa-size-xl"></i>
          </div>
          <div className="home">
            <i className="fas fa-circle"></i>
          </div>
          <div className="tray">
            <i className="fas fa-square"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WAScreen;
