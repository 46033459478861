import {
  FETCH_WA_CORE_TEMPLATES,
  FETCH_WA_CORE_TEMPLATES_SUCCESS,
  FETCH_WA_CORE_TEMPLATES_FAILURE,
  DELETE_WA_CORE_TEMPLATE,
  DELETE_WA_CORE_TEMPLATE_SUCCESS,
  DELETE_WA_CORE_TEMPLATE_FAILURE,
  SET_WA_CORE_TEMPLATES_LOADER
} from '../../actionTypes';

const initialState = {
  isLoading: false,
  data: [],
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_WA_CORE_TEMPLATES:
      return { ...state, isLoading: true };
    case FETCH_WA_CORE_TEMPLATES_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };

    case FETCH_WA_CORE_TEMPLATES_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case DELETE_WA_CORE_TEMPLATE:
      return { ...state, isLoading: true };
    case DELETE_WA_CORE_TEMPLATE_SUCCESS:
      state.data.data.data = state.data.data.data.filter(
        template => template._id !== action.payload
      );
      return {
        ...state,
        isLoading: false
      };
    case DELETE_WA_CORE_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case SET_WA_CORE_TEMPLATES_LOADER:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
}
