import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
// import Wizard from "containers/Wizard";
import Dashboard from 'components/Dashboard';
import Overview from 'components/Overview';
import Header from 'components/Header';
import Approval from 'components/Approval';
import MasterData from 'components/MasterData';
import NotFound from 'components/NotFound';
import PrmData from 'components/PrmData';
import BrandDetails from 'components/BrandDetails';
import CampaignType from 'components/CampaignType';
import CampaignDetails from 'components/CampaignDetails';
import QuestionsAnswers from 'components/QuestionsAnswers';
import ReviewAndSubmit from 'components/Review/index';
import GigyaConnectionDetails from 'components/GigyaConnectionDetails';
import ConsentPreferences from 'components/ConsentPreferences';
import UserManagement from 'components/user-management';
import QuestionAnswerSorting from 'components/QuestionAnswerSorting';
import WithAuthorization from 'components/Authorization';
import ScrollToTop from 'components/ScrollToTop';
import CampaignAuthoringAccess from 'components/campaign-authoring-access';
// import BrandManagement from 'components/brand-management';
import PromotigoInformation from 'components/PromotigoInfomatiom';

import SaasFormProfileFieldDetails from '../components/SAAS/SaasFormProfileFieldDetails';
import SaasFormSinglePageDetails from '../components/SAAS/SaasFormSinglePageDetails';
import SaasGenericFormField from '../components/SAAS/SaasGenericFormField';
import CommunicationBrandDetails from 'components/communications/CommunicationBrandDetails';
import CommunicationCampaignDetails from 'components/communications/CommunicationCampaignDetails';
import CommunicationReviewAndSubmit from 'components/communications/CommunicationReview';
import CommunicationConfirmation from 'components/communications/CommunicationConfirmation';
import CommunicationOverview from 'components/communications/CommunicationOverview';
import CommunicationDashboard from 'components/communications/CommunicationDashboard';
import ReleaseNoteCommunication from 'pages/ReleaseNoteCommunication/ReleaseNoteCommunication';
import ReportDashboard2 from 'pages/ReportingDashboard/ReportingDashboard';
import Homepage from 'components/Homepage';
import Home from '../pages/Home';
import Login from '../pages/Login';
import LoginRedirect from 'pages/LoginRedirect';

import SaasQuestionAnswers from 'components/SAAS/SaasQuestionAnswers';
import EpsilonQnA from 'components/SAAS/EpslionQnA';
import SaasConsentCompliance from 'components/SAAS/SaasConsentCompliance';
import PreviewServiceError from 'components/SAAS/PreviewServiceError';
import ProfileFieldsSorting from '../components/SAAS/ProfileFieldsSorting';
import SaasIncentive from 'components/SAAS/SaasIncentive';
import SaasEpsilonMasterData from 'components/SAAS/SaasEpsilonMasterData';
import EditorManagement from 'components/EditorManagement';
import Footer from 'components/Footer.js';
import SaasQuestionAnswerV2 from 'components/SAAS/SaasQuestionAnswersV2';
import constant from '../constants';
import QuestionsAnswersV2 from 'components/QuestionsAnswersV2';
import FeedbackForm from 'components/FeedbackForm';
import FormBuilderPoc from '../pages/FormBuilderPoc';
import NewEmailCampaign from '../pages/NewEmailCampaign';
import EmailType from '../pages/EmailType';
import EmailCampaignDetails from '../pages/EmailCampaignDetails';
import EmailEditor from '../pages/EmailEditor';
import EmailCampaignTemplate from '../pages/EmailCampaignTemplate';
import EmailSubmit from '../pages/EmailSubmit';

import SMSType from 'pages/CommunicationType';
import SMSAudience from 'pages/CommunicationAudience';
import SMSSchedule from 'pages/CommunicationSchedule';
import SMSAttributes from 'pages/CommunicationAttributes';
import SMSReview from 'pages/CommunicationReview';
import CommunicationSubmit from 'pages/CommunicationSubmit';

import WACampaignBrandDetails from 'pages/WACampaignBrandDetails';
import WACampaignType from 'pages/WACampaignType';
import WACampaignSummary from 'pages/WACampaignSummary';
import WACampaignAttributes from 'pages/WACampaignAttributes';
import WACampaignSchedule from 'pages/WACampaignSchedule';
import WACampaignAudience from 'pages/WACampaignAudience';
import WACampaignDesign from 'pages/WACampaignDesign';
import WACampaignReview from 'pages/WACampaignReview';
import WACampaignOverview from 'pages/WACampaignOverview';
import WACampaignSubmitConfirmation from 'pages/WACampaignSubmitConfirmation';
import WACampaignDashboard from 'pages/WACampaignDashboard';
import WACampaignApproval from 'pages/WACampaignApproval';
import WACampainExecution from 'pages/WACampainExecution';
import WASetupGuidelines from 'pages/WASetupGuidelines';
import WASelectTemplate from 'pages/WASelectTemplate';

import ReportManagement from 'components/report-management';
import ConsumerDataEnrichment from 'pages/ReleaseNoteCommunication/ConsumerDataEnrichment';
import EmailDashboard from 'pages/EmailDashboard/EmailDashboard';
import EmailDashboardV2 from 'pages/EmailDashboard/EmailDashboardV2';
import SaveTemplateOverlay from 'components/organisms/SaveTemplateOverlay';
import EmailOverview from '../pages/EmailOverview';
import EmailReview from '../pages/EmailReview';
import EmailApproval from '../pages/EmailApproval';
import EmailCampaignSummary from '../pages/EmailCampaignSummary';
import EmailCampaignSchedule from '../pages/EmailCampaignSchedule';
import EmailCampaignAudience from 'pages/EmailCampaignAudience';
import EmailProperties from 'pages/EmailProperties';
import DesignYourEmail from 'pages/DesignYourEmail';
import EmailCollection from 'pages/EmailCollection';
import BrandDefaultsMain from 'pages/BrandEmailDefaults/BrandDefaultsMain';
import EmailExecution from 'pages/EmailExecution';
import EmailSetupGuidelines from 'pages/EmailSetupGuidelines';
import CommunicationSetupGuidelines from 'pages/CommunicationSetupGuidelines';
// import EmailABTests from 'pages/EmailABTests';
// import EmailCollaborators from 'pages/EmailCollaborators';
import CampaignAttributes from 'pages/CampaignAttributes';
import { checkEmailRouteAccess } from 'selectors';
import { PageRestrict } from 'components/PageRestrict';
import { checkRouteAccess, getFullUUID } from 'selectors';
import RedirectEventHandler from 'components/RedirectEventHandler';
import UserApprovers from 'components/user-approvers';
import RedirectToPage from 'components/RedirectToPage';
import SideBarNav from 'components/Sidebar';
import SmsCampaignSummary from 'pages/SmsCampaignSummary';
import CommunicationDesignSms from 'components/communications/CommunicationDesignSms';
import BrandManagement from 'pages/BrandManagement';
import CommunicationApproval from 'components/communications/CommunicationApproval';
import SMSExecution from 'pages/CommunicationExecution';
import DesignSMS from 'pages/DesignSMS';
import TinyMCEWA from 'pages/TinyMCEWA';
import constants from 'constants/config';
import ConsentComponent from 'pages/ConsentStatement/ConsentStatement';
import ConsentDetails from 'pages/ConsentsDetails';
import ConsentGuideline from 'pages/ConsentGuideline';

const AppRouter = ({ eventEmitter }) => (
  <BrowserRouter>
    <Header
      shortHeaderRoutes={[]}
      noHeaderRoutes={[
        'email-editor',
        'email-template',
        'login',
        'login-redirect',
        'wa-template'
      ]}
      shrink
    />
    <ScrollToTop />
    <SideBarNav />
    <main>
      <Switch>
        <Route
          exact={true}
          path="/campaign-wizard"
          render={props => (
            <WithAuthorization
              componentName={
                constant.featureFlags.ENABLE_CAMPAIGN_EMAIL ? Home : Homepage
              }
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/login"
          render={props => <Login {...props} />}
        />
        <Route
          exact={true}
          path="/login-redirect"
          render={props => <LoginRedirect {...props} />}
        />
        <Route
          exact={true}
          path="/campaign-wizard/dashboard"
          render={props => (
            <WithAuthorization
              componentName={Dashboard}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          name="brand details"
          path="/campaign-wizard/newcampaign"
          render={props => (
            <WithAuthorization
              componentName={BrandDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        {constant.featureFlags.ENABLE_BRAND_DEFAULTS ? (
          <Route
            exact={true}
            path="/campaign-wizard/brand-style"
            render={props => (
              <WithAuthorization
                componentName={BrandDefaultsMain}
                allowedRoles={[
                  'admin',
                  'approver',
                  'masterdata',
                  'acs',
                  'gigya',
                  'prm',
                  'requestor',
                  'creativeagency',
                  'executionagency'
                ]}
                {...props}
              />
            )}
          />
        ) : null}
        <Route
          exact={true}
          path="/campaign-wizard/user"
          render={props => (
            <WithAuthorization
              componentName={UserManagement}
              allowedRoles={['admin']}
              {...props}
            />
          )}
        />
        {/* new added route config */}
        <Route
          exact={true}
          path="/campaign-wizard/brand-management"
          render={props => (
            <WithAuthorization
              componentName={BrandManagement}
              allowedRoles={['admin', 'acs']}
              {...props}
            />
          )}
        />
        {/* <Route
          exact={true}
          path="/campaign-wizard/brand-mgmt"
          render={props => (
            <WithAuthorization
              componentName={BrandManagement}
              allowedRoles={['admin']}
              {...props}
            />
          )}
        /> */}
        {/* <Route
          exact={true}
          path="/campaign-wizard/report-mgmt"
          render={props => (
            <WithAuthorization
              componentName={ReportManagement}
              allowedRoles={['admin']}
              {...props}
            />
          )}
        /> */}
        {/* <Route
          exact={true}
          path="/campaign-wizard/report-mgmt-v2"
          render={props => (
            <WithAuthorization
              componentName={ReportManagement2}
              allowedRoles={['admin']}
              {...props}
            />
          )}
        /> */}
        {/* new route config for Promotigo Info page*/}
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/promotigo-info"
          render={props => (
            <WithAuthorization
              componentName={PromotigoInformation}
              allowedRoles={['requestor', 'admin']}
              pageName={'promotigo-info'}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id"
          render={props => (
            <WithAuthorization
              componentName={Overview}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/branddetails"
          render={props => (
            <WithAuthorization
              componentName={BrandDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/type"
          render={props => (
            <WithAuthorization
              componentName={CampaignType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-profile-details"
          render={props => (
            <WithAuthorization
              componentName={SaasFormProfileFieldDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/configure-form-fields"
          render={props => (
            <WithAuthorization
              componentName={SaasFormSinglePageDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-incentive-fields"
          render={props => (
            <WithAuthorization
              componentName={SaasIncentive}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-consent-compliance"
          render={props => (
            <WithAuthorization
              componentName={SaasConsentCompliance}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-generic-details"
          render={props => (
            <WithAuthorization
              componentName={SaasGenericFormField}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-sorting-page"
          render={props => (
            <WithAuthorization
              componentName={ProfileFieldsSorting}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/details"
          render={props => (
            <WithAuthorization
              componentName={CampaignDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/questionsanswers"
          render={props => (
            <WithAuthorization
              componentName={
                constant.featureFlags.ENABLE_NEW_QNA_FOR_NONSAAS
                  ? QuestionsAnswersV2
                  : QuestionsAnswers
              }
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/sorting"
          render={props => (
            <WithAuthorization
              componentName={QuestionAnswerSorting}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saasqna"
          render={props => (
            <WithAuthorization
              componentName={
                constant.featureFlags.ENABLE_NEW_QNA_FOR_SAAS
                  ? SaasQuestionAnswerV2
                  : SaasQuestionAnswers
              }
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        {/* EpsilonQnA */}
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-epsilon-qna"
          render={props => (
            <WithAuthorization
              componentName={EpsilonQnA}
              allowedRoles={['admin', 'requestor']}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/saas-epsilon-masterdata"
          render={props => (
            <WithAuthorization
              componentName={SaasEpsilonMasterData}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/review"
          render={props => (
            <WithAuthorization
              componentName={ReviewAndSubmit}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/manage-editors"
          render={props => (
            <WithAuthorization
              componentName={EditorManagement}
              allowedRoles={['admin', 'requestor']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/communications/setup-guidelines"
          render={props => (
            <WithAuthorization
              componentName={CommunicationSetupGuidelines}
              allowedRoles={['admin', 'acs']}
              pageName={'setup-guidelines'}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.REVIEW}`}
          render={props => (
            <WithAuthorization
              componentName={SMSReview}
              allowedRoles={['admin', 'requestor', 'approver', 'acs']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.CONFIRMATION_PAGE}`}
          render={props => (
            <WithAuthorization
              componentName={CommunicationSubmit}
              allowedRoles={['admin', 'requestor', 'approver', 'acs']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/approval"
          render={props => (
            <WithAuthorization
              componentName={Approval}
              allowedRoles={['admin', 'approver']}
              pageName={'approval'}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/masterdata"
          render={props => (
            <WithAuthorization
              componentName={MasterData}
              allowedRoles={['masterdata', 'admin']}
              pageName={'masterdata'}
              customRules={[checkRouteAccess]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/prm"
          render={props => (
            <WithAuthorization
              componentName={PrmData}
              allowedRoles={['prm', 'admin']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/authoringaccess"
          render={props => (
            <WithAuthorization
              componentName={CampaignAuthoringAccess}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/consentpreferences"
          render={props => (
            <WithAuthorization
              componentName={ConsentPreferences}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/campaign/:id/gigya"
          render={props => (
            <WithAuthorization
              componentName={GigyaConnectionDetails}
              allowedRoles={['gigya', 'admin']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/newcommunications"
          key={'new-sms-campaign'}
          render={props => (
            <WithAuthorization
              componentName={CommunicationBrandDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              key={`${getFullUUID()}`}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`/campaign-wizard/${constants.SMS_ROUTES.NEW_SMS_TYPE}`}
          render={props => (
            <WithAuthorization
              componentName={SMSType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`/campaign-wizard/${constants.SMS_ROUTES.NEW_CAMPAIGN_SUMMARY}`}
          render={props => (
            <WithAuthorization
              componentName={SmsCampaignSummary}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.BRAND_DETAILS}`}
          render={props => (
            <WithAuthorization
              componentName={CommunicationBrandDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.SMS_TYPE}`}
          render={props => (
            <WithAuthorization
              componentName={SMSType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />

        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.AUDIENCE}`}
          render={props => (
            <WithAuthorization
              componentName={SMSAudience}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.SCHEDULE}`}
          render={props => (
            <WithAuthorization
              componentName={SMSSchedule}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.CAMPAIN_ATTRIBUTES}`}
          render={props => (
            <WithAuthorization
              componentName={SMSAttributes}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.CAMPAIGN_SUMMARY}`}
          render={props => (
            <WithAuthorization
              componentName={SmsCampaignSummary}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />

        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.DESIGN_SMS}`}
          render={props => (
            <WithAuthorization
              //componentName={CommunicationDesignSms}
              componentName={DesignSMS}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.APPROVAL}`}
          render={props => (
            <WithAuthorization
              componentName={CommunicationApproval}
              allowedRoles={['admin', 'approver']}
              pageName={`${constants.SMS_ROUTES.APPROVAL}`}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id/${constants.SMS_ROUTES.EXECUTION}`}
          render={props => (
            <WithAuthorization
              componentName={SMSExecution}
              allowedRoles={['admin', 'acs']}
              customRules={[checkEmailRouteAccess]}
              pageName={`${constants.SMS_ROUTES.EXECUTION}`}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.SMS_BASE_ROUTE}/:id`}
          render={props => (
            <WithAuthorization
              componentName={CommunicationOverview}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              pageName={'sms-overview'}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/smsdashboard"
          render={props => (
            <WithAuthorization
              componentName={CommunicationDashboard}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />

        {/* WhatsApp routes */}
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/rich-text-poc`}
          render={props => (
            <WithAuthorization
              componentName={TinyMCEWA}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignDashboard}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_CAMPAIGN}`}
          key={'new-wa-campaign'}
          render={props => (
            <WithAuthorization
              componentName={WACampaignBrandDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_WA_TYPE}`}
          key={'wa-campaign-type'}
          render={props => (
            <WithAuthorization
              componentName={WACampaignType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_CAMPAIGN_SUMMARY}`}
          key={'wa-campaign-summary'}
          render={props => (
            <WithAuthorization
              componentName={WACampaignSummary}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.WA_GUIDELINES_PAGE}`}
          render={props => (
            <WithAuthorization
              componentName={WASetupGuidelines}
              allowedRoles={['admin', 'acs']}
              pageName={'wa-setup-guidelines'}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignOverview}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              pageName={'wa-overview'}
              {...props}
              key={`${getFullUUID()}`}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.BRAND_DETAILS}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignBrandDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.WA_TYPE}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.CAMPAIGN_SUMMARY}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignSummary}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.CAMPAIN_ATTRIBUTES}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignAttributes}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.SCHEDULE}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignSchedule}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.AUDIENCE}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignAudience}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.WA_SELECT_TEMPLATE}`}
          render={props => (
            <WithAuthorization
              componentName={WASelectTemplate}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.DESIGN}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignDesign}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.REVIEW}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignReview}
              allowedRoles={['admin', 'requestor', 'approver', 'acs']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.CONFIRMATION_PAGE}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignSubmitConfirmation}
              allowedRoles={['admin', 'requestor', 'approver', 'acs']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.APPROVAL}`}
          render={props => (
            <WithAuthorization
              componentName={WACampaignApproval}
              allowedRoles={['admin', 'approver']}
              pageName={`${constants.SMS_ROUTES.APPROVAL}`}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path={`${constants.WA_BASE_ROUTE}/:id/${constants.WA_ROUTES.EXECUTION}`}
          render={props => (
            <WithAuthorization
              componentName={WACampainExecution}
              allowedRoles={['admin', 'acs']}
              customRules={[checkEmailRouteAccess]}
              pageName={`${constants.WA_ROUTES.EXECUTION}`}
              {...props}
            />
          )}
        />

        <Route
          exact={true}
          path="/campaign-wizard/releasenotes"
          render={props => (
            <WithAuthorization
              componentName={ReleaseNoteCommunication}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/consumerdataenrichment"
          render={props => (
            <WithAuthorization
              componentName={ConsumerDataEnrichment}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/formbuilderpoc"
          render={props => (
            <WithAuthorization
              componentName={FormBuilderPoc}
              allowedRoles={['admin', 'approver', 'requestor']}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/new-email-campaign"
          render={props => (
            <WithAuthorization
              componentName={NewEmailCampaign}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/branddetails"
          render={props => (
            <WithAuthorization
              componentName={NewEmailCampaign}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/email-collection"
          render={props => (
            <WithAuthorization
              componentName={EmailCollection}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/new-email-campaign-emailtype"
          render={props => (
            <WithAuthorization
              componentName={EmailType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/emailtype"
          render={props => (
            <WithAuthorization
              componentName={EmailType}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/new-email-campaign-email-summary"
          render={props => (
            <WithAuthorization
              componentName={EmailCampaignSummary}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/setup-guidelines"
          render={props => (
            <WithAuthorization
              componentName={EmailSetupGuidelines}
              allowedRoles={['admin', 'acs']}
              pageName={'setup-guidelines'}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-summary"
          render={props => (
            <WithAuthorization
              componentName={EmailCampaignSummary}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-schedule"
          render={props => (
            <WithAuthorization
              componentName={EmailCampaignSchedule}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-properties"
          render={props => (
            <WithAuthorization
              componentName={EmailProperties}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-audience"
          render={props => (
            <WithAuthorization
              componentName={EmailCampaignAudience}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-campaign-details"
          render={props => (
            <WithAuthorization
              componentName={EmailCampaignDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/design-your-email"
          render={props => (
            <WithAuthorization
              componentName={DesignYourEmail}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-template"
          render={props => (
            <WithAuthorization
              componentName={EmailCampaignTemplate}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-editor"
          render={props => (
            <WithAuthorization
              componentName={EmailEditor}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-submit"
          render={props => (
            <WithAuthorization
              componentName={EmailSubmit}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              pageName={'email-submit'}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id"
          render={props => (
            <WithAuthorization
              componentName={EmailOverview}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              pageName={'email-overview'}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/review"
          render={props => (
            <WithAuthorization
              componentName={EmailReview}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/approval"
          render={props => (
            <WithAuthorization
              componentName={EmailApproval}
              allowedRoles={['admin', 'approver']}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              pageName={'approval'}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/execution"
          render={props => (
            <WithAuthorization
              componentName={EmailExecution}
              allowedRoles={['admin', 'acs']}
              pageName={'execution'}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/emailbriefing/:id/email-attributes"
          render={props => (
            <WithAuthorization
              componentName={CampaignAttributes}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              customRules={[checkEmailRouteAccess]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/email-dashboard"
          render={props => (
            <WithAuthorization
              componentName={EmailDashboard}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              accessDeniedMessage={constant.EMAIL_BRIEFING_ACCESS_DENIED_MESG}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/approvers"
          render={props => (
            <WithAuthorization
              componentName={UserApprovers}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/report-mgmt-v2"
          render={props => (
            <WithAuthorization
              componentName={ReportDashboard2}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/consent-statement"
          render={props => (
            <WithAuthorization
              componentName={ConsentComponent}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/consent-statement/view"
          render={props => (
            <WithAuthorization
              componentName={ConsentDetails}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/consent-guideline"
          render={props => (
            <WithAuthorization
              componentName={ConsentGuideline}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor',
                'creativeagency',
                'executionagency'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/error/:code"
          render={props => (
            <WithAuthorization
              componentName={PreviewServiceError}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/campaign-wizard/404"
          render={props => (
            <WithAuthorization
              componentName={PageRestrict}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          path={'/campaign-wizard/emailbriefing/:id/*'}
          render={props => (
            <WithAuthorization
              componentName={PageRestrict}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
        <Route
          render={props => (
            <WithAuthorization
              componentName={PageRestrict}
              allowedRoles={[
                'admin',
                'approver',
                'masterdata',
                'acs',
                'gigya',
                'prm',
                'requestor'
              ]}
              {...props}
            />
          )}
        />
      </Switch>
    </main>
    <Footer noFooterRoutes={['email-editor', 'login', 'login-redirect']} />
    <FeedbackForm />
    <SaveTemplateOverlay />
    <RedirectEventHandler eventEmitter={eventEmitter} />
  </BrowserRouter>
);

export default AppRouter;
