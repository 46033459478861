import React, { useState } from 'react';
import Modal from 'react-modal';

import EmailGuideline from 'components/EmailGuideline';

import constants from 'constants/config';

const WAltoGuidelines = ({ isOpen, handleCloseForm }) => {
  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        handleCloseForm();
      }}
      className="cw-modal cw-modal--lto-guidelines"
      contentLabel="LTO guidelines"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={() => {
            handleCloseForm();
          }}
        ></span>

        <div className="d-flex mb-20">
          {/* <div className="email-overlay-header-box">
            <img
              src={EmailCouponIcon}
              alt="send test email icon"
              className="send-test-email__icon"
            />
          </div> */}
          <div className="pr-3">
            <div className={'cw-heading--saascampaign send-test-email__title'}>
              Limited time offer component
            </div>
          </div>
        </div>

        <div className="overlay-content-wrapper">
          <div className="overlay-content">
            <EmailGuideline
              listStyleType="disc"
              listStyleTypeColor="black"
              liClassName="li-item-color"
              noMargin
              isEmailCampaign
              hideCloseButton
              hideHorizontalLine
              data={constants.WA_LTO_GUIDELINES}
              noUlLineBreak
              noSubTitleLineBreak
            />
          </div>

          <div className="bottom-cta">
            <div className="horizontal-line"></div>

            <div className="mt-3">
              <button
                type="submit"
                onClick={() => {
                  handleCloseForm();
                }}
                className="btn btn-block btn-outline-secondary mb-10 cw-print-hide text-nowrap"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WAltoGuidelines;
