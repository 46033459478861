import React, { Component } from 'react';
import Loader from './Loader';
import { connect } from 'react-redux';
import { getFormatedDate, getUserCountryList } from '../selectors/index';
import CheckboxDropDown from './CheckboxDropDown';
import { getBrandCountries } from '../actions/dashboard';
import axios from 'axios';
import * as XLSX from 'xlsx';
import moment from 'moment';
import constant from '../constants';
import {
  fetchApproverFilterData,
  fetchUserData
} from '../actions/user-management';
import SearchIcon from '../images/find.png';
import Autocomplete from 'react-autocomplete';
import DynamicTable from './organisms/DynamicTable/DynamicTable';
import ActionExportIcon from '../images/action-export.png';
import ReactTooltip from 'react-tooltip';
import DownloadIcon from '../images/download-image.svg';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { fetchDomainData } from '../actions/user-management/requesterTab';
import CWBannerModal from './adminConfig/cw-banner-model';
import { openBannerModal} from '../actions/adminConfig'

class TabPanelContent extends Component {
  constructor(props) {
    super(props);
    this.uploadExcelRef = React.createRef();
    this.state = {
      selectedCountry: [],
      selectedCampaignFilter: [],
      filterUserData: [],
      selectedCountryArr: [],
      file: null,
      query: '',
      selectedBusinessFilter: []
    };
    this._getCountries = this._getCountries.bind(this);
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this._handleCampaignDropDownValue = this._handleCampaignDropDownValue.bind(
      this
    );
    this._handleSearch = this._handleSearch.bind(this);
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handleBusinessGroupDropDownValue = this._handleBusinessGroupDropDownValue.bind(
      this
    );
  }

  componentDidMount() {
    this._setRoleBaseFilters();
    this.props.getBrandCountries();
  }

  _setRoleBaseFilters() {
    if (
      this.props.authorizedUserData &&
      this.roleForFilter.includes(this.props.authorizedUserData.group)
    ) {
      if (
        this.props.authorizedUserData &&
        this.props.authorizedUserData.country &&
        this.props.authorizedUserData.country.length
      ) {
        this.country = getUserCountryList(
          this.props.authorizedUserData.country
        );
        this.setState({ brandcountry: this.country });
      }
    }
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.code + '-' + country._id, label: country.name };
      });
    }
  }

  _getBusinessGroup() {
    let { businessGroup } = this.props;
    if (businessGroup && businessGroup.length > 0) {
      return businessGroup.map(function(bu) {
        return { value: bu._id, label: bu.name };
      });
    }
  }

  getUserDataBasedOnFilter(
    selectedCountryData,
    selectedCampaignData,
    selectedBusinessData
  ) {
    const filter = {
      selectedCountry: selectedCountryData
        ? selectedCountryData
        : this.state.selectedCountryArr,
      selectedCampaign: selectedCampaignData
        ? selectedCampaignData
        : this.state.selectedCampaignFilter,
      selectedBusiness: selectedBusinessData
        ? selectedBusinessData
        : this.state.selectedBusinessFilter,
      sortBy: this.props.sortBy,
      sortKey: this.props.sortKey
    };
    if (
      filter &&
      filter.selectedCountry.length === 0 &&
      filter.selectedCampaign.length === 0 &&
      filter.selectedBusiness.length === 0
    ) {
      this.props.fetchUserData('approver', '', '', '', this.state.query);
    } else {
      this.props.fetchApproverFilterData('approver', filter, this.state.query);
    }
  }

  _handleCampaignDropDownValue(selectedOption, action) {
    this.getUserDataBasedOnFilter('', selectedOption);
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedCampaignFilter: selectedOption
      });
    } else {
      this.setState({
        selectedCampaignFilter: selectedOption
      });
    }
  }
  _handleBusinessGroupDropDownValue(selectedOption) {
    this.getUserDataBasedOnFilter('', '', selectedOption);
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedBusinessFilter: selectedOption
      });
    } else {
      this.setState({
        selectedBusinessFilter: selectedOption
      });
    }
  }

  _handleDropDownValue(selectedOption, action) {
    let selectedCoutryData = [];
    if (selectedOption && selectedOption.length) {
      for (const element of selectedOption) {
        let countryId =
          element && element['value'] ? element['value'].split('-')[1] : null;
        if (countryId) {
          selectedCoutryData.push(countryId);
        }
      }
    }
    this.getUserDataBasedOnFilter([...new Set(selectedCoutryData)], '');
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedCountry: selectedOption,
        selectedCountryArr: selectedCoutryData
      });
    } else {
      this.setState({
        selectedCountry: selectedOption,
        selectedCountryArr: selectedCoutryData
      });
    }
  }

  _handleSearch(event) {
    let searchText = this.state.query;
    const filter = {
      selectedCountry: this.state.selectedCountryArr,
      selectedCampaign: this.state.selectedCampaignFilter,
      selectedBusiness: this.state.selectedBusinessFilter,
      sortBy: this.props.sortBy,
      sortKey: this.props.sortKey
    };
    if (
      filter &&
      filter.selectedCountry.length === 0 &&
      filter.selectedCampaign.length === 0 &&
      filter.selectedBusiness.length === 0
    ) {
      this.props.panelType === 'requester'
        ? this.props.fetchDomainData(
            this.props.sortBy,
            this.props.sortKey,
            searchText
          )
        : this.props.fetchUserData(
            this.props.panelType,
            '',
            '',
            '',
            searchText
          );
    } else {
      this.props.fetchApproverFilterData(
        this.props.panelType,
        filter,
        searchText
      );
    }
  }

  _handleOnChange(e) {
    this.setState({ query: e.target.value });
    if (e.target.value === '') {
      let searchText = e.target.value;
      const filter = {
        selectedCountry: this.state.selectedCountryArr,
        selectedCampaign: this.state.selectedCampaignFilter,
        selectedBusiness: this.state.selectedBusinessFilter,
        sortBy: this.props.sortBy,
        sortKey: this.props.sortKey
      };
      if (
        filter &&
        filter.selectedCountry.length === 0 &&
        filter.selectedCampaign.length === 0 &&
        filter.selectedBusiness.length === 0
      ) {
        this.props.panelType === 'requester'
          ? this.props.fetchDomainData(
              this.props.sortBy,
              this.props.sortKey,
              searchText
            )
          : this.props.fetchUserData(
              this.props.panelType,
              '',
              '',
              '',
              searchText
            );
      } else {
        this.props.fetchApproverFilterData(
          this.props.panelType,
          filter,
          searchText
        );
      }
    }
  }

  suggestionMenu(children) {
    return children && children.length ? <></> : <></>;
  }

  downloadApprovers = () => {
    let endDate = new Date();
    let startDate = endDate.setMonth(endDate.getMonth() - 3);
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = '';
    endDate = '';

    let allCwApproverListUrl = `${constant.serviceUrls.CAMPAIGN_GET_USER_URL}/export/approver?group=approver&&startDate=${startDate}&&endDate=${endDate}&sortBy=lastUpdatedDate&orderBy=desc`;
    axios.get(allCwApproverListUrl).then(response => {
      const approverList = response && response.data;
      let approverJson = [];
      if (approverList && approverList.length) {
        for (let approver of approverList) {
          if (approver && approver.country && approver.country.length) {
            for (const country of approver.country) {
              let newApprovar = {
                Country: country.name,
                'Business group': approver.allBuFlag
                  ? 'All'
                  : (approver.businessGroup &&
                      approver.businessGroup[0] &&
                      approver.businessGroup[0].name) ||
                    'Unavailable',
                'Approver name': approver.name || 'Unavailable',
                'Approver email': approver.emailId || 'Unavailable',
                Status:
                  approver && approver.status == '1'
                    ? 'Active'
                    : approver.status == '0'
                    ? 'Inactive'
                    : 'Unavailable'
              };
              approverJson.push(newApprovar);
            }
          }
        }
      }
      approverJson.sort((p, q) => (p.Country > q.Country ? 1 : -1));
      const worksheet = XLSX.utils.json_to_sheet(approverJson);

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [['Exported on', new moment().format('DD MMM YYYY')]],
        { origin: 'A' + (approverJson.length + 3) }
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Approver List');
      XLSX.writeFile(workbook, `CW_Approvers_All_time.xlsx`);
    });
  };

  handleFileChange = async event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(
          constant.serviceUrls.APPROVER_BULK_UPLOAD,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        console.log('File uploaded successfully', response.data);
      } catch (error) {
        console.error('Error uploading file', error);
      }
    } else {
      alert('Please select an Excel file before submitting.');
    }
  };

  handleFileClick = e => {
    e.target.value = '';
  };

  sortClick = e => {
    this.props.sortClick(
      e,
      this.props.panelType,
      this.state.selectedCountryArr,
      this.state.selectedCampaignFilter,
      this.state.selectedBusinessFilter,
      this.state.query
    );
  };

  editUser = user => {
    this.props.editUser(
      this.props.panelType,
      'editUser',
      user,
      this.state.selectedCountryArr,
      this.state.selectedCampaignFilter,
      user.userRole,
      this.state.selectedBusinessFilter,
      this.state.query
    );
  };

  deleteUser = user => {
    this.props.deleteUser(
      this.props.panelType,
      user.emailId || user.domain,
      this.state.selectedCountryArr,
      this.state.selectedCampaignFilter,
      user.businessGroup,
      user.country,
      this.state.selectedBusinessFilter,
      this.state.query
    );
  };

  downloadExcel = () => {
    let endDate = new Date();
    let startDate = endDate.setMonth(endDate.getMonth() - 3);
    const excleStartDateFormat = moment(startDate).format('DDMMMYYYY');

    let endNewDate = new Date();
    const excleEndDateFormat = moment(endNewDate).format('DDMMMYYYY');

    startDate = startDate && moment(startDate).format('DD/MM/YYYY');
    endNewDate = endNewDate && moment(endNewDate).format('DD/MM/YYYY');

    let qnaUrl = `${
      constant.serviceUrls.QNA_CAMPAIGN_DETAILS
    }?startDate=${encodeURIComponent(startDate)}&&endDate=${encodeURIComponent(
      endNewDate
    )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export`;

    axios({ method: 'get', url: qnaUrl, responseType: 'blob' })
      .then(response => {
        let fileName =
          response.headers['content-disposition']?.split('filename=')[1] ||
          `file_${new Date().getTime()}`;
        fileDownload(response.data, fileName);
      })
      .catch(err => {
        console.log('download report error: ', err);
      });
  };

  handleBannerClick = () => {
    this.props.openBannerModal(); // Call the Redux action to open the modal
  };

  render() {
    let { asyncInProgress, campaignConfig } = this.props;
    const addNewButtonTitle =
      this.props.panelType === 'requester' ? 'New Domain' : 'New User';
    let userData =
      this.props.panelType === 'requester'
        ? this.props.domainData
        : this.props.userData;
    return (
      <div className="approver-content">
        <div
          className={classNames('mb-10', {
            //  'd-flex': this.props.panelType !== 'approver',
            //  'justify-content-end' : this.props.panelType !== 'approver',
          })}
        >
          <button
            type="button"
            className="btn btn-outline-primary float-right"
            onClick={() =>
              this.props.modalClick(
                this.props.panelType,
                'addUser',
                null,
                this.state.selectedCountryArr,
                this.state.selectedCampaignFilter,
                null,
                null,
                this.state.query,
                this.state.selectedBusinessFilter
              )
            }
          >
            {addNewButtonTitle}
          </button>
          {this.props.panelType === 'admin' ? (
                <>
                <button
                  className="btn btn-outline-primary float-right mr-10 ml-10"
                  onClick={this.handleBannerClick}
                >
                  Update CW banner message
                </button>
                <CWBannerModal/>
                </>
              ) : null}
          {this.props.panelType === 'admin' ? (
            <button
              className="btn btn-outline-primary float-right mr-10 ml-10"
              onClick={() =>
                this.props.modalClick(this.props.panelType, 'addReleaseNote')
              }
            >
              Add Release Update
            </button>
          ) : null}
          {this.props.panelType === 'admin' ? (
            <button
              className="btn btn-outline-primary float-right"
              onClick={this.downloadExcel}
            >
              Download Excel
            </button>
          ) : null}
          <div className="mr-10 mb-20">
            <div className="d-flex flex-grow-1 align-items-center">
              <Autocomplete
                inputProps={{
                  id: 'campaignIdAutocomplete',
                  className: 'search-input search-input-approver-content cross',
                  placeholder: `Search by user name or email`,
                  onKeyPress: e => {
                    if (e.key === 'Enter' && this.state.query) {
                      this._handleSearch(e);
                    }
                  }
                }}
                wrapperProps={{
                  className:
                    this.props.panelType === 'admin'
                      ? 'search-input-wrapper-admin'
                      : 'search-input-wrapper'
                }}
                autoHighlight={false}
                value={this.state.query}
                onChange={this._handleOnChange}
                renderMenu={this.suggestionMenu}
                items={[]}
                getItemValue={''}
                renderInput={props => (
                  <div className="input-clear">
                    <input {...props} />
                    {this.state.query.trim().length > 0 && (
                      <span
                        className="input-clear__cross"
                        onClick={() => {
                          this._handleOnChange({ target: { value: '' } });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </span>
                    )}
                  </div>
                )}
                renderItem={[]}
              />
              <button className="search-button" onClick={this._handleSearch}>
                <img src={SearchIcon} alt="Search" />
              </button>
            </div>
            <div className="cw-listing d-flex flex-grow-1 align-items-center"></div>
          </div>
          {this.props.panelType === 'approver' ? (
            <>
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <div className="d-flex">
                    <div className="mr-10">
                      <div className="form-group mb-0">
                        <CheckboxDropDown
                          isMulti={true}
                          className={
                            'cw-dashboard-filter approver-readyonly-dropdown'
                          }
                          menuIsOpen={false}
                          name={'brandcountry'}
                          hideSelectedOptions={false}
                          placeholder={'Country'}
                          isDisabled={asyncInProgress}
                          isLoading={asyncInProgress}
                          value={this.state.brandcountry}
                          onChange={this._handleDropDownValue}
                          options={this._getCountries()}
                          isClearable={false}
                        />
                      </div>
                    </div>
                    <div className="mr-10">
                      <div className="form-group mb-0">
                        <CheckboxDropDown
                          isMulti={true}
                          className={
                            'cw-dashboard-filter approver-readyonly-dropdown'
                          }
                          menuIsOpen={false}
                          name={'businessGroup'}
                          hideSelectedOptions={false}
                          placeholder={'Business group'}
                          isDisabled={asyncInProgress}
                          isLoading={asyncInProgress}
                          value={this.state.brandcountry}
                          onChange={this._handleBusinessGroupDropDownValue}
                          options={this._getBusinessGroup()}
                          isClearable={false}
                        />
                      </div>
                    </div>
                    <div className="mr-10">
                      <div className="form-group mb-0">
                        <CheckboxDropDown
                          isMulti={true}
                          className={
                            'cw-dashboard-filter approver-readyonly-dropdown'
                          }
                          menuIsOpen={false}
                          name={'campaigngroup'}
                          hideSelectedOptions={false}
                          placeholder={'Campaign group'}
                          isDisabled={asyncInProgress}
                          isLoading={asyncInProgress}
                          value={this.state.selectedCampaignFilter}
                          onChange={this._handleCampaignDropDownValue}
                          options={[
                            {
                              label: 'Data campaign',
                              value: 'formCampaign'
                            },
                            {
                              label: 'Email campaign',
                              value: 'emailCampaign'
                            },
                            {
                              label: 'SMS campaign',
                              value: 'smsCampaign'
                            },
                            {
                              label: 'WhatsApp campaign',
                              value: 'waCampaign'
                            }
                          ]}
                          isClearable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  {this.props.panelType === 'approver' ? (
                    <div>
                      <input
                        type="file"
                        ref={this.uploadExcelRef}
                        id="excelFileInput"
                        accept=".xlsx"
                        onChange={this.handleFileChange}
                        onClick={this.handleFileClick}
                        className="d-none"
                      />
                      <Action
                        icon={ActionExportIcon}
                        title="Upload approvers"
                        id="save-email"
                        onClick={() => {
                          this.uploadExcelRef.current.click();
                        }}
                      />
                    </div>
                  ) : null}
                  {this.props.panelType === 'approver' ? (
                    <Action
                      icon={DownloadIcon}
                      title="Export approvers"
                      id="export-email"
                      onClick={() => this.downloadApprovers()}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div>
          <div className="cw-listing--content">
            {userData &&
            userData.length === 0 &&
            this.props.userData.length != 0 ? (
              <>
                <span>
                  {this.props.requestor === 'requestor'
                    ? `No domain available.`
                    : 'No user available.'}
                  {/* No approvers are available on record for the selected markets.
                  The approval requests for these markets are sent to Global 121 */}
                </span>
              </>
            ) : (
              <>
                <DynamicTable
                  campaignConfig={campaignConfig}
                  sortClick={this.sortClick}
                  columnHeaders={this.props.columnHeaders}
                  asyncInProgress={this.props.asyncInProgress}
                  userData={userData}
                  panelType={this.props.panelType}
                  editUser={this.editUser}
                  deleteUser={this.deleteUser}
                  businessGroup={this.props.businessGroup}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function Action({ id, icon, onClick, title }) {
  return (
    <>
      <ReactTooltip
        id={id}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <button
        onClick={onClick}
        className={'action-bar__action float-right'}
        data-tip={title}
        data-for={id}
      >
        <img src={icon} alt={title} />
      </button>
    </>
  );
}

const mapStateToProps = state => ({
  // authorizedUserData: state.authorized.authorizedUserData,
  showLoader: state.dashboard.showLoader,
  countries: state.dashboard.countries,
  userData: state.user.userData || {},
  campaignConfig: state.app.campaignConfig,
  domainData: state.requester.domainData || {},
  bannerMessage: state.adminConfig.message
});

export default connect(mapStateToProps, {
  fetchApproverFilterData,
  fetchUserData,
  getBrandCountries,
  fetchDomainData,
  openBannerModal
})(TabPanelContent);
