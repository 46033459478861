import React from 'react';

const WAChatFooter = ({ data }) => {
  return data ? (
    <div className="footer-text-container">
      <span className="footer-text">{data}</span>
    </div>
  ) : null;
};

export default WAChatFooter;
