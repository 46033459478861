import React, { useState } from 'react';
import * as yup from 'yup';
import constants from 'constants/config';
import { transformWAPayloadBySchedule } from 'selectors';
import {
  updateWAComms,
  waUploadFileWithStatus,
  setWACommsLoader
} from 'actions/wabriefings/waComms';
import WACostAndRequestMoreInfoInput from 'components/molecules/WACostAndRequestMoreInfoInput/WACostAndRequestMoreInfoInput';
import { get } from 'lodash';

const CampaignCostRequestInfoSchema = (
  dispatch,
  { defaultValues, pathUid, closeModal, setToggle, waType, authorizedUserData }
) => {
  const defaultType = get(defaultValues, 'waTrigger.type');
  const [isActive, setIsActive] = useState(false);
  return {
    formType: 'custombuttons',
    submitBtnLabel: 'Send cost estimate',
    btnClassName: 'd-flex justify-content-end mt-20',
    modelBtnSwap: true,
    isEdit: false,
    customButtonWatch: 'shareCostEstimate.selectedFlow',
    fields: [
      {
        type: 'custom',
        name: 'shareCostEstimate',
        component: WACostAndRequestMoreInfoInput,
        id: 'shareCostEstimate',
        label: 'What do you want to do',
        emailType: waType,
        setIsActive: setIsActive,
        isActive: isActive,
        defaultValue: defaultValues,
        rules: yup.object({
          selectedFlow: yup
            .object()
            .shape({
              label: yup.string().required(),
              value: yup.string().required()
            })
            .default(undefined)
            .required('Please select a value'),
          audienceCount:
            defaultType === 'dynamicTrigger'
              ? yup.number().optional()
              : yup.number().when('selectedFlow', {
                  is: data => data && data.value === 'shareCostEstimate',
                  then: schema =>
                    schema
                      .positive('Audience count must be greater than 0')
                      .required('Please provide audience count')
                      .typeError('Please provide audience count')
                }),
          acsCostFile: yup.mixed().when('selectedFlow', {
            is: data => data && data.value === 'shareCostEstimate',
            then: schema =>
              schema
                .required('Please upload a file')
                .typeError('Please upload a file')
          }),
          additionalcomments: yup.string().when('selectedFlow', {
            is: data => data && data.value === 'shareCostEstimate',
            then: schema =>
              schema
                .required('Please enter additional comments')
                .typeError('Please enter additional comments')
          }),
          requestInfoText: yup.string().when('selectedFlow', {
            is: data => data && data.value === 'requestMoreInfo',
            then: schema =>
              schema
                .required('Please describe the information to request')
                .typeError('Please describe the information to request')
          })
        })
      }
    ],
    onSubmit: (data, { user }) => {
      let dataToSend = {};
      const paramUid = pathUid;
      if (
        data &&
        data?.shareCostEstimate?.selectedFlow?.value === 'shareCostEstimate'
      ) {
        const formData = new FormData();
        const fileData = data?.shareCostEstimate?.acsCostFile;
        formData.append('file', fileData);
        /***** Neew to send to S3 bucket and get key that we need to store in DB */
        delete data.shareCostEstimate.acsCostFile;

        formData.append('userEmail', user.emailId);
        formData.append('userName', user.name);
        formData.append(
          'status',
          constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER
        );
        formData.append('acsCostEstimateFlow', true); // for file identify
        formData.append(
          'shareCostEstimate',
          JSON.stringify(data.shareCostEstimate)
        );
        formData.append('uid', paramUid);
        formData.append('role', authorizedUserData.group);
        const transformedData = transformWAPayloadBySchedule(dataToSend);
        dispatch(setWACommsLoader(true));
        dispatch(
          waUploadFileWithStatus(
            formData,
            { ...transformedData, uid: paramUid },
            (error, result) => {
              dispatch(setWACommsLoader(true));
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                closeModal(true);
              }
            },
            { disableLoader: true }
          )
        );
      } else if (
        data &&
        data?.shareCostEstimate?.selectedFlow?.value === 'requestMoreInfo'
      ) {
        dataToSend = {
          userEmail: user.emailId,
          userName: user.name,
          requestInfoText: data?.shareCostEstimate?.requestInfoText,
          requestInfoAnswerText: null,
          status: constants.WA_CAMPAIGN_STATUS.AWAITING_INFO,
          nmiAssignedToRequestor: data?.shareCostEstimate?.assignBackToRequestor
        };
        const transformedData = transformWAPayloadBySchedule(dataToSend);

        dispatch(
          updateWAComms(
            paramUid,
            transformedData,
            (error, result) => {
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                closeModal(true);
              }
            },
            { disableLoader: false }
          )
        );
      }
    },
    onPrevious: () => {
      setToggle(false);
      closeModal(false);
    },
    previousBtnLabel: 'Cancel',
    disableFormError: true
  };
};

export default CampaignCostRequestInfoSchema;
