import React from 'react';
import { Image } from '../../atoms/image';
import { Paragraph } from '../../atoms/Paragraph';
import classNames from 'classnames';

export const CardBasic = ({ ...props }) => {
  return (
    <div
      className={
        props.cardClass
          ? 'card text-center ' + props.cardClass
          : 'card text-center '
      }
    >
      {props.icon ? (
        <div className={'card-image ' + props.icon}>
          {props.tag ? (
            <span
              className={classNames('badge badge-pill', {
                'badge-dark': props.tagClass ? false : true,
                [props.tagClass]: props.tagClass ? true : false
              })}
            >
              {props.tag}
            </span>
          ) : null}
        </div>
      ) : null}
      <div className="card-body">
        <div className="card-body--text">
          {props.title ? (
            <Paragraph
              className={'card-body--title h5'}
              text={props.title}
            ></Paragraph>
          ) : null}
          {props.description ? (
            <Paragraph
              className={'card-body--desc'}
              text={props.description}
            ></Paragraph>
          ) : null}
        </div>
        <div className="card-body--buttons">
          {props.privileged &&
          (props.privilegedOnlyAccess || props.privilegedOnlyWaAccess) ? (
            <>
              <a
                href={props.ctaPrimaryUrl}
                className={
                  props.btnBlock
                    ? 'btn btn-primary btn-block'
                    : 'btn btn-primary'
                }
              >
                {props.ctaPrimaryText}
              </a>
              <a
                href={props.ctaSecondaryUrl}
                className={
                  props.btnBlock
                    ? 'btn btn-outline-secondary btn-block'
                    : 'btn btn-outline-secondary'
                }
              >
                {props.ctaSecondaryText}
              </a>
            </>
          ) : props.type === 'email' && props.privilegedOnlyAccess ? (
            <>
              <a
                href="#"
                onClick={props.callbackFunc}
                className={
                  props.btnBlock
                    ? 'btn btn-primary btn-block'
                    : 'btn btn-primary'
                }
              >
                {props.ctaPrimaryText}
              </a>
              <a
                href="#"
                className={
                  props.btnBlock
                    ? 'btn btn-outline-secondary btn-block'
                    : 'btn btn-outline-secondary'
                }
              >
                {props.ctaSecondaryText}
              </a>
            </>
          ) : props.type === 'wa' && props.privilegedOnlyWaAccess ? (
            <>
              <a
                href="#"
                onClick={props.callbackWaFunc}
                className={
                  props.btnBlock
                    ? 'btn btn-primary btn-block'
                    : 'btn btn-primary'
                }
              >
                {props.ctaPrimaryText}
              </a>
              <a
                href="#"
                onClick={props.callbackWaFunc}
                className={
                  props.btnBlock
                    ? 'btn btn-outline-secondary btn-block'
                    : 'btn btn-outline-secondary'
                }
              >
                {props.ctaSecondaryText}
              </a>
            </>
          ) : (
            <>
              <a
                href={props.ctaPrimaryUrl}
                className={
                  props.btnBlock
                    ? 'btn btn-primary btn-block'
                    : 'btn btn-primary'
                }
              >
                {props.ctaPrimaryText}
              </a>
              <a
                href={props.ctaSecondaryUrl}
                className={
                  props.btnBlock
                    ? 'btn btn-outline-secondary btn-block'
                    : 'btn btn-outline-secondary'
                }
              >
                {props.ctaSecondaryText}
              </a>
            </>
          )}
        </div>
        {props.type === 'wa' && props.waAccessError ? (
          <div className="text-danger">{props.ctaError}</div>
        ) : null}
        {props.type === 'email' && props.showError ? (
          <div className="text-danger">{props.ctaError}</div>
        ) : null}
      </div>
    </div>
  );
};
export default CardBasic;
