import {
  ASYNC_WA_COMMS_IN_PROGRESS,
  SET_WA_COMMS,
  UNSET_WA_COMMS
} from '../../actionTypes';

import {
  createWABriefing,
  getWABriefingbyUid,
  updateWABriefing,
  waUploadFileWithStatusService
} from 'services/waBriefings';
import { _generateUUID } from 'selectors';
import { transformWADataFromAPI } from 'utilities/util';
import _ from 'lodash';
import { setNotificationMessage } from 'actions/dashboard';
import constants from 'constants/config';

export const setWACommsLoader = payload => ({
  type: ASYNC_WA_COMMS_IN_PROGRESS,
  isLoading: payload
});

export const resetWAComms = () => ({
  type: UNSET_WA_COMMS
});

export const setWAComms = payload => ({
  type: SET_WA_COMMS,
  payload
});

export const createWAcomms = (data, callback) => {
  return async function(dispatch) {
    try {
      dispatch(setWACommsLoader(true));
      // generate uid
      const uid = _generateUUID();
      data.uid = uid;
      const result = await createWABriefing(data);
      const dataToset = transformWADataFromAPI(result);
      dispatch(setWAComms(dataToset));
      callback(null, result);
    } catch (err) {
      callback(err, null);
    } finally {
      dispatch(setWACommsLoader(false));
    }
  };
};

export const updateWAComms = (
  uid,
  data,
  callback = () => {},
  { isSaveAsDraft = false, disableStoreUpdate, disableLoader } = {}
) => {
  return async function(dispatch) {
    try {
      let response = null;
      if (!disableLoader) {
        dispatch(setWACommsLoader(true));
      }
      if (uid) {
        response = await updateWABriefing(uid, data);
        console.log('isSaveAsDraft', isSaveAsDraft);
        if (isSaveAsDraft) {
          console.log('response', response);
          const campaignName = _.get(response, 'data.name', 'Untitled');
          const uid = _.get(response, 'data.uid');
          dispatch(
            setNotificationMessage(
              constants.CAMPAIGN_SAVED_AS_DRAFT_TEXT,
              `${constants.WA_BASE_ROUTE}/${uid}`,
              'success',
              campaignName,
              'emailCampaignDraft'
            )
          );
        }
      }
      const dataToSet = response ? transformWADataFromAPI(response.data) : data;
      if (!disableStoreUpdate) {
        dispatch(setWAComms(uid ? { ...dataToSet, uid } : dataToSet));
      }
      callback(null, true);
    } catch (err) {
      callback(err, null);
    } finally {
      if (!disableLoader) {
        dispatch(setWACommsLoader(false));
      }
    }
  };
};

export const waUploadFileWithStatus = (
  data,
  jsonData,
  callback,
  { disableLoader } = {}
) => {
  return async function(dispatch) {
    try {
      if (!disableLoader) {
        dispatch(setWACommsLoader(true));
      }

      let response = await waUploadFileWithStatusService(data, jsonData);
      const dataToset = transformWADataFromAPI(response.data);
      dispatch(setWAComms(dataToset));
      if (callback) {
        callback(null, response && response.data);
      }
    } catch (err) {
      callback(err, null);
    } finally {
      if (!disableLoader) {
        dispatch(setWACommsLoader(false));
      }
    }
  };
};

export const getWAcommsByUid = (uid, callback = () => {}) => {
  return async function(dispatch) {
    try {
      dispatch(setWACommsLoader(true));
      const result = await getWABriefingbyUid(uid);
      const dataToset = transformWADataFromAPI(result.data);
      dispatch(setWAComms(dataToset));
      callback(null, result);
    } catch (err) {
      callback(err, null);
    } finally {
      dispatch(setWACommsLoader(false));
    }
  };
};

export const getWACommsList = (filters, callback = () => {}) => {};
