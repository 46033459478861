import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import constant from 'constants/config';
import { formatRichTextToWA } from 'utilities/util';

import '../../components/custom-css/richTextEditor.css';

const TinyMCEWA = props => {
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const previewRef = useRef();

  const { authorizedUserData } = useSelector(state => state.authorized);

  const handleOnChange = richTextData => {
    const defaultCopy = richTextData.getContent();
    setValue(defaultCopy);
    setValue2(format(defaultCopy));
  };

  const format = text => {
    return formatRichTextToWA(text);
  };

  return (
    <>
      <div className="col-sm-12 skypink email-campaign-brand-selection">
        <div className="cw-section">
          <div className="cw-section--title mb-30">
            <h2 className="cw-heading--secondary cw-email-heading">
              WA Richtext POC
            </h2>
          </div>

          <div className="row">
            <div className="col-md-8 wa-design">
              <form>
                <div className="form-group">
                  {/* <textarea className='form-control' rows="4" name="body-text" value={value} onChange={handleOnChange}></textarea>  */}
                  <Editor
                    className="tox tox-statusbar"
                    onChange={(evt, editor) => handleOnChange(editor)}
                    onEditorChange={(evt, editor) => handleOnChange(editor)}
                    onBeforeAddUndo={(evt, editor) =>
                      props && props.handleBeforeAddUndo
                        ? props.handleBeforeAddUndo(evt, editor)
                        : null
                    }
                    value={value}
                    disabled={false}
                    apiKey={constant.TinyMCEapiKey}
                    initialValue={''}
                    init={{
                      placeholder: 'type message',
                      height: 250,
                      menubar: false,
                      style: 'default',
                      plugins: 'lists emoticons',
                      toolbar:
                        'bold italic strikethrough | monospace | bullist numlist | emoticons | myCustomMenu',
                      toolbar_location: 'top', // You can also use 'bottom' or 'floating'
                      branding: false,
                      content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: ${
                        false ? '#e9ecef' : 'transparent'
                      } }`,
                      formats: {
                        strikethrough: { inline: 'del' },
                        monospace: {
                          inline: 'span',
                          styles: { 'font-family': 'monospace' }
                        }
                      },
                      style_formats: [
                        { title: 'Custom format', format: 'custom_format' }
                      ],
                      setup: function(editor) {
                        // Add a custom button for monospace text
                        editor.ui.registry.addButton('monospace', {
                          text: 'Monospace',
                          onAction: function() {
                            editor.formatter.toggle('monospace');
                          }
                        });

                        editor.ui.registry.addMenuButton('myCustomMenu', {
                          text: 'Add Variables',
                          fetch: function(callback) {
                            const items = [
                              {
                                type: 'menuitem',
                                text: 'First Name',
                                onAction: function() {
                                  const customCode = `[FName]`;
                                  editor.insertContent(customCode);
                                }
                              },
                              {
                                type: 'menuitem',
                                text: 'Last Name',
                                onAction: function() {
                                  const customCode = `[LName]`;
                                  editor.insertContent(customCode);
                                }
                              }
                            ];
                            callback(items);
                          }
                        });
                      },
                      forced_root_block: '',
                      force_br_newlines: true,
                      force_p_newlines: false
                    }}
                    onInit={(evt, editor) => {
                      console.log('editor: ', editor);
                      //editor.formatter.apply('custom_format');
                      editor.on('SetContent', function(e) {
                        // Select all ordered lists and modify them
                        let lists = editor.getDoc().querySelectorAll('ol');
                        console.log('lists: ', lists);
                        lists.forEach(function(list, mainIndex) {
                          //list.setAttribute('data-list', "true");
                          // Optionally, manipulate list items
                          const liList = list.querySelectorAll('li');
                          console.log('liList: ', liList);
                          liList.forEach(function(listItem, index) {
                            console.log('index: ', index);
                            // For example, you can add a custom data attribute to each <li>
                            listItem.setAttribute(
                              'data-list',
                              `${mainIndex + 1}`
                            );
                          });
                        });
                      });
                    }}
                  />
                </div>
              </form>

              <div className="mt-3">
                <p>Rich text</p>
                <pre className="pre-scrollable cw-formbuilder-code">
                  {value}
                </pre>
              </div>

              <div className="mt-3">
                <p>WhatsApp Rich text</p>
                <pre className="pre-scrollable cw-formbuilder-code">
                  {value2}
                </pre>
              </div>

              <div className="mt-3">
                <p>WhatsApp encoded value for gupshup</p>
                <pre className="pre-scrollable cw-formbuilder-code">
                  {encodeURIComponent(value2)}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TinyMCEWA;
