import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';
import * as yup from 'yup';

import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import ProgressBar from 'components/molecules/ProgressBar';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import WASummaryCards from 'components/organisms/EmailSummaryCards/WASummaryCards';
import WAOverviewTables from 'components/organisms/WAOverviewTables';
// import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import { FormError } from 'components/molecules/FormError';
import Loader from 'components/Loader';
import { Feedback, validateFeedbackForm } from './Feedback';
import NotFound from 'components/NotFound';
import MobileDeviceFrame from 'components/organisms/MobileDeviceFrame';

import { getWAcommsByUid, updateWAComms } from 'actions/wabriefings/waComms';
import { submitFeedback } from 'actions/feedbackForm';

// import { getMobilePreviewDates } from 'utilities/util';
import { validateWACampaign, validateWAContent } from './utilities';
import { scrollToTop } from 'selectors';

import constants from 'constants/config';

const WACampaignReview = props => {
  const pathUid = props.match.params.id;
  const dispatch = useDispatch();

  const [additionalInfo, setAdditionalInfo] = useState('');
  const [feedback, setFeedback] = useState({});
  const [errors, setErrors] = useState(false);
  const [requestInfoAnswer, setRequestInfoAnswer] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [mobilePreviewData, setMobilePreviewData] = useState();

  const { waCommsData, isLoading } = useSelector(state => state.waBriefings);
  const { feedbacks: userSubmittedFeebacks } = useSelector(
    state => state.feedbackForm
  );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const previewRef = useRef();

  useEffect(() => {
    if (pathUid) {
      dispatch(
        getWAcommsByUid(pathUid, (err, _) => {
          if (err) {
            setNotFound(true);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (waCommsData.waDesign) {
      const yoData = {
        ...get(waCommsData, 'waDesign', {}),
        brand: get(waCommsData, 'brand'),
        deliverySchedule: get(waCommsData, 'deliverySchedule', {})
      };
      setMobilePreviewData(yoData);
    }
  }, [waCommsData]);

  // let mobilePreviewData = getSMSReviewData(this.props.smsBrandDetails);

  const shouldShowFeedbackSection = isEmpty(userSubmittedFeebacks);

  const validateRequestorInfoAnswer = async waDetails => {
    const { requestInfoAnswerText } = waDetails;
    const requestorInfoSubmitSchema = yup.object({
      requestInfoAnswerText: yup.string().required()
    });

    return await requestorInfoSubmitSchema.isValid({
      requestInfoAnswerText: requestInfoAnswerText
    });
  };

  const isError =
    errors.summary ||
    errors.campaignAttributes ||
    errors.deliverySchedule ||
    errors.audienceDefinition ||
    errors.emailABTesting ||
    errors.feedbackError ||
    errors.waDesign ||
    errors.dynamicCouponError ||
    errors.feedbackCommentError ||
    errors.requestAnswererror;

  const handleNextClick = async () => {
    const errors = await validateWACampaign(waCommsData);
    const {
      summary,
      campaignAttributes,
      deliverySchedule,
      audienceDefinition,
      waDesign,
      dynamicCouponError
      // emailCollaborators,
    } = errors;

    const feedbackError = await validateFeedbackForm(
      feedback,
      shouldShowFeedbackSection
    );

    const feedbackCommentErr =
      feedback.rw_ratings <= '3' &&
      (!feedback.rw_comments || feedback.rw_comments.trim().length == 0)
        ? true
        : false;

    let requestInfoError = true;
    if (waCommsData && waCommsData.requestInfoText) {
      requestInfoError = await validateRequestorInfoAnswer(requestInfoAnswer);
    }

    if (
      summary ||
      campaignAttributes ||
      deliverySchedule ||
      audienceDefinition ||
      !feedbackError ||
      // waContentError ||
      waDesign ||
      dynamicCouponError ||
      feedbackCommentErr ||
      dynamicCouponError ||
      !requestInfoError
    ) {
      setErrors({
        ...errors,
        feedbackError: !feedbackError,
        waDesign,
        feedbackCommentError: feedbackCommentErr,
        campaignAttributes: dynamicCouponError ? true : campaignAttributes,
        requestAnswererror: !requestInfoError
      });
      scrollToTop();
      return;
    }

    let campaignAdditionalInfoData = {};

    if (additionalInfo.trim()) {
      campaignAdditionalInfoData.uid = pathUid;
      campaignAdditionalInfoData.additionalInfo = additionalInfo;

      // await axios.post(
      //   constants.communications.POST_SMS_CAMPAIGN_ADDITIONAL_INFO_DETAILS,
      //   campaignAdditionalInfoData
      // );
    }

    if (shouldShowFeedbackSection) {
      let feedbackData = {
        ratings: feedback.rw_ratings,
        campaignRequestType: 'WhatsApp campaigns',
        commentType: get(feedback, 'rw_commentType.value'),
        comments: feedback.rw_comments,
        campaignUid: pathUid,
        feedbackProvider: authorizedUserData._id
      };

      dispatch(
        submitFeedback(feedbackData, (error, result) => {
          if (error) {
            console.log(error);
          } else {
            console.log(result);
          }
        })
      );
    }

    let dataToSend = {
      status: constants.WA_CAMPAIGN_STATUS.AWAITING_FOR_APPROVAL,
      uid: pathUid,
      userEmail: authorizedUserData.emailId,
      userName: authorizedUserData.name,
      requestorsEmail: authorizedUserData.emailId
    };

    if (waCommsData && waCommsData.requestInfoText) {
      dataToSend = {
        ...dataToSend,
        requestInfoAnswerText:
          requestInfoAnswer && requestInfoAnswer.requestInfoAnswerText
      };
    }

    dispatch(
      updateWAComms(pathUid, dataToSend, (err, res) => {
        console.log({ err, res });
        if (res) {
          props.history.push(
            `${constants.WA_BASE_ROUTE}/${pathUid}/${constants.WA_ROUTES.CONFIRMATION_PAGE}`
          );
        }
      })
    );
  };

  const handleRequestInfoAnswer = event => {
    let newRequestInfoAnswer = {
      ...requestInfoAnswer,
      requestInfoAnswerText: event.target.value
    };
    setErrors({
      ...errors,
      requestAnswererror: false
    });
    setRequestInfoAnswer(newRequestInfoAnswer);
  };

  const onClickEdit = () => {
    props.history.push({
      pathname: `${constants.WA_BASE_ROUTE}/${pathUid}/${constants.WA_ROUTES.DESIGN}`,
      state: {
        isEdited: true
      }
    });
  };

  if (notFound) return <NotFound />;
  if (isLoading) return <Loader />;

  return (
    <div className="skypink ml-5 mr-5">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.REVIEW}
          totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
        />
        <PageHeader heading="Review and submit campaign" />

        <Layout
          className="mt-30"
          sidebar={
            <MobileDeviceFrame
              ref={previewRef}
              data={mobilePreviewData}
              className="mobile-device-overview pt-20"
              onClickEdit={onClickEdit}
              showActions={true}
            />
          }
        >
          {isError && (
            <div>
              <FormError
                className="mb-15"
                message={constants.EMAIL_REVIEW_ERROR_MESSAGE}
                isNotBold
              />
            </div>
          )}

          <div className="mb-20">
            {/* <SMSContentAlert smsCampaign={campaign} /> */}
          </div>

          <WASummaryCards waCampaign={waCommsData} />

          <WAOverviewTables
            review
            editable
            waCampaign={waCommsData}
            errors={errors}
            handleRequestInfoAnswer={handleRequestInfoAnswer}
            requestInfoAnswerFlag={true}
          />

          {shouldShowFeedbackSection && (
            <Feedback
              feedback={feedback}
              setFeedback={setFeedback}
              errors={errors}
              setErrors={setErrors}
            />
          )}

          <BackAndNext
            onClickBack={() => {
              const uid = props.match.params.id;
              props.history.push(
                `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.DESIGN}`
              );
            }}
            onClickNext={handleNextClick}
            nextTitle="Submit"
          />
        </Layout>
      </div>
    </div>
  );
};

export default WACampaignReview;
