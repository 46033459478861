import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { DatePicker } from 'components/atoms/DatePicker';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

export const FormDatePicker = ({
  name,
  label,
  control,
  errors,
  dateFormat,
  timeFormat,
  ...props
}) => {
  const results = useWatch({
    control,
    name: props.watchInput,
    defaultValue: props.defaultWatchValue
  });
  const errorMessages = get(errors, name);

  const [properties, setProperties] = useState({
    disabled: props.defaultDisabled
  });

  const hasError = !!(errors && errorMessages);
  useEffect(() => {
    if (props.defaultValue && props.setValue) {
      props.setValue(name, moment.utc(props.defaultValue));
    }
  }, []);
  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(results, properties, setProperties);
    }
  }, [results]);

  return (
    <div
      className={classNames('form-group', props.wrapperClassName)}
      aria-live="polite"
    >
      <label htmlFor={props.id}>{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={props.defaultValue}
        render={({ field }) => {
          return (
            <DatePicker
              {...field}
              name={name}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              isValidDate={props.isValidDate}
              placeholder={props.placeholder}
              disabled={props.disabled || properties.disabled}
              hasError={hasError}
              inputGroupClassName={props.inputGroupClassName}
            />
          );
        }}
      />
      <FieldError errors={errors} name={name} />
    </div>
  );
};
