import React, { useState, useRef } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { ReactComponent as DownArrow } from 'images/down-arrow.svg';

export const SimpleSelect = ({ options, selected, handleChange }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setExpanded(false));

  const handleSelect = option => {
    handleChange(option);
    setExpanded(!expanded);
  };

  return (
    <span
      className="d-flex align-items-center email-custom-select position-relative"
      ref={ref}
    >
      <label
        htmlFor={'sortKey'}
        className={'form-check-label email-dashboard-checkbox__label'}
      >
        {`Sort by:`}
      </label>
      <div
        className="d-flex align-items-center justify-content-end"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="title sort-lable-title text-truncate">
          {selected.label}
        </div>
        <DownArrow className="down-arrow" />
      </div>

      {expanded ? (
        <div className="options position-absolute simple-select-option">
          {options.map(option => (
            <div
              key={option.value}
              className="option"
              style={{ width: 'auto' }}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      ) : null}
    </span>
  );
};
