import React from 'react';
import GuidelineWrapper from '../../atoms/GuidelineWrapper/GuidelineWrapper';
import GuideLines from '../../GuideLines';

const WAButtonGuideline = props => {
  const {
    hideCloseButton,
    hideHorizontalLine,
    noMargin,
    listStyleType = 'circle',
    listStyleTypeColor = 'black',
    noUlLineBreak = false,
    noSubTitleLineBreak
  } = props;
  return (
    <GuidelineWrapper
      className="template-modal-wrapper"
      titleClassName="cw-heading--saascampaign mt-15"
      isEmailCampaign={props.isEmailCampaign}
      onClose={props.closeIsEditModal}
      hideCloseButton={hideCloseButton}
      title={props.title}
    >
      <div className={noMargin ? '' : 'mt-4'}>
        <GuideLines
          data={props.data}
          qnaIndex={true}
          noLineBreak={true}
          ulStyle={{ listStyleType, marginLeft: 20 }}
          liStyle={{ listStyleType, paddingLeft: 0 }}
          liClassName="unordered-list-item brandidetails"
          noUlLineBreak={noUlLineBreak}
          noSubTitleLineBreak={noSubTitleLineBreak}
        />
        {hideHorizontalLine ? null : <div className="horizontal-line"></div>}
      </div>
    </GuidelineWrapper>
  );
};

export default WAButtonGuideline;
