import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchCampaignData } from '../../actions/overview';
import ReactHtmlParser from 'react-html-parser';
import constant from '../../constants';
import ReactTooltip from 'react-tooltip';
import {
  checkCampaignLanguage,
  epsilonMarket,
  fetchQnANotificationTileData
} from '../../selectors';
import groupIcon from '../../images/groupIcon.png';
import SummaryComponent from 'components/SummaryComponent';
import constants from '../../constants';
import {get, isEmpty} from 'lodash';
import { isNewMasterDataRequested } from '../organisms/QnAFieldv2/QnAFieldv2';
import ConditionallyMandatoryMassage from 'components/molecules/ConditionallyMandatoryMassage';

class FormAuthoringGrouped extends Component {
  state = {
    campaignId: this.props.campaignId,
    expand: -1,
    consentAccordion: -1
  };
  componentDidMount() {
    this.campaignId = this.props.campaignId;
    let isOverviewPage = this.props.isOverviewPage
      ? this.props.isOverviewPage
      : false;
    isOverviewPage
      ? this.props.fetchCampaignData(
          this.campaignId,
          null,
          null,
          null,
          null,
          isOverviewPage
        )
      : this.props.fetchCampaignData(this.campaignId);
  }

  handleAccordionExpandCollapse = index => {
    this.setState(prevState => ({
      expand: prevState.expand === index ? false : index
    }));
  };

  handleConsentAccordion = index => {
    this.setState(prevState => ({
      consentAccordion: prevState.consentAccordion === index ? false : index
    }));
  };

  render() {
    let fields =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.fields;
    let consents =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.consents;
    let messages =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.messages;

    let { campaign, campaignDetails, campaignConfig } = this.props;
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let isCampaignEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      this.props.campaign && this.props.campaign.campaignType === 'Incentive';
    let isIncentiveTypeCompetition =
      this.props.campaign &&
      this.props.campaign.incentiveType === 'Competitions';
    let isIncentiveTypeSamples =
      this.props.campaign &&
      this.props.campaign.incentiveType === 'Product samples';
    let country = campaign && campaign.country && campaign.country.code;
    let isEpsilonMarket = country && epsilonMarket(country);
    let languageAnsId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.languageAnsId;
    let languageAnsText =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.languageAnsText;
    let brandOptinCCID =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.brandOptinCCID;
    let campaignControlId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.campaignControlId;
    let corporateOptinCCID =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.corporateOptinCCID;
    let dataSourceId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.dataSourceId;
    let languageQuestionAnsId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.languageQuestionAnsId;

    let brandSmsOptinCCID =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.brandSmsOptinCCID;

    let myHealthMyDataOptinCCID =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.myHealthMyDataOptinCCID;

    let { colWidth, newIconClass, accordianClass } = this.props;

    let qnaTileData = fetchQnANotificationTileData(campaignDetails);

    const isMasterDataOther =
      campaign &&
      campaign.isMasterData &&
      campaign.campaignDetail &&
      campaign.campaignDetail.incentiveItPartner === 'other'
        ? true
        : false;

    let newMasterData = (isNew, colWidth) => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
            className={colWidth}
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };

    const { expand } = this.state;
    const baseConsentIndex = (fields && fields.length) || 0;
    const submitButtonIndex =
      baseConsentIndex + ((consents && consents.length) || 0);

    return (
      <div className="cw-campaign--review-section mb-40">
        <div className="mb-10">
          <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
            <h3 className="cw-heading--senary">Form Fields & Elements</h3>
            {this.props.isEditButton ? (
              <NavLink
                className="btn btn-outline-secondary ml-auto"
                to={
                  '/campaign-wizard/campaign/' +
                  this.campaignId +
                  '/configure-form-fields'
                }
              >
                Edit
              </NavLink>
            ) : (
              ''
            )}
          </div>
          {qnaTileData &&
          qnaTileData.showQnATile &&
          country !== constants.COUNTRY_US &&
          !this.props.ishideQnA ? (
            <div className="mb-20">
              <SummaryComponent
                data={this.props.campaign}
                isBrandSummary={false}
                isQnASummary={true}
                qnaTileData={qnaTileData}
              />
            </div>
          ) : null}
          {campaignDetails.fields &&
            campaignDetails.fields.map((elem, index) => {
              // START: Form fields
              if (elem.type === 'field') {
                return (
                  <React.Fragment key={elem.dataType}>
                    <div className="mb-10">
                      <div className="accordion saas-form-field-accordion">
                        <div className="card" style={{ border: 0 }}>
                          <div
                            className={`card-header accordian-card-header ${
                              this.state.expand === index
                                ? 'card-bottom-border'
                                : 'card-no-bottom'
                            }`}
                            style={{ padding: '7px 13px' }}
                          >
                            <span
                              className="saas-accordion-heading"
                              style={{ fontWeight: '700' }}
                            >
                              {elem.label}
                            </span>
                            <span
                              type="button"
                              className={
                                this.state.expand === index
                                  ? 'saas-accordion-arrow afterTransform'
                                  : this.props.isMasterData &&
                                    campaignConfig.MASTER_DATA_JOURNEY
                                      .HIDE_DEFAULT
                                  ? 'd-none'
                                  : 'saas-accordion-arrow'
                              }
                              onClick={() => {
                                this.handleAccordionExpandCollapse(index);
                              }}
                            >
                              <i
                                className={
                                  this.state.expand === index
                                    ? 'fas fa-chevron-down transformProfileIcon-180'
                                    : 'fas fa-chevron-down'
                                }
                              ></i>
                            </span>
                          </div>
                          <div
                            className={
                              this.state.expand === index
                                ? 'collapse show'
                                : 'collapse'
                            }
                          >
                            <div className="cw-campaign--review-section-content">
                              <ReviewRow
                                label="Field Category"
                                value="Standard profile field"
                              />
                              <ReviewRow
                                label={
                                  elem.label === 'Paragraph'
                                    ? 'Paragraph Text'
                                    : elem.label === 'Heading'
                                    ? 'Heading Text'
                                    : ' Field Label'
                                }
                                value={
                                  elem.label === 'Paragraph'
                                    ? ReactHtmlParser(elem.paragraph)
                                    : elem.label === 'Heading'
                                    ? elem.heading
                                    : elem.fieldLabel
                                }
                              />

                              {elem.label === 'State or Province' ? (
                                <>
                                  <ReviewRow
                                    label="Field Type"
                                    value={elem.questionType}
                                  />
                                  {elem.questionType === 'Single choice' ? (
                                    <ReviewRow
                                      label="State Values"
                                      value={
                                        elem.answers && elem.answers.length > 0
                                          ? elem.answers
                                              .map(ele => ele.value)
                                              .join(', ')
                                          : null
                                      }
                                    />
                                  ) : null}
                                </>
                              ) : null}
                              {elem.label === 'Gender' ? (
                                <ReviewRow
                                  label="Gender Values"
                                  value={
                                    elem.values && elem.values.length > 0
                                      ? elem.values
                                          .filter(
                                            ele =>
                                              ele.isChecked &&
                                              ele.translatedValue.trim()
                                                .length !== 0
                                          )
                                          .map(ele => ele.translatedValue)
                                          .join(', ')
                                      : null
                                  }
                                />
                              ) : null}

                              {elem.toolTipText &&
                              elem.toolTipText.trim().length > 0 ? (
                                <ReviewRow
                                  label="Tooltip/Help-Text"
                                  value={
                                    elem.toolTipText ? elem.toolTipText : ''
                                  }
                                />
                              ) : null}
                              {elem.label !== 'Paragraph' &&
                              elem.label !== 'Heading' ? (
                                <ReviewRow
                                  label="Mandatory field"
                                  value={
                                    elem.mandatoryToggle
                                      ? constant.MANDATORY_LABEL_MAP[
                                          'mandatory'
                                        ]
                                      : constant.MANDATORY_LABEL_MAP['optional']
                                  }
                                />
                              ) : null}
                              {get(elem, "validation.conditionalRequired.validationMessage", null) &&
                          <ReviewRow
                            label="Condition"
                            value={(<ConditionallyMandatoryMassage elem={elem} index={index} profile={fields} isProfileField noMargin/>)}
                          />
                          }
                              {elem.mandatoryToggle ? (
                                <ReviewRow
                                  label="Mandatory Field Error Message"
                                  value={
                                    elem.mandatoryMessage
                                      ? elem.mandatoryMessage
                                      : ''
                                  }
                                />
                              ) : null}
                              {elem.label === 'Date of Birth' ? (
                                <ReviewRow
                                  label="Legal Age Validation Message"
                                  value={
                                    elem.validationMessage
                                      ? elem.validationMessage
                                      : ''
                                  }
                                />
                              ) : null}
                              {(elem.label === 'Email Address' ||
                                elem.label === 'Mobile Number' ||
                                elem.label === 'Postal Code') &&
                              elem.validationMessage &&
                              elem.validationMessage.trim().length > 0 ? (
                                <ReviewRow
                                  label="Format Validation Error Message"
                                  value={
                                    elem.validationMessage
                                      ? elem.validationMessage
                                      : ''
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              // START: Form elements
              if (elem.type === 'formElement') {
                return (
                  <React.Fragment key={index}>
                    <div className="mb-10">
                      <div className="accordion saas-form-field-accordion">
                        <div className="card" style={{ border: 0 }}>
                          <div
                            className={`card-header accordian-card-header ${
                              this.state.expand === index
                                ? 'card-bottom-border'
                                : 'card-no-bottom'
                            }`}
                            style={{ padding: '7px 13px' }}
                          >
                            <span
                              className="saas-accordion-heading"
                              style={{ fontWeight: '700' }}
                            >
                              {elem.label}
                            </span>
                            <span
                              type="button"
                              className={
                                this.state.expand === index
                                  ? 'saas-accordion-arrow afterTransform'
                                  : 'saas-accordion-arrow'
                              }
                              onClick={() => {
                                this.handleAccordionExpandCollapse(index);
                              }}
                            >
                              <i
                                className={
                                  this.state.expand === index
                                    ? 'fas fa-chevron-down transformProfileIcon-180'
                                    : 'fas fa-chevron-down'
                                }
                              ></i>
                            </span>
                          </div>
                          <div
                            className={
                              this.state.expand === index
                                ? 'collapse show'
                                : 'collapse'
                            }
                          >
                            <div className="cw-campaign--review-section-content">
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    Field Category
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    Form element/Text separator
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    {elem.label === 'Paragraph'
                                      ? 'Paragraph Text'
                                      : elem.label === 'Heading'
                                      ? 'Heading Text'
                                      : ' Field Label'}
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    {elem.label === 'Paragraph'
                                      ? ReactHtmlParser(elem.paragraph)
                                      : elem.label === 'Heading'
                                      ? elem.heading
                                      : elem.fieldLabel}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              // START: Q & A
              if (
                elem.dataType === 'questionAnswerPair' &&
                !this.props.ishideQnA
              ) {
                return (
                  <React.Fragment key={index}>
                    <div className={'mb-10'}>
                      <div className="mb-10">
                        <ReactTooltip
                          place="right"
                          // type="warning"
                          multiline={true}
                          //className="cw-tooltip"
                        />
                        <div className="accordion saas-form-field-accordion">
                          <div className="card" style={{ border: 0 }}>
                            <div
                              className={`card-header ${
                                this.state.expand === index
                                  ? 'card-bottom-border'
                                  : 'card-no-bottom'
                              }`}
                              style={{ padding: '7px 13px' }}
                            >
                              <div className="row">
                                <div
                                  className={
                                    !isEpsilonMarket &&
                                    (elem.isNew ||
                                      elem.istranslatedNew ||
                                      elem.answers.find(
                                        e =>
                                          e.isNew === true ||
                                          e.istranslatedNew === true
                                      ))
                                      ? accordianClass
                                      : `col-sm-11`
                                  }
                                >
                                  <span
                                    className="saas-accordion-heading"
                                    style={{ fontWeight: '700' }}
                                  >
                                    {elem.questionLabel}
                                  </span>
                                </div>

                                {!epsilonMarket(country) &&
                                (elem.isNew ||
                                  elem.istranslatedNew ||
                                  elem.answers.find(
                                    e =>
                                      e.isNew === true ||
                                      e.istranslatedNew === true
                                  )) ? (
                                  <div
                                    className={newIconClass}
                                    style={{
                                      display: 'block',
                                      color: '#E9A40A',
                                      whiteSpace: 'pre'
                                    }}
                                  >
                                    {elem.isNew ||
                                    elem.istranslatedNew ||
                                    elem.answers.find(
                                      e =>
                                        e.isNew === true ||
                                        e.istranslatedNew === true
                                    ) ? (
                                      <>
                                        <img
                                          style={{ marginLeft: '5px' }}
                                          src={groupIcon}
                                          alt={groupIcon}
                                          width="25"
                                          height="25"
                                        ></img>
                                        &nbsp; New Master
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : null}
                                <div className="col-sm-1">
                                  <span
                                    type="button"
                                    className={
                                      this.state.expand === index
                                        ? 'saas-accordion-arrow afterTransform'
                                        : 'saas-accordion-arrow'
                                    }
                                    onClick={() => {
                                      this.handleAccordionExpandCollapse(index);
                                    }}
                                  >
                                    <i
                                      className={
                                        this.state.expand === index
                                          ? 'fas fa-chevron-down transformProfileIcon-180'
                                          : 'fas fa-chevron-down'
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                this.state.expand === index
                                  ? 'collapse show'
                                  : 'collapse'
                              }
                            >
                              <div
                                className="cw-campaign--review-section-content"
                                style={{ marginTop: '10px' }}
                              >
                                <ReviewRowQnA
                                  label="Field Category"
                                  value="Custom question & answer"
                                  country={country}
                                  colWidth={colWidth}
                                />
                                {elem.qualifyingToggle ? (
                                  <ReviewRowQnA
                                    label="Qualifying question"
                                    value="Yes"
                                    country={country}
                                    colWidth={colWidth}
                                  />
                                ) : null}

                                <br />
                                <div className="cw-question-answer-dotted-line">
                                  <div className="cw-heading-qna">
                                    <span
                                      className="float-left  fa fa-circle mt-10"
                                      style={{ marginTop: '5px' }}
                                    ></span>
                                    <strong style={{ marginLeft: '15px' }}>
                                      Question
                                    </strong>
                                  </div>
                                  <div className="cw-striped-qna-row">
                                    <div className="row">
                                      <div
                                        className={`${colWidth} cw-qna-lable`}
                                      >
                                        Question Type
                                      </div>
                                      <div
                                        className={`${
                                          epsilonMarket(country)
                                            ? `col-sm-8`
                                            : colWidth
                                        } cw-text--ternary`}
                                      >
                                        {elem.label}
                                      </div>
                                    </div>
                                  </div>
                                  {!epsilonMarket(country) ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          English-Master Data
                                        </div>
                                        <div
                                          className={`${colWidth} cw-text--ternary`}
                                          style={{ lineBreak: 'anywhere' }}
                                        >
                                          {elem.englishLabel}
                                        </div>
                                        {this.props.showMasterIcon ? null : (
                                          <div
                                            className={`${colWidth} cw-text--ternary`}
                                          >
                                            {elem.isNew
                                              ? elem.id
                                                ? elem.id
                                                : 'Awaiting'
                                              : elem.id}
                                          </div>
                                        )}
                                        {newMasterData(elem.isNew, colWidth)}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Consumer Facing Label
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country)
                                              ? `col-sm-8`
                                              : colWidth
                                          } cw-text--ternary`}
                                        >
                                          {elem.questionLabel}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {!isCampaignEnglish &&
                                  !epsilonMarket(country) ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          {campaignLanguage} Translation- Master
                                          Data
                                        </div>
                                        <div
                                          className={`${colWidth} cw-text--ternary`}
                                          style={{ lineBreak: 'anywhere' }}
                                        >
                                          {elem.translatedLabel}
                                        </div>
                                        {this.props.showMasterIcon ? null : (
                                          <div
                                            className={`${colWidth} cw-text--ternary`}
                                          >
                                            {elem.istranslatedNew
                                              ? elem.translatedId
                                                ? elem.translatedId
                                                : 'Awaiting'
                                              : elem.translatedId}
                                          </div>
                                        )}
                                        {newMasterData(
                                          elem.isNew || elem.istranslatedNew,
                                          colWidth
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                  {!epsilonMarket(country) ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Consumer Facing Label
                                        </div>
                                        <div
                                          className={`${colWidth} cw-text--ternary`}
                                        >
                                          {elem.questionLabel}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Question ID
                                        </div>
                                        <div className="col-sm-8 cw-text--ternary">
                                          {elem.id}
                                        </div>
                                        {newMasterData(elem.isNew, colWidth)}
                                      </div>
                                    </div>
                                  )}
                                  {elem.isNew &&
                                  !epsilonMarket(country) &&
                                  elem.reasonForCreation ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Reason for new request
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country)
                                              ? `col-sm-8`
                                              : colWidth
                                          } cw-text--ternary`}
                                        >
                                          {elem.reasonForCreation}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {isEpsilonMarket ||
                                  !isNewMasterDataRequested(elem) ||
                                  !elem.additionalComments ? null : (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Detailed justification for new request
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country) ||
                                            (colWidth &&
                                              colWidth.slice(-1) === '4')
                                              ? 'col-sm-8'
                                              : 'col-sm-9'
                                          } cw-text--ternary`}
                                        >
                                          {elem.additionalComments || ''}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {elem.answers && elem.answers.length > 0 ? (
                                  <>
                                    <br />
                                    <div className="cw-question-answer-dotted-line">
                                      <div className="cw-heading-qna">
                                        <span
                                          className="float-left  fa fa-circle mt-10"
                                          style={{ marginTop: '5px' }}
                                        ></span>
                                        <strong style={{ marginLeft: '15px' }}>
                                          Answers
                                        </strong>
                                      </div>
                                      {!epsilonMarket(country) &&
                                        elem.answers.map(
                                          (answer, answerIndex) => {
                                            return (
                                              <>
                                                <span className="cw-answer-answer">
                                                  <strong
                                                    style={{
                                                      marginLeft: '30px'
                                                    }}
                                                  >
                                                    Answer {answerIndex + 1}
                                                  </strong>
                                                </span>
                                                <div className="cw-striped-qna-row">
                                                  <div className="row">
                                                    <div
                                                      className={`${colWidth} cw-qna-lable`}
                                                    >
                                                      English-Master Data
                                                    </div>
                                                    <div
                                                      className={`${colWidth} cw-text--ternary`}
                                                      style={{
                                                        lineBreak: 'anywhere'
                                                      }}
                                                    >
                                                      {answer.englishLabel}
                                                    </div>
                                                    {this.props
                                                      .showMasterIcon ? null : (
                                                      <div
                                                        className={`${colWidth} cw-text--ternary`}
                                                      >
                                                        {answer.isNew
                                                          ? answer.value
                                                            ? answer.value
                                                            : 'Awaiting'
                                                          : answer.value}
                                                      </div>
                                                    )}
                                                    {newMasterData(
                                                      answer.isNew,
                                                      colWidth
                                                    )}
                                                  </div>
                                                </div>
                                                {!isCampaignEnglish ? (
                                                  <div className="cw-striped-qna-row">
                                                    <div className="row">
                                                      <div
                                                        className={`${colWidth} cw-qna-lable`}
                                                      >
                                                        {campaignLanguage}{' '}
                                                        Translation- Master Data
                                                      </div>
                                                      <div
                                                        className={`${colWidth} cw-text--ternary`}
                                                        style={{
                                                          lineBreak: 'anywhere'
                                                        }}
                                                      >
                                                        {answer.translatedLabel}
                                                      </div>
                                                      {this.props
                                                        .showMasterIcon ? null : (
                                                        <div
                                                          className={`${colWidth} cw-text--ternary`}
                                                        >
                                                          {answer.istranslatedNew
                                                            ? answer.translatedId
                                                              ? answer.translatedId
                                                              : 'Awaiting'
                                                            : answer.translatedId}
                                                        </div>
                                                      )}
                                                      {newMasterData(
                                                        answer.isNew ||
                                                          answer.istranslatedNew,
                                                        colWidth
                                                      )}
                                                    </div>
                                                  </div>
                                                ) : null}
                                                <div className="cw-striped-qna-row">
                                                  <div className="row">
                                                    <div
                                                      className={`${colWidth} cw-qna-lable`}
                                                    >
                                                      Consumer Facing Label
                                                    </div>
                                                    <div
                                                      className={`${colWidth} cw-text--ternary`}
                                                    >
                                                      {answer.answerLabel}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      {epsilonMarket(country) &&
                                        elem.answers.map(
                                          (answer, answerIndex) => {
                                            return (
                                              <>
                                                {elem.value !== 'freeText' &&
                                                elem.value !==
                                                  'freeTextArea' ? (
                                                  <div className="cw-striped-qna-row">
                                                    <div className="row">
                                                      <div
                                                        className={`${colWidth} cw-qna-lable`}
                                                      >
                                                        Consumer Facing Label
                                                      </div>
                                                      <div
                                                        className={`${
                                                          epsilonMarket(country)
                                                            ? `col-sm-8`
                                                            : colWidth
                                                        } cw-text--ternary`}
                                                      >
                                                        {answer.answerLabel}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                <div className="cw-striped-qna-row">
                                                  <div className="row">
                                                    <div
                                                      className={`${colWidth} cw-qna-lable`}
                                                    >
                                                      {elem.value !== 'freeText'
                                                        ? `Answer ${answerIndex +
                                                            1} ID`
                                                        : `Answer ID`}
                                                    </div>
                                                    <div
                                                      className={`${
                                                        epsilonMarket(country)
                                                          ? `col-sm-8`
                                                          : colWidth
                                                      } cw-text--ternary`}
                                                    >
                                                      {answer.value}
                                                    </div>
                                                    {newMasterData(
                                                      answer.isNew,
                                                      colWidth
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="cw-striped-qna-row">
                                                  <div className="row">
                                                    <div
                                                      className={`${colWidth} cw-qna-lable`}
                                                    >
                                                      {elem.value !== 'freeText'
                                                        ? `Survey Question Answer ${answerIndex +
                                                            1} ID`
                                                        : `Survey Question Answer ID`}
                                                    </div>
                                                    <div
                                                      className={`${
                                                        epsilonMarket(country)
                                                          ? `col-sm-8`
                                                          : colWidth
                                                      } cw-text--ternary`}
                                                    >
                                                      {
                                                        answer.epsilonQuestionAnswerId
                                                      }
                                                    </div>
                                                    {newMasterData(
                                                      answer.isNew,
                                                      colWidth
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                  </>
                                ) : null}
                                <br />
                                <div
                                  className={
                                    this.props.isMasterData &&
                                    campaignConfig.MASTER_DATA_JOURNEY
                                      .HIDE_DEFAULT
                                      ? 'd-none'
                                      : 'cw-question-answer-dotted-line'
                                  }
                                >
                                  <div className="cw-heading-qna">
                                    <span
                                      className="float-left  fa fa-circle mt-10"
                                      style={{ marginTop: '5px' }}
                                    ></span>
                                    <strong style={{ marginLeft: '15px' }}>
                                      Other Configurations
                                    </strong>
                                  </div>
                                  {elem.toolTip &&
                                  elem.toolTip.trim().length > 0 ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Tooltip/Help-Text
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country)
                                              ? `col-sm-8`
                                              : colWidth
                                          } cw-text--ternary`}
                                        >
                                          {elem.toolTip}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="cw-striped-qna-row">
                                    <div className="row">
                                      <div
                                        className={`${colWidth} cw-qna-lable`}
                                      >
                                        Mandatory field
                                      </div>
                                      <div
                                        className={`${
                                          epsilonMarket(country)
                                            ? `col-sm-8`
                                            : colWidth
                                        } cw-text--ternary`}
                                      >
                                        {elem.mandatoryToggle
                                          ? constant.MANDATORY_LABEL_MAP[
                                              'mandatory'
                                            ]
                                          : constant.MANDATORY_LABEL_MAP[
                                              'optional'
                                            ]}
                                      </div>
                                    </div>
                                  </div>
                                  {elem.mandatoryToggle ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Mandatory Field Error Message
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country)
                                              ? `col-sm-8`
                                              : colWidth
                                          } cw-text--ternary`}
                                        >
                                          {elem.mandatoryMessage}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              // START: Incentive
              if (isCampaignIncentive && elem.type === 'incentiveFields') {
                return (
                  <React.Fragment key={index}>
                    <div className="mb-10">
                      <div className={`mb-10`}>
                        <div className="accordion saas-form-field-accordion">
                          <div className="card" style={{ border: 0 }}>
                            <div
                              className={`card-header accordian-card-header ${
                                this.state.expand === index
                                  ? 'card-bottom-border'
                                  : 'card-no-bottom'
                              }`}
                              style={{ padding: '7px 13px' }}
                            >
                              <span
                                className="saas-accordion-heading"
                                style={{ fontWeight: '700' }}
                              >
                                {elem.label}
                              </span>
                              <span
                                type="button"
                                className={
                                  this.state.expand === index
                                    ? 'saas-accordion-arrow afterTransform'
                                    : this.props.isMasterData &&
                                      campaignConfig.MASTER_DATA_JOURNEY
                                        .HIDE_DEFAULT &&
                                      elem.dataType !== 'samplePrizeSelection'
                                    ? 'd-none'
                                    : 'saas-accordion-arrow'
                                }
                                onClick={() => {
                                  this.handleAccordionExpandCollapse(index);
                                }}
                              >
                                <i
                                  className={
                                    this.state.expand === index
                                      ? 'fas fa-chevron-down transformProfileIcon-180'
                                      : 'fas fa-chevron-down'
                                  }
                                ></i>
                              </span>
                            </div>
                            <div
                              className={
                                this.state.expand === index
                                  ? 'collapse show'
                                  : 'collapse'
                              }
                            >
                              <div className="cw-campaign--review-section-content">
                                <ReviewRow
                                  label="Field Category"
                                  value="Incentive field"
                                />
                                {elem.qualifyingToggle ? (
                                  <ReviewRow
                                    label="Qualifying question"
                                    value="Yes"
                                  />
                                ) : null}
                                <ReviewRow
                                  label="Field Label"
                                  value={elem.fieldLabel ? elem.fieldLabel : ''}
                                />

                                {isIncentiveTypeSamples &&
                                elem.dataType === 'samplePrizeSelection' ? (
                                  <>
                                    <div className="cw-striped-profile-row">
                                      <div className="row">
                                        <div className="col-sm-4 cw-qna-lable">
                                          Samples
                                        </div>
                                        <div className="col-sm-8 cw-text--ternary">
                                          {elem.answers &&
                                          elem.answers.length > 0
                                            ? elem.answers
                                                .filter(ele => ele.label)
                                                .map(ele => ele.label)
                                                .join(', ')
                                            : 'Awaiting'}
                                        </div>
                                      </div>
                                    </div>
                                    {elem.answers && elem.answers.length > 1 ? (
                                      <div className="cw-striped-profile-row">
                                        <div className="row">
                                          <div className="col-sm-4 cw-qna-lable">
                                            Sample Selection
                                          </div>
                                          <div className="col-sm-8 cw-text--ternary">
                                            {elem.questionType ===
                                            'Single choice'
                                              ? constant.SAMPLE_OPTIONS
                                                  .singleSample
                                              : elem.questionType ===
                                                'Multiple choice'
                                              ? constant.SAMPLE_OPTIONS
                                                  .multipleSample
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}

                                {elem.dataType === 'fileUpload' ? (
                                  <>
                                    {elem.maxFileSize ? (
                                      <ReviewRow
                                        label="Maximum Upload File Size"
                                        value={
                                          elem.maxFileSize
                                            ? elem.maxFileSize
                                            : ''
                                        }
                                      />
                                    ) : null}
                                    {elem.values &&
                                    elem.values
                                      .filter(ele => ele.isChecked)
                                      .map(ele => ele.value)
                                      .join(', ').length > 0 ? (
                                      <ReviewRow
                                        label="Allowed File Types"
                                        value={
                                          elem.values && elem.values.length > 0
                                            ? elem.values
                                                .filter(ele => ele.isChecked)
                                                .map(ele => ele.value)
                                                .join(', ')
                                            : ''
                                        }
                                      />
                                    ) : null}
                                    <ReviewRow
                                      label="Failed Upload Error Message"
                                      value={
                                        elem.failedUploadMessage
                                          ? elem.failedUploadMessage
                                          : ''
                                      }
                                    />
                                  </>
                                ) : null}

                                {elem.toolTip &&
                                elem.toolTip.trim().length > 0 ? (
                                  <ReviewRow
                                    label="Tooltip/Help-Text"
                                    value={elem.toolTip ? elem.toolTip : ''}
                                  />
                                ) : null}
                                {this.props.isMasterData &&
                                campaignConfig.MASTER_DATA_JOURNEY
                                  .HIDE_DEFAULT &&
                                elem.dataType !==
                                  'samplePrizeSelection' ? null : (
                                  <ReviewRow
                                    label="Mandatory field"
                                    value={
                                      elem.mandatoryToggle
                                        ? constant.MANDATORY_LABEL_MAP[
                                            'mandatory'
                                          ]
                                        : constant.MANDATORY_LABEL_MAP[
                                            'optional'
                                          ]
                                    }
                                  />
                                )}

                                {elem.mandatoryToggle ? (
                                  <ReviewRow
                                    label="Mandatory Field Error Message"
                                    value={
                                      elem.mandatoryMessage
                                        ? elem.mandatoryMessage
                                        : ''
                                    }
                                  />
                                ) : null}
                                {elem.validationMessage &&
                                elem.validationMessage.trim().length > 0 ? (
                                  <ReviewRow
                                    label="Format Validation Error Message"
                                    value={
                                      elem.validationMessage
                                        ? elem.validationMessage
                                        : ''
                                    }
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              return null;
            })}
        </div>

        <div>
          {/* <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
            <h3 className="cw-heading--senary">
              Consent and Compliance Fields
            </h3>
            {this.props.isEditButton ? (
              <NavLink
                className="btn btn-outline-secondary ml-auto"
                to={
                  '/campaign-wizard/campaign/' +
                  this.campaignId +
                  this.props.consentPage
                }
              >
                Edit
              </NavLink>
            ) : (
              ''
            )}
          </div> */}

          {campaignDetails.consents &&
            campaignDetails.consents.map((elem, index) => {
              return (
                <React.Fragment key={elem.optInType}>
                  <div className="mb-10">
                    <div className="accordion saas-form-field-accordion">
                      <div className="card" style={{ border: 0 }}>
                        <div
                          className={`card-header accordian-card-header ${
                            expand === baseConsentIndex + index
                              ? 'card-bottom-border'
                              : 'card-no-bottom'
                          }`}
                          style={{ padding: '7px 13px' }}
                        >
                          <span
                            className="saas-accordion-heading"
                            style={{ fontWeight: '700' }}
                          >
                            {elem.optInType === 'privacyNotice'
                              ? 'Privacy Notice'
                              : elem.label}
                          </span>
                          <span
                            type="button"
                            className={
                              expand === baseConsentIndex + index
                                ? 'saas-accordion-arrow afterTransform'
                                : 'saas-accordion-arrow'
                            }
                            onClick={() => {
                              this.handleAccordionExpandCollapse(
                                baseConsentIndex + index
                              );
                            }}
                          >
                            <i
                              className={
                                expand === baseConsentIndex + index
                                  ? 'fas fa-chevron-down transformProfileIcon-180'
                                  : 'fas fa-chevron-down'
                              }
                            ></i>
                          </span>
                        </div>
                        <div
                          className={
                            expand === baseConsentIndex + index
                              ? 'collapse show'
                              : 'collapse'
                          }
                        >
                          <div className="cw-campaign--review-section-content">
                            <ReviewRow
                              label="Field category"
                              value="Consents, compliance & legal"
                            />
                            {elem.optInType ===
                            'multiChannelUnileverConsent' ? (
                              <ReviewRow
                                label="Applicable Channels"
                                value={
                                  elem.multi_consents_value &&
                                  elem.multi_consents_value.length > 0
                                    ? elem.multi_consents_value
                                        .filter(
                                          ele =>
                                            ele.isChecked &&
                                            ele.translatedValue.trim()
                                              .length !== 0
                                        )
                                        .map(ele => ele.translatedValue)
                                        .join(', ')
                                    : null
                                }
                              />
                            ) : null}
                            {campaign &&
                            campaign.isMasterData &&
                            campaignConfig.MASTER_DATA_JOURNEY
                              .HIDE_DEFAULT ? null : (
                              <>
                                {elem.optInType !== 'privacyNotice' &&
                                elem.optInType !== 'legalDisclaimer' &&
                                elem.optInType !== 'contentInterestAreas' ? (
                                  <ReviewRow
                                    label="Display Options"
                                    value={
                                      elem.consentType === 'mandatory'
                                        ? 'Checkbox (Explicit consent)'
                                        : elem.consentType === 'optional'
                                        ? 'Checkbox (Explicit consent)'
                                        : elem.consentType === 'implicit'
                                        ? 'Statement (Implicit consent, text merged with another consent)'
                                        : elem.consentType ===
                                          'implicitStandalone'
                                        ? 'Statement (Implicit consent - standalone)'
                                        : elem.consentType
                                    }
                                  />
                                ) : null}  
                                {elem.optInType !== 'privacyNotice' &&
                                elem.optInType !== 'legalDisclaimer' &&
                                elem.optInType !== 'contentInterestAreas' &&
                                (elem.consentType === 'mandatory' ||
                                  elem.consentType === 'optional') ? (
                                  <ReviewRow
                                    label="Mandatory field"
                                    value={
                                      elem.consentType === 'mandatory'
                                        ? constant.MANDATORY_LABEL_MAP[
                                            elem.consentType
                                          ]
                                        : elem.consentType === 'optional'
                                        ? constant.MANDATORY_LABEL_MAP[
                                            elem.consentType
                                          ]
                                        : elem.consentType
                                    }
                                  />
                                ) : null}
                                {(epsilonMarket(country)? get(elem, "validation.mandatory", false) : get(elem, "validation.conditionalRequired.validationMessage", null)) &&
                                  <ReviewRow
                                    label="Condition"
                                    value={(<ConditionallyMandatoryMassage elem={elem} index={index} noMargin/>)}
                                  />
                                }
                                {elem.optInType !== 'privacyNotice' &&
                                elem.optInType !== 'legalDisclaimer' &&
                                (elem.consentType === 'mandatory' ||
                                !isEmpty(elem?.validation?.conditionalRequired)) ? (
                                  <ReviewRow
                                    label="Mandatory Error Message"
                                    value={
                                      elem.mandatoryMessage
                                        ? elem.mandatoryMessage
                                        : ''
                                    }
                                  />
                                ) : null}
                                {elem.optInType === 'legalAgeConsent' ? (
                                  <ReviewRow
                                    label="Minimum legal age of consent"
                                    value={
                                      elem.ageOfConsent
                                        ? elem.ageOfConsent
                                        : '16'
                                    }
                                  />
                                ) : null}
                              </>
                            )}
                            {elem.optInType !== 'contentInterestAreas' && (
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    Consent Statement
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    <p>
                                      {elem.defaultCopy
                                        ? ReactHtmlParser(elem.defaultCopy)
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {campaign &&
                            campaign.isMasterData &&
                            campaignConfig.MASTER_DATA_JOURNEY
                              .HIDE_DEFAULT ? null : (
                              <>
                                {elem.optInType === 'additionalSubscription' ||
                                elem.optInType === 'additionalSubscription1' ? (
                                  <>
                                    {elem.frequency ? (
                                      <ReviewRow
                                        label="Frequency"
                                        value={elem.frequency}
                                      />
                                    ) : null}
                                    {elem.serviceId ? (
                                      <ReviewRow
                                        label="Service ID"
                                        value={elem.serviceId}
                                      />
                                    ) : null}
                                  </>
                                ) : null}
                                {elem.optInType === 'brandEmailConsent' &&
                                !isEpsilonMarket ? (
                                  <>
                                    <ReviewRow
                                      label="Email Senders Name"
                                      value={
                                        elem.emailSenderName
                                          ? elem.emailSenderName
                                          : null
                                      }
                                    />
                                    {elem.replyToEmailName &&
                                    elem.replyToEmailName.trim().length > 0 ? (
                                      <ReviewRow
                                        label="Reply to Email Name"
                                        value={
                                          elem.replyToEmailName
                                            ? elem.replyToEmailName
                                            : null
                                        }
                                      />
                                    ) : null}
                                    {elem.replyToEmailAdderess &&
                                    elem.replyToEmailAdderess.trim().length >
                                      0 ? (
                                      <ReviewRow
                                        label="Reply to Email Address"
                                        value={
                                          elem.replyToEmailAdderess
                                            ? elem.replyToEmailAdderess
                                            : null
                                        }
                                      />
                                    ) : null}
                                  </>
                                ) : null}
                              </>
                            )}
                            {elem.optInType === 'brandEmailConsent' &&
                              isEpsilonMarket && (
                                <>
                                  {brandOptinCCID && (
                                    <ReviewRow
                                      label="Brand Opt-in CCID (Email)"
                                      value={brandOptinCCID}
                                    />
                                  )}
                                </>
                              )}
                            {elem.optInType === 'unileverEmailConsent' &&
                              isEpsilonMarket && (
                                <>
                                  {corporateOptinCCID && (
                                    <ReviewRow
                                      label="Corporate Opt-in CCID (Email)"
                                      value={corporateOptinCCID}
                                    />
                                  )}
                                </>
                              )}
                            {elem.optInType === 'myHealthMyDataConsent' &&
                              isEpsilonMarket && (
                                <>
                                  {myHealthMyDataOptinCCID && (
                                    <ReviewRow
                                      label="MHMD Opt-in CCID"
                                      value={myHealthMyDataOptinCCID}
                                    />
                                  )}
                                </>
                              )}
                            {elem.optInType === 'brandSMSConsent' &&
                              isEpsilonMarket && (
                                <>
                                  {brandSmsOptinCCID && (
                                    <ReviewRow
                                      label="Brand Opt-in CCID (SMS)"
                                      value={brandSmsOptinCCID}
                                    />
                                  )}
                                </>
                              )}
                            {elem.optInType ===
                              'combinedUnileverAndBrandEmailConsent' &&
                              isEpsilonMarket && (
                                <>
                                  {brandOptinCCID && (
                                    <ReviewRow
                                      label="Brand Opt-in CCID"
                                      value={brandOptinCCID}
                                    />
                                  )}
                                  {corporateOptinCCID && (
                                    <ReviewRow
                                      label="Corporate Opt-in CCID"
                                      value={corporateOptinCCID}
                                    />
                                  )}
                                </>
                              )}
                            {elem.optInType === 'contentInterestAreas' ? (
                              <>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Note
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      Content Interest Areas will render on the
                                      sign up form as a question to consumers to
                                      understand the kind of content they are
                                      interested in. Consumers will be presented
                                      with an optional question and can choose
                                      multiple interest areas. The labels of the
                                      field and values will remain fixed as of
                                      now but will display in local language of
                                      the form
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Field label
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      Are there any of these areas you would
                                      like to hear more on from our brands
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Field values
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      <ul className="cia-list-review">
                                        <li>Parenting &amp; Family</li>
                                        <li>Planet, Nature and Society</li>
                                        <li>Home &amp; Hygiene</li>
                                        <li>Food &amp; Recipes</li>
                                        <li>Beauty &amp; Styling</li>
                                        <li>
                                          Wellness, Nutrition and Active
                                          Lifestyle
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Mandatory field
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      No
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
        {/* START: Submit */}
        <>
          <div className={`mb-10`}>
            <div className="accordion saas-form-field-accordion">
              <div className="card" style={{ border: 0 }}>
                <div
                  className={`card-header accordian-card-header ${
                    this.state.expand === submitButtonIndex
                      ? 'card-bottom-border'
                      : 'card-no-bottom'
                  }`}
                  style={{ padding: '7px 13px' }}
                >
                  <span
                    className="saas-accordion-heading"
                    style={{ fontWeight: '700' }}
                  >
                    Submit button
                  </span>
                  <span
                    type="button"
                    className={
                      this.state.expand === submitButtonIndex
                        ? 'saas-accordion-arrow afterTransform'
                        : 'saas-accordion-arrow'
                    }
                    onClick={() => {
                      this.handleAccordionExpandCollapse(submitButtonIndex);
                    }}
                  >
                    <i
                      className={
                        this.state.expand === submitButtonIndex
                          ? 'fas fa-chevron-down transformProfileIcon-180'
                          : 'fas fa-chevron-down'
                      }
                    ></i>
                  </span>
                </div>
                <div
                  className={
                    this.state.expand === submitButtonIndex
                      ? 'collapse show'
                      : 'collapse'
                  }
                >
                  <div className="cw-campaign--review-section-content">
                    <ReviewRow
                      label="Field Category"
                      value="Form element/Text separator"
                    />
                    <ReviewRow
                      label="Submit Button Label"
                      value={messages && messages.submit}
                    />
                    <ReviewRow
                      label="Submission Failure Message"
                      value={messages && messages.submissionError}
                    />
                    <ReviewRow
                      label="Campaign Expiry Message"
                      value={messages && messages.campaignExpiry}
                    />
                    <ReviewRow
                      label="Mandatory Fields Indicator Text"
                      value={messages && messages.mandatoryFieldsIndicator}
                    />

                    {isIncentiveTypeCompetition ? (
                      isMasterDataOther ? null : (
                        <div className="cw-striped-profile-row mb-2">
                          <div className="row">
                            <div className="col-sm-4 cw-qna-lable">
                              Instant Win/Lose
                            </div>
                            <div className="col-sm-8 cw-text--ternary">
                              {campaign.campaignDetail &&
                              campaign.campaignDetail.instantWinLose
                                ? 'Yes'
                                : 'No'}
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}

                    {/* Epsilon fields */}
                    {epsilonMarket(country) && (
                      <div className="mb-20">
                        <ReviewRow
                          label="Data Source ID"
                          value={dataSourceId}
                        />
                        <ReviewRow
                          label="Campaign Control ID (CCID)"
                          value={campaignControlId}
                        />
                        {languageQuestionAnsId && (
                          <ReviewRow
                            label="Language Question Answer ID"
                            value={languageQuestionAnsId}
                          />
                        )}
                        {languageAnsId && (
                          <ReviewRow
                            label="Language Answer ID"
                            value={languageAnsId}
                          />
                        )}
                        {languageAnsText && (
                          <ReviewRow
                            label="Language Answer Text"
                            value={languageAnsText}
                          />
                        )}

                        <div class="cw-striped-profile-row"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* END: Submit */}

        {epsilonMarket(country) ? (
          <>
            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix mt-40">
              <h3 className="cw-heading--senary">Epsilon Master Data</h3>
              {this.props.isEditButton ? (
                <NavLink
                  className="btn btn-outline-secondary ml-auto"
                  to={`/campaign-wizard/campaign/${this.campaignId}/configure-form-fields`}
                >
                  Edit
                </NavLink>
              ) : (
                ''
              )}
            </div>
            <div className="mb-20">
              <div className="cw-striped-profile-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Data Source ID</strong>
                  </div>
                  <div className="col-sm-8 cw-text--ternary">
                    {dataSourceId}
                  </div>
                </div>
              </div>
              <div className="cw-striped-profile-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Campaign Control ID (CCID)</strong>
                  </div>
                  <div className="col-sm-8 cw-text--ternary">
                    {campaignControlId}
                  </div>
                </div>
              </div>
              {brandOptinCCID ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Brand Opt-in CCID (Email)</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {brandOptinCCID}
                    </div>
                  </div>
                </div>
              ) : null}
              {corporateOptinCCID ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Corporate Opt-in CCID (Email)</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {corporateOptinCCID}
                    </div>
                  </div>
                </div>
              ) : null}
              {brandSmsOptinCCID ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Brand Opt-in CCID (SMS)</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {brandSmsOptinCCID}
                    </div>
                  </div>
                </div>
              ) : null}
              {myHealthMyDataOptinCCID ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>MHMD Opt-in CCID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {myHealthMyDataOptinCCID}
                    </div>
                  </div>
                </div>
              ) : null}
              {languageQuestionAnsId ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Language Question Answer ID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {languageQuestionAnsId}
                    </div>
                  </div>
                </div>
              ) : null}
              {languageAnsId ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Language Answer ID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {languageAnsId}
                    </div>
                  </div>
                </div>
              ) : null}
              {languageAnsText ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Language Answer Text</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {languageAnsText}
                    </div>
                  </div>
                </div>
              ) : null}
              <div class="cw-striped-profile-row"></div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const ReviewRow = ({ label, value }) => (
  <div className="cw-striped-profile-row">
    <div className="row">
      <div className="col-sm-4 cw-qna-lable">{label}</div>
      <div className="col-sm-8 cw-text--ternary">{value}</div>
    </div>
  </div>
);

export const ReviewRowQnA = ({ label, value, country, colWidth }) => (
  <div className="cw-striped-qna-row">
    <div className="row">
      <div className={`${colWidth} cw-qna-lable`}>{label}</div>
      <div
        className={`${
          epsilonMarket(country) ? 'col-sm-8' : colWidth
        } cw-text--ternary`}
      >
        {value}
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  campaign: state.overview.campaign,
  campaignDetails: state.overview.campaignDetails
});

export default connect(mapStateToProps, {
  fetchCampaignData
})(FormAuthoringGrouped);
