import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import moment from 'moment';

const WAChatLTO = ({ data, headerType, waButtons }) => {
  console.log('data: ', data);
  const showLTO = get(data, 'waLtoDetails.ltoEnable', false);
  const isHeaderImage = headerType === 'headerImage';
  console.log('isHeaderImage: ', isHeaderImage);

  const getCopyCode = () => {
    let codeText = 'BUYONEGETONE';
    if (waButtons && waButtons.length) {
      const list = waButtons.find(item => (item.id = 'nonQuickReply'));
      if (list) {
        const findList = list.nestedArray.find(
          elem => elem.type === 'Copy code button'
        );
        if (findList) {
          codeText = findList.buttonValue;
        }
      }
    }
    return codeText;
  };

  return (
    showLTO && (
      <div
        className={classNames('wa-lto-container', {
          'mar-top': !isHeaderImage
        })}
      >
        <div
          className={classNames('wa-lto-box', {
            'rounded-top': !isHeaderImage,
            'rounded-bottom': true
          })}
        >
          <div className="wa-lto-icon">
            <span className="">
              <i className="zmdi zmdi-card-giftcard"></i>
            </span>
          </div>
          <div className="wa-lto-info">
            <span className="wa-lto-title">
              {get(data, 'waLtoDetails.ltoSummary')}
            </span>
            {get(data, 'waLtoDetails.expirationEnable') && (
              <span className="wa-lto-time">
                Offer ends on{' '}
                {moment(get(data, 'waLtoDetails.expirationDate')).format(
                  'MMM DD'
                )}
              </span>
            )}
            <span className="wa-lto-code">Code {getCopyCode()}</span>
          </div>
        </div>
      </div>
    )
  );
};

export default WAChatLTO;
