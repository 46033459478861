import React from 'react';
import { useWatch } from 'react-hook-form';
import WARichTextInputWrapper from 'components/molecules/WARichTextInputWrapper';
import { get } from 'lodash';

const WARichTextBodyComp = ({
  name,
  id,
  label,
  control,
  register,
  defaultValue,
  defaultValueDynamicVar,
  errors,
  waDesign,
  maxLength,
  ...props
}) => {
  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(waDesign, `${'carousel'}.messageType`)
  });
  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(waDesign, `templateUse`)
  });
  if (messageType && messageType.value === 'carousel') return null;

  return (
    <WARichTextInputWrapper
      name={name}
      id={id}
      label={label}
      control={control}
      register={register}
      setValue={props.setValue}
      watch={props.watch}
      defaultValue={defaultValue}
      defaultValueDynamicVar={defaultValueDynamicVar}
      errors={errors}
      formGroupWrapperClass="mt-1 wa-design-sections"
      maxLength={maxLength}
      showCharCount={true}
      disabled={templateUse === 'reuse'}
    />
  );
};

export default WARichTextBodyComp;
