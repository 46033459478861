import * as yup from 'yup';
import constants from 'constants/config';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { updateWAComms } from '../../actions/wabriefings/waComms';
import {
  getYupSchema,
  transformEmailBriefingPayload,
  WACampaignSubmitRedirectURL
} from 'selectors';
import moment from 'moment';
import { get } from 'lodash';
import { WAAttributes } from 'components/organisms/WAAttributes';
import { WaABTestingInput } from 'components/molecules/WaABTestInput';
import { getWAButtonDefaults } from 'utilities/util';

const checkIfCleanUpRequired = (mainData, defaultData) => {
  const result = { cleanABTest: false, isVariantChange: false };
  const isAbTestConfigured = get(defaultData, 'isAbTestConfigured');
  const oldValue = get(defaultData, 'abTesting.performABTests');
  const newValue = get(mainData, 'abTesting.performABTests');
  const oldSplit = get(defaultData, 'abTesting.abTestSplits');
  const newSplit = get(mainData, 'abTesting.abTestSplits');
  const oldElements = get(defaultData, 'abTesting.abTestElements');
  const newElements = get(mainData, 'abTesting.abTestElements');
  if (isAbTestConfigured && oldValue === true && newValue === false) {
    result.cleanABTest = true;
  }
  if (isAbTestConfigured && oldElements !== newElements) {
    result.cleanABTest = true;
  }
  if (
    oldSplit === '3-variants-5-5-5-85' &&
    newSplit !== '3-variants-5-5-5-85'
  ) {
    result.isVariantChange = true;
  }
  return result;
};

const WAcampaignAttributesSchema = (
  dispatch,
  {
    defaultValues,
    match,
    history,
    waType,
    status,
    isEdit,
    toggleShowDynamicCouponAlert
  }
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const paramUid = match.params.id;
    const {
      couponCode,
      kanaTicketNumber,
      waCoupon,
      waCouponType,
      couponFileUpload,
      shareCouponFileViaKana
    } = data.waAttributes;

    const dataToSend = {
      ...data,
      userEmail: user.emailId,
      userName: user.name
    };

    dataToSend.waAttributes = waCoupon
      ? {
          waCoupon: {
            couponApplied: waCoupon,
            type: waCouponType,
            code: waCouponType === 'staticCoupon' ? couponCode : '',
            kanaTicketNumber:
              waCouponType === 'dynamicCoupon' ? kanaTicketNumber : '',
            couponFileUpload:
              waCouponType === 'dynamicCoupon' ? couponFileUpload : '',
            shareCouponFileViaKana:
              waCouponType === 'dynamicCoupon' ? shareCouponFileViaKana : false
          }
        }
      : {};

    // if WA campaign has design configued
    if (get(defaultValues, 'waDesign')) {
      const btnList = get(defaultValues, 'waDesign.waButtons', []);
      if (btnList.length) {
        dataToSend.waDesign = {
          ...get(defaultValues, 'waDesign'),
          waButtons: getWAButtonDefaults(btnList, waCouponType, couponCode)
        };
      }
    }

    dispatch(
      updateWAComms(paramUid, dataToSend, (error, result) => {
        if (error) {
          console.log('error: ', error);
        } else {
          if (paramUid) {
            history.push(
              WACampaignSubmitRedirectURL({
                backToReview: isEdited && backToReview,
                status: status,
                uid: paramUid,
                nextPage: `${constants.WA_ROUTES.SCHEDULE}`
              })
            );
          }
        }
      })
    );
  };

  const abTestFieldConfig = constants.featureFlags.ENABLE_WA_ABTESTING
    ? [
        {
          type: 'custom',
          name: 'abTesting',
          label: 'A/B or multivariate tests',
          component: WaABTestingInput,
          id: 'abTesting',
          defaultValue: defaultValues,
          rules: yup.object({
            performABTests: yup.bool(),
            abTestElements: yup
              .string()
              .when('performABTests', {
                is: data => !!data,
                then: schema =>
                  schema.required('Please specify A/B test elements')
              })
              .nullable(),
            abTestSplits: yup
              .string()
              .when('performABTests', {
                is: data => !!data,
                then: schema => schema.required('Please specify A/B test split')
              })
              .nullable(),
            winCriteria: yup
              .string()
              .when('performABTests', {
                is: data => !!data,
                then: schema => schema.required('Please specify win criteria')
              })
              .nullable(),
            testDuration: yup
              .object()
              .shape({
                label: yup.string().required(),
                value: yup.string().required()
              })
              .when('abTestSplits', {
                is: data =>
                  constants.EMAIL_AB_TEST_DURATION_CHECK_LIST.includes(data),
                then: schema => schema.required('Please select test duration')
              })
              .default(undefined)
          })
        }
      ]
    : [];

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'waAttributes',
        id: 'waAttributes',
        label: 'Coupons',
        component: WAAttributes,
        defaultValue: defaultValues,
        rules: yup.object({
          waCoupon: yup.bool(),
          waCouponType: yup
            .string()
            .when('waCoupon', {
              is: data => !!data,
              then: schema => schema.required('Please select coupon type')
            })
            .nullable(),
          couponCode: yup
            .string()
            .when(['waCouponType', 'waCoupon'], (type, applied, schema) => {
              if (applied && type === 'staticCoupon') {
                return schema
                  .required('Please provide a coupon code')
                  .max(50, 'Coupon code cannot be longer than 50 characters');
              }
              return schema;
            })
            .nullable(),
          kanaTicketNumber: yup
            .string()
            .when(
              ['waCouponType', 'waCoupon', 'shareCouponFileViaKana'],
              (type, applied, isShareViaKana, schema) => {
                if (applied && type === 'dynamicCoupon' && isShareViaKana) {
                  return schema
                    .matches(
                      /^$|^CS\d{7}$/,
                      "Please enter a valid UNA ticket in format 'CS1234567'"
                    )
                    .nullable()
                    .max(
                      9,
                      "Please enter a valid UNA ticket in format 'CS1234567'"
                    );
                }
                return schema;
              }
            )
            .nullable()
        }),
        toggleShowDynamicCouponAlert
      },
      ...abTestFieldConfig
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      const {
        couponCode,
        kanaTicketNumber,
        waCoupon,
        waCouponType,
        couponFileUpload,
        shareCouponFileViaKana
      } = data.waAttributes;

      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        ...data,
        status: '1'
      };

      dataToSend.waAttributes = waCoupon
        ? {
            waCoupon: {
              couponApplied: waCoupon,
              type: waCouponType,
              code: waCouponType === 'staticCoupon' ? couponCode : '',
              kanaTicketNumber:
                waCouponType === 'dynamicCoupon' ? kanaTicketNumber : '',
              couponFileUpload:
                waCouponType === 'dynamicCoupon' ? couponFileUpload : '',
              shareCouponFileViaKana:
                waCouponType === 'dynamicCoupon'
                  ? shareCouponFileViaKana
                  : false
            }
          }
        : {};

      // if WA campaign has design configued
      if (get(defaultValues, 'waDesign')) {
        const btnList = get(defaultValues, 'waDesign.waButtons', []);
        if (btnList.length) {
          dataToSend.waDesign = {
            ...get(defaultValues, 'waDesign'),
            waButtons: getWAButtonDefaults(btnList, waCouponType, couponCode)
          };
        }
      }

      dispatch(
        updateWAComms(
          paramUid,
          dataToSend,
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              history.push(
                `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
              );
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.CAMPAIGN_SUMMARY}`
      );
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default WAcampaignAttributesSchema;
