import React from 'react';
import { get } from 'lodash';
import { Icon } from 'semantic-ui-react';
import constants from 'constants/config';

import { FormCheckBoxGroupInput } from '../../components/molecules/FormCheckBoxGroupInput';

export const WAScheduleTests = ({
  register,
  errors,
  setValue,
  showWAScheduleTests,
  isCrossBrand,
  name,
  id,
  label,
  hasURL
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const getOptions = () => {
    if (isCrossBrand) {
      return [
        {
          id: 'usability',
          label:
            'I have tested WhatsApp for accessibility, rendering and deliverability and confirm there are no issues',
          value: 'usability'
        },
        {
          id: 'setupGuidelines',
          label: () => (
            <label htmlFor="setupGuidelines" className="form-check-label">
              I have reviewed the setup&nbsp;
              <a
                href="/campaign-wizard/communications/setup-guidelines"
                target="_blank"
              >
                <span style={{ textDecoration: 'underline' }}>guidelines</span>
                &nbsp;
                <Icon name="external" />
              </a>
              and performed all checks accordingly
            </label>
          ),
          value: 'setupGuidelines',
          richlabel: true
        },
        {
          id: 'crossbrand',
          label:
            'I have reviewed that this cross brand WhatsApp has Unilever branding and not brand specific branding in header and footer. I have also checked that this email will be sent from a Unilever email address and not brand email address',
          value: 'crossbrand'
        },
        {
          id: 'compliance',
          label:
            'I have performed all tests as necessary to ensure WhatsApp is compliant with Unilever standards',
          value: 'compliance'
        }
      ];
    } else {
      return [
        {
          id: 'usability',
          label:
            'I have tested WhatsApp for accessibility, rendering and deliverability and confirm there are no issues',
          value: 'usability'
        },
        {
          id: 'setupGuidelines',
          label: () => (
            <label htmlFor="setupGuidelines" className="form-check-label">
              I have reviewed the setup&nbsp;
              <a
                href={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.WA_GUIDELINES_PAGE}`}
                target="_blank" rel="noreferrer"
              >
                <span style={{ textDecoration: 'underline' }}>guidelines</span>
                &nbsp;
                <Icon name="external" />
              </a>
              and performed all checks accordingly
            </label>
          ),
          value: 'setupGuidelines',
          richlabel: true
        },
        {
          id: 'compliance',
          label:
            'I have performed all tests as necessary to ensure WhatsApp is compliant with Unilever standards',
          value: 'compliance'
        }
      ];
    }
  };

  const options = getOptions();
  if (hasURL) {
    options.push({
      id: 'bodyURLShortened',
      label:
        'I have converted any long URLs in the WhatsApp body with short Bitly URLs',
      value: 'bodyURLShortened'
    });
  }

  if (!showWAScheduleTests) {
    return null;
  }

  return (
    <div className="email-summary-font">
      <FormCheckBoxGroupInput
        id={id}
        type={'checkboxgroup'}
        name={name}
        label={label}
        register={register}
        errors={errors}
        defaultValue={null}
        key={id}
        options={options}
        setValue={setValue}
      />
    </div>
  );
};
