import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormatedDate } from '../../../selectors';
import constant from '../../../constants';
import TwoValueColumn from 'components/atoms/TwoValueColumn/TwoValueColumn';
import Column from 'components/atoms/Column/Column';
import EmailStatus from 'components/atoms/EmailStatus/EmailStatus';
import EmailBriefingPreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import { shouldRedirectToExecution } from '../../../selectors';
import welcomeEmailImg from '../../../images/welcome.png';
import newsLetterEmailImg from '../../../images/newsletter.png';
import coreInfoEmailImg from '../../../images/core-info.png';
import birthdayEmailImg from '../../../images/birthday-icon.svg';
import otherEmailImg from '../../../images/other.png';
import previewImg from '../../../images/dashboard-list-preview.svg';
import { useHistory } from 'react-router';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
const icons = {
  welcomeEmail: welcomeEmailImg,
  newsletter: newsLetterEmailImg,
  otherMarketingEmails: otherEmailImg,
  birthdayEmail: birthdayEmailImg,
  coreinfo: coreInfoEmailImg
};

const EmailBriefingListRow = ({ campaign, index, role }) => {
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );
  const { authorizedUserData } = useSelector(state => state.authorized);

  campaign.brand = campaign.brand || {};
  campaign.country = campaign.country || {};
  const {
    summary = {},
    emailType,
    uid,
    type,
    updatedAt,
    status,
    isCrossBrand,
    brand,
    country: { name: countryname },
    requestersEmail,
    emailTemplateEditId,
    deliverySchedule,
    abTesting,
    winningVariant,
    requestorsName
  } = campaign;
  const { campaignName: name } = summary;
  const history = useHistory();

  const lastUpdateDate = getFormatedDate(updatedAt, false, false).split('-')[0];
  const brandName = Array.isArray(brand) ? brand.map(b => b.name) : brand.name;

  const getEmailBriefingLink = () => {
    const role = get(authorizedUserData, 'group', 'requestor');

    if (
      constant.EMAIL_APPROVAL_PAGE_ACCESS_ROLES.includes(role) &&
      status === constant.EMAIL_STATUS.AWAITING_FOR_APPROVAL
    ) {
      return `/campaign-wizard/emailbriefing/${uid}/approval`;
    } else if (
      constant.EMAIL_EXECUTION_PAGE_ACCESS_ROLES.includes(role) &&
      shouldRedirectToExecution(campaign)
    ) {
      return `/campaign-wizard/emailbriefing/${uid}/execution`;
    } else {
      return `/campaign-wizard/emailbriefing/${uid}`;
    }
  };

  const onSelectClick = e => {
    e.preventDefault();
    history.push(getEmailBriefingLink());
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  return (
    <>
      <tr key={index}>
        <TwoValueColumn
          icon={{
            src:
              icons[emailType] ||
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
            alt: `${emailType} icon`
          }}
          value1={constant.emailType[emailType]}
          value2={
            type && type === 'Sign-up with Questions & Answers'
              ? 'Sign up with Q&A'
              : type && type === 'Simple sign-up'
              ? 'Simple sign up'
              : ''
          }
        />
        <td className="max-width-column center">
          <div className="d-flex flex-column">
            <a
              href={getEmailBriefingLink()}
              title={name}
              className="one-line dark-text"
              onClick={e => {
                onSelectClick(e);
              }}
            >
              {name || 'Untitled'}
            </a>
          </div>
        </td>
        <TwoValueColumn
          value1={isCrossBrand ? 'Cross brand' : brandName}
          value2={countryname}
        />
        <Column value={lastUpdateDate} />
        <td className="center">
          <EmailStatus status={status} />
        </td>
        <td className="center">
          <ReactTooltip
            id="viewBtnToolTip"
            place="top"
            type="info"
            multiline={true}
            className=""
          />
          <span
            className="cw-icon cw-icon--help"
            data-tip={`Preview`}
            currentitem="false"
            data-for="viewBtnToolTip"
          >
            <button
              className="inline-button template-btn"
              onClick={() => {
                setIsTemplatePreviewOpen(true);
                setSelectedTemplateForPreview(emailTemplateEditId);
              }}
            >
              <img src={previewImg} alt="preview" className="preview-icon" />
            </button>
          </span>
        </td>
      </tr>

      <EmailBriefingPreviewOverlay
        isOpen={isTemplatePreviewOpen}
        onPreviewSelect={() => {
          history.push(getEmailBriefingLink());
        }}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        primaryButtonTitle="View"
        previewOf="campaign"
        campaignName={name || 'Untitled'}
        requestersEmail={requestersEmail}
        category={constant.emailType[emailType]}
        brand={brandName}
        status={status}
        country={countryname}
        campaignUpdatedAt={updatedAt}
        isCrossBrand={isCrossBrand}
        deliverySchedule={deliverySchedule}
        abTesting={abTesting}
        winningVariant={winningVariant}
        requestorsName={requestorsName}
      />
    </>
  );
};

export default EmailBriefingListRow;
