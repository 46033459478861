import React from 'react';

import BrandFilter from 'components/organisms/Filters/BrandFilter/BrandFilter';
import CountryFilter from 'components/organisms/Filters/CountryFilter/CountryFilter';
import StatusFilter from 'components/organisms/Filters/StatusFilter/StatusFilter';
import LastUpdatedFilter from 'components/organisms/Filters/LastUpdatedFilter/LastUpdatedFilter';
import CheckboxDropDown from 'components/CheckboxDropDown';
import { lastUpdatedData } from 'components/DashboardData';

import constants from 'constants/config';

export const WAFiltersInputs = ({
  brands,
  countries,
  filters,
  isLoading,
  handleDropDownValue
}) => {
  return (
    <>
      <div className="row mt-15 cw-dashboard__filters">
        <div className="d-flex align-items-center col-sm-12">
          {/* Country dropdown */}
          <CountryFilter
            asyncInProgress={isLoading}
            name="country"
            value={filters.country}
            onChange={handleDropDownValue}
            options={countries}
            classNamePrefix="react-multi-select"
            isEmailCampaign={true}
          />

          {/* Brand Filter */}
          <BrandFilter
            asyncInProgress={isLoading}
            name="brand"
            value={filters.brand}
            onChange={handleDropDownValue}
            options={brands}
            classNamePrefix="react-multi-select"
            isEmailCampaign={true}
          />

          {/* Type Filter */}
          <div className="filter-item mr-10">
            <CheckboxDropDown
              isMulti={true}
              name={'waType'}
              label={'WhatsApp Type'}
              placeholder={'WhatsApp Type'}
              className="react-multi-select-container"
              classNamePrefix="react-multi-select"
              options={constants.WA_TYPE_OPTIONS}
              value={filters.waType}
              onChange={handleDropDownValue}
              isDisabled={isLoading}
              isLoading={isLoading}
              isClearable={false}
              isEmailCampaign={true}
              hideSelectedOptions={false}
            />
          </div>

          {/* Status Dropdown */}
          <StatusFilter
            asyncInProgress={isLoading}
            value={filters.status}
            onChange={handleDropDownValue}
            options={constants.WA_STATUS_OPTIONS}
            classNamePrefix="react-multi-select"
            isEmailCampaign={true}
          />

          {/* Last Updated Dropdown */}
          <LastUpdatedFilter
            isLoading={isLoading}
            name="lastUpdatedDate"
            value={filters.lastUpdatedDate}
            onChange={handleDropDownValue}
            options={lastUpdatedData}
            classNamePrefix="react-multi-select"
            isEmailCampaign={true}
          />
        </div>
      </div>

      <div className="row mt-15 cw-dashboard__filters">
        <div className="d-flex align-items-center col-sm-12">
          {/* A/B Testing Filter */}
          <div className="filter-item mr-10">
            <CheckboxDropDown
              isMulti={true}
              name={'abTesting'}
              label={'WhatsApp with A/B tests'}
              placeholder={'WhatsApp with A/B tests'}
              className={'react-multi-select-container'}
              classNamePrefix="react-multi-select"
              options={constants.WA_ABTEST_FILTER_OPTIONS}
              value={filters.abTesting}
              onChange={handleDropDownValue}
              isDisabled={isLoading}
              isLoading={isLoading}
              isClearable={false}
              isEmailCampaign={true}
              hideSelectedOptions={false}
            />
          </div>

          {/* Incentives Filter */}
          <div className="filter-item mr-10">
            <CheckboxDropDown
              isMulti={true}
              name={'waWithIncentives'}
              label={'WhatsApp with incentives'}
              placeholder={'WhatsApp with incentives'}
              className="react-multi-select-container"
              classNamePrefix="react-multi-select"
              options={constants.EMAIL_COUPON_FILTER_OPTIONS}
              value={filters.waWithIncentives}
              onChange={handleDropDownValue}
              isDisabled={isLoading}
              isLoading={isLoading}
              isClearable={false}
              isEmailCampaign={true}
              hideSelectedOptions={false}
            />
          </div>

          {/* Primary theme Filter */}
          <div className="filter-item mr-10">
            <CheckboxDropDown
              isMulti={true}
              name={'waContentThemes'}
              label={'Primary content theme'}
              placeholder={'Primary content theme'}
              className="react-multi-select-container"
              classNamePrefix="react-multi-select"
              options={constants.EMAIL_PRIMARY_THEME}
              value={filters.waContentThemes}
              onChange={handleDropDownValue}
              isDisabled={isLoading}
              isLoading={isLoading}
              isClearable={false}
              isEmailCampaign={true}
              hideSelectedOptions={false}
            />
          </div>

          {/* Secondary theme Filter */}
          <div className="filter-item">
            <CheckboxDropDown
              isMulti={true}
              name={'waContentSecondaryThemes'}
              label={'Secondary content theme'}
              placeholder={'Secondary content theme'}
              className="react-multi-select-container"
              classNamePrefix="react-multi-select"
              options={constants.EMAIL_SECONDARY_THEMES}
              value={filters.waContentSecondaryThemes}
              onChange={handleDropDownValue}
              isDisabled={isLoading}
              isLoading={isLoading}
              isClearable={false}
              isEmailCampaign={true}
              hideSelectedOptions={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
