import * as yup from 'yup';
import constants from 'constants/config';
import {
  getYupSchema,
  transformSMSPayloadBySchedule,
  SMSSubmitRedirectURL
} from 'selectors';
import moment from 'moment';
import {
  DisplaySmsTriggerInput,
  SmsTriggerInput
} from 'components/molecules/SmsTriggerInput';
import { submitSmsCampaignDetailsV2 } from 'actions/communications/communicationCampaignDetails';
import { SendFollowUpEmailInput } from 'components/molecules/SendFollowUpEmailInput';

export const dateTimeSchema = isABTestOnSendTime => {
  if (isABTestOnSendTime) {
    return {
      smsDeliveryDateVariantA: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter SMS delivery date for variant A'),
      smsDeliveryDateVariantB: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter SMS delivery date for variant B'),
      smsDeliveryTimeVariantA: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time for variant A'),
      smsDeliveryTimeVariantB: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time for variant B')
    };
  } else {
    return {
      smsDeliveryDate: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter SMS delivery date'),
      smsDeliveryTime: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time')
    };
  }
};

const smsScheduleSchema = (
  dispatch,
  {
    defaultValues = {},
    match,
    history,
    emailDesign,
    smsType,
    abTesting,
    campaignDetails
  }
) => {
  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    if (data?.smsTrigger?.details?.triggerCondition?.value !== 'other') {
      delete data.smsTrigger.details.otherTriggerDetails;
    }
    const paramUid = match.params.id;
    const dataToSend = {
      uid: paramUid,
      userEmail: user.emailId,
      userName: user.name,
      deliverySchedule: data
    };

    const transformedData = transformSMSPayloadBySchedule(dataToSend);
    dispatch(
      submitSmsCampaignDetailsV2(
        { ...transformedData, uid: paramUid },
        (error, result) => {
          if (error) {
            console.log('OnSubmit error: ', error);
          } else {
            history.push(
              SMSSubmitRedirectURL({
                backToReview: isEdited && backToReview,
                status: campaignDetails.status,
                uid: paramUid,
                nextPage: `${constants.SMS_ROUTES.AUDIENCE}`
              })
            );
          }
        }
      )
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'smsTrigger',
        component: SmsTriggerInput,
        displayComponent: DisplaySmsTriggerInput,
        id: 'smsTrigger',
        label: 'SMS trigger',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: defaultValues.smsTrigger,
        smsType: smsType,
        abTesting,
        rules: yup
          .object({
            type: yup
              .string()
              .required('Please select SMS trigger')
              .nullable(),
            details: yup
              .object()
              .when('type', {
                is: 'fixedDateAndtime',
                then: yup.object(dateTimeSchema(isABTestOnSendTime))
              })
              .when('type', {
                is: 'dynamicTrigger',
                then: yup.object({
                  triggerCondition: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .required('Please select trigger condition')
                    .default(undefined),
                  triggerConditionStartDate: yup
                    .string()
                    .test(
                      'isDateStringParsable',
                      'Please enter a valid date',
                      dateString => {
                        return (
                          moment(dateString, 'DD/MM/YYYY', true).isValid() ||
                          moment(
                            dateString,
                            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                            true
                          ).isValid()
                        );
                      }
                    )
                    .test(
                      'isNotPastDate',
                      'Please select a future date',
                      dateString => {
                        return moment(dateString, [
                          'DD/MM/YYYY',
                          moment.ISO_8601,
                          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                        ]).isSameOrAfter(moment(), 'day');
                      }
                    )
                    .required('Please enter start date'),
                  triggerConditionEndDate: yup
                    .string()
                    .when(['triggerConditionAlwaysOn', 'triggerCondition'], {
                      is: (triggerConditionAlwaysOn, triggerCondition) => {
                        return triggerCondition && triggerConditionAlwaysOn
                          ? false
                          : true;
                      },
                      then: schema =>
                        schema
                          .test(
                            'isDateStringParsable',
                            'Please enter a valid date',
                            dateString => {
                              return (
                                moment(
                                  dateString,
                                  'DD/MM/YYYY',
                                  true
                                ).isValid() ||
                                moment(
                                  dateString,
                                  'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                                  true
                                ).isValid()
                              );
                            }
                          )
                          .test(
                            'isNotPastDate',
                            'Please select a future date',
                            dateString => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(moment(), 'day');
                            }
                          )
                          .test(
                            'isNotGreaterThenStartDate',
                            'End date can not be before Start date',
                            (dateString, context) => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(
                                moment(
                                  context.parent.triggerConditionStartDate,
                                  [
                                    'DD/MM/YYYY',
                                    moment.ISO_8601,
                                    'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                                  ]
                                ),
                                'day'
                              );
                            }
                          )
                          .required('Please enter end date'),
                      otherwise: schema => schema.notRequired()
                    }),
                  otherTriggerDetails: yup
                    .string()
                    .trim()
                    .nullable()
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition && triggerCondition.value === 'other',
                      then: yup
                        .string()
                        .required('Please specify trigger condition'),
                      otherwise: yup.string().nullable()
                    }),
                  triggerConditionBirthdayDate: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .default(undefined)
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition &&
                        triggerCondition.value === 'fixedDateInBirthdayMonth',
                      then: yup.object().required('Please select date'),
                      otherwise: yup.object().nullable()
                    })
                })
              })
          })
          .required('Please select email trigger')
          .default({ type: undefined, details: undefined })
      },
      // {
      //   type: 'custom',
      //   name: 'sendFollowUpSms',
      //   mainSmsLabel:'Do you want to send a follow up SMS?',
      //   subSmsLabel:'We will setup your campaign to automatically trigger a follow up SMS to consumers who do not open your SMS in the 1st attempt. The SMS body content of the follow up will remain the same as the original SMS. To encourage non-openers to engage, you will be required to enter a slightly different subject line for your follow up in the later sections of this briefing.',
      //   sendAfterFieldLabel:'How many days after the 1st send should we send your follow up SMS to non-openers?',
      //   component: SendFollowUpEmailInput,
      //   id: 'sendFollowUpSms',
      //   label: 'Send follow up SMS',
      //   dateFormat: constants.FORMAT_DATE_DD_MM_YY,
      //   timeFormat: true,
      //   defaultValue: defaultValues
      // },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: defaultValues.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(300, 'Max 300 characters are allowed'),
        maxLength: '300'
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),

    onDraft: (data, validationSchema, { user }) => {
      if (
        data?.smsTrigger?.details?.triggerCondition?.value !== 'other' &&
        data?.smsTrigger?.details?.otherTriggerDetails
      ) {
        delete data.smsTrigger.details.otherTriggerDetails;
      }
      const paramUid = match.params.id;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        deliverySchedule: data
      };

      const transformedData = transformSMSPayloadBySchedule(dataToSend);
      dispatch(
        submitSmsCampaignDetailsV2(
          { ...transformedData, uid: paramUid },
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              history.push(
                `/campaign-wizard/${constants.SMS_ROUTES.DASHBOARD}`
              );
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.CAMPAIN_ATTRIBUTES}`
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default smsScheduleSchema;
