import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import HistoryComponent from 'components/history';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import NotFound from 'components/NotFound';
import WASummaryCards from 'components/organisms/EmailSummaryCards/WASummaryCards';
import WAOverviewTables from 'components/organisms/WAOverviewTables';
import Toolbar from 'components/communications/Toolbar';
import Loader from 'components/Loader';
import Layout from 'components/organisms/Layout/Layout';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';

import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import EmailGuidelineText from 'components/organisms/EmailGuidelineText/EmailApproverGuideline';
import EmailGuideline from 'components/EmailGuideline';
import EmailRejectModel from 'components/organisms/EmailRejectModel';
import MobileDeviceFrame from 'components/organisms/MobileDeviceFrame';

import { getWAcommsByUid, updateWAComms } from 'actions/wabriefings/waComms';
import { fetchHistoryLogs } from 'actions/history';
import { openFeedbackForm } from 'actions/feedbackForm';
import { get } from 'lodash';

import { getSMSReviewData } from 'selectors';
import constants from 'constants/config';

import print_icon from 'images/print_icon.png';
import feedback_icon from 'images/feedback_icon.png';

const WACampaignApproval = props => {
  const pathUid = props.match.params.id;
  const dispatch = useDispatch();

  const [notFound, setNotFound] = useState(false);
  const [
    isApprovalGuidelinesModalOpen,
    setIsApprovalGuidelinesModalOpen
  ] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [mobilePreviewData, setMobilePreviewData] = useState();

  const { waCommsData, isLoading } = useSelector(state => state.waBriefings);
  const historyLogs = useSelector(state => state.historyLogs);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const previewRef = useRef();

  useEffect(() => {
    if (pathUid) {
      dispatch(
        getWAcommsByUid(pathUid, (err, _) => {
          if (err) {
            setNotFound(true);
          }
        })
      );
      dispatch(fetchHistoryLogs(pathUid));
    }
  }, []);

  useEffect(() => {
    if (waCommsData.waDesign) {
      const yoData = {
        ...get(waCommsData, 'waDesign', {}),
        brand: get(waCommsData, 'brand'),
        deliverySchedule: get(waCommsData, 'deliverySchedule', {})
      };
      setMobilePreviewData(yoData);
    }
  }, [waCommsData]);

  const { name, status } = waCommsData || {};

  const continueCampaign = () => {
    props.history.push(
      `${constants.WA_BASE_ROUTE}/${pathUid}/${constants.WA_ROUTES.BRAND_DETAILS}`
    );
  };

  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: pathUid,
        userId: authorizedUserData._id,
        defaultCampaignRequest: 'WA',
        showCampaignRequest: false
      })
    );
  };

  const handleOnSubmit = rejectedComment => {
    const dataToSend = {
      uid: pathUid,
      status: '5', // Rejected
      userEmail: authorizedUserData.emailId,
      userName: authorizedUserData.name,
      rejectComments: rejectedComment,
      role: authorizedUserData.group
    };

    dispatch(
      updateWAComms(pathUid, dataToSend, (err, res) => {
        if (res) {
          props.history.push(
            `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
          );
        }
      })
    );
  };

  const handleApprovalClick = () => {
    const dataToSend = {
      uid: pathUid,
      status: constants.WA_CAMPAIGN_STATUS.AWAITING_META_APPROVAL,
      userEmail: authorizedUserData.emailId,
      userName: authorizedUserData.name
    };

    dispatch(
      updateWAComms(pathUid, dataToSend, (err, res) => {
        if (res) {
          props.history.push(`${constants.WA_BASE_ROUTE}/${pathUid}`);
        }
      })
    );
  };

  let isDraft = status === constants.WA_CAMPAIGN_STATUS.DRAFT;

  // let mobilePreviewData = getSMSReviewData(this.props.smsDetailsOverview);

  if (notFound) {
    return <NotFound />;
  } else if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="skypink ml-5 mr-5">
        <div className="cw-section cw-overview">
          <div className="draft_button_section_main">
            <PageHeader
              heading={name || 'Untitled'}
              status={status}
              subHeading={isDraft ? 'Continue creating your campaign' : ''}
              review
            />

            <div className="toolbar-scroll-fix cw-print-hide">
              {!isDraft && (
                <Toolbar>
                  <Toolbar.Item
                    icon={print_icon}
                    name="Print"
                    onClick={() => window.print()}
                  />
                  <Toolbar.Item
                    icon={feedback_icon}
                    name="Feedback"
                    onClick={() => handleOnOpenFeedback()}
                  />
                </Toolbar>
              )}
            </div>

            <div className="mb-10">
              <EmailApproverInfo
                emailBriefing={waCommsData || {}}
                userData={authorizedUserData}
              />
            </div>

            <div className="mb-50">
              <EmailGuidelineText
                isOpenGuidelineModel={() =>
                  setIsApprovalGuidelinesModalOpen(true)
                }
                titleText={`Please review and approve the WhatsApp campaign using these `}
                guideLineName={`approval guidelines`}
              />
            </div>
          </div>

          <Layout
            className="mt-30"
            sidebar={
              <MobileDeviceFrame
                ref={previewRef}
                data={mobilePreviewData}
                className="mobile-device-overview"
              />
            }
          >
            <WASummaryCards waCampaign={waCommsData} />

            <WAOverviewTables editable={false} waCampaign={waCommsData} />

            <div className="mb-20">
              <BackAndNext
                backTitle="Reject"
                onClickBack={() => setIsRejectionModalOpen(true)}
                onClickNext={handleApprovalClick}
                nextTitle="Approve"
              />
            </div>

            <div className="overview-page-history mt-20">
              {historyLogs && historyLogs.length ? (
                <HistoryComponent
                  historyLogs={historyLogs}
                  isEmailBrief={true}
                />
              ) : null}
            </div>
          </Layout>
        </div>

        <EmailRejectModel
          modalIsOpen={isRejectionModalOpen}
          closeModal={() => setIsRejectionModalOpen(false)}
          handleOnSubmit={handleOnSubmit}
        />
        <Modal
          scrollable={true}
          isOpen={isApprovalGuidelinesModalOpen}
          onRequestClose={() => setIsApprovalGuidelinesModalOpen(false)}
          className="cw-modal cw-modal--qnadetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <EmailGuideline
            closeIsEditModal={() => setIsApprovalGuidelinesModalOpen(false)}
            data={constants.WA_APPROVER_GUIDE_LINES}
            isEmailCampaign={true}
          />
        </Modal>
      </div>
    );
  }
};

export default WACampaignApproval;
