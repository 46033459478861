import React, { useState, forwardRef, useEffect } from 'react';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';
import { FormReactSelect } from '../FormReactSelect';
import { CustomFormFieldArrayV2 } from '../FormFieldArray/custom-v2';
import campaignidsImg from '../../../images/campaignIds.png';
import standerdProfileImg from '../../../images/standerdProfile.png';
import questionAndAnswersImg from '../../../images/questionAndAnswers.png';
import pastEmailEngagementImg from '../../../images/pastEmailEngagement.png';
import classNames from 'classnames';
import MultiRangeSlider from '../RangeSlider';
import CheckboxDropDown from 'components/CheckboxDropDown';
import { SMSCustomCriteria } from './SMSCustomCriteria';
import { WACustomCriteria } from './WACustomCriteria';
import ExternalLinkIcon from '../../../images/external-link.png';

export const emailCustomCriteriaLabel = campaignConfig => {
  return {
    campaignIds:
      campaignConfig && campaignConfig.LABEL_SIGN_UP_ID
        ? `${campaignConfig.LABEL_SIGN_UP_ID}s`
        : 'Campaign IDs',
    standardProfile: 'Standard profile',
    questionAndAnswers: 'Questions & answers',
    pastEmailEngagement: 'Past email engagement'
  };
};

export const SMSCustomCriteriaLabel = campaignConfig => {
  return {
    campaignIds:
      campaignConfig && campaignConfig.LABEL_SIGN_UP_ID
        ? `${campaignConfig.LABEL_SIGN_UP_ID}s`
        : 'Campaign IDs',
    standardProfile: 'Standard profile',
    questionAndAnswers: 'Questions & answers',
    pastSMSEngagement: 'Past SMS engagement'
  };
};

export const WACustomCriteriaLabel = campaignConfig => {
  return {
    campaignIds:
      campaignConfig && campaignConfig.LABEL_SIGN_UP_ID
        ? `${campaignConfig.LABEL_SIGN_UP_ID}s`
        : 'Campaign IDs',
    standardProfile: 'Standard profile',
    questionAndAnswers: 'Questions & answers',
    pastWAEngagement: 'Past WhatsApp engagement'
  };
};

const EMAIL_CUSTOM_CRITERIA_OPTIONS = campaignConfig => {
  return [
    {
      label: `${campaignConfig.LABEL_SIGN_UP_ID}s`,
      value: 'campaignIds',
      id: 'campaignIds',
      iconImg: campaignidsImg
    },
    {
      label: emailCustomCriteriaLabel().standardProfile,
      value: 'standardProfile',
      id: 'standardProfile',
      iconImg: standerdProfileImg
    },
    {
      label: emailCustomCriteriaLabel().questionAndAnswers,
      value: 'questionAndAnswers',
      id: 'questionAndAnswers',
      iconImg: questionAndAnswersImg
    },
    {
      label: emailCustomCriteriaLabel().pastEmailEngagement,
      value: 'pastEmailEngagement',
      id: 'pastEmailEngagement',
      iconImg: pastEmailEngagementImg
    }
  ];
};

const SMS_CUSTOM_CRITERIA_OPTIONS = campaignConfig => {
  return [
    {
      label: `${campaignConfig.LABEL_SIGN_UP_ID}s`,
      value: 'campaignIds',
      id: 'campaignIds',
      iconImg: campaignidsImg
    },
    {
      label: SMSCustomCriteriaLabel().standardProfile,
      value: 'standardProfile',
      id: 'standardProfile',
      iconImg: standerdProfileImg
    },
    {
      label: SMSCustomCriteriaLabel().questionAndAnswers,
      value: 'questionAndAnswers',
      id: 'questionAndAnswers',
      iconImg: questionAndAnswersImg
    },
    {
      label: SMSCustomCriteriaLabel().pastSMSEngagement,
      value: 'pastSMSEngagement',
      id: 'pastSMSEngagement',
      iconImg: pastEmailEngagementImg
    }
  ];
};

const WA_CUSTOM_CRITERIA_OPTIONS = campaignConfig => {
  return [
    {
      label: `${campaignConfig.LABEL_SIGN_UP_ID}s`,
      value: 'campaignIds',
      id: 'campaignIds',
      iconImg: campaignidsImg
    },
    {
      label: WACustomCriteriaLabel().standardProfile,
      value: 'standardProfile',
      id: 'standardProfile',
      iconImg: standerdProfileImg
    },
    {
      label: WACustomCriteriaLabel().questionAndAnswers,
      value: 'questionAndAnswers',
      id: 'questionAndAnswers',
      iconImg: questionAndAnswersImg
    },
    {
      label: WACustomCriteriaLabel().pastWAEngagement,
      value: 'pastWAEngagement',
      id: 'pastWAEngagement',
      iconImg: pastEmailEngagementImg
    }
  ];
};

export const createLastOptions = () => {
  const opt = [];
  for (let i = 1; i <= 12; i++) {
    opt.push({ label: i.toString(), value: i.toString() });
  }
  return opt;
};

const Childfield = ({
  name,
  control,
  watchInput,
  errors,
  register,
  defaultValue,
  rules,
  trigger,
  setValue,
  openCustomAudienceModal,
  campaignConfig
}) => {
  const defaultType = get(defaultValue, 'type');
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });

  if (get(triggerType, 'value') === 'customCriteria') {
    return (
      // <div className="ml-33 pt-0 cw-form-nested">
      //   {/* <FormTextArea
      //     name={`${name}.details.customCriteriaDetails`}
      //     register={register}
      //     label="Define custom criteria"
      //     errors={errors}
      //     defaultValue={get(defaultValue, 'details.customCriteriaDetails')}
      //     maxLength="100"
      //   /> */}
      // </div>
      <>
        {name === 'sendSMSTo' ? (
          <SMSCustomCriteria
            control={control}
            errors={errors}
            defaultValue={defaultValue}
            rules={rules}
            trigger={trigger}
            setValue={setValue}
            fieldName={name}
            register={register}
            openCustomAudienceModal={openCustomAudienceModal}
            campaignConfig={campaignConfig}
          />
        ) : name === 'sendWATo' ? (
          <WACustomCriteria
            control={control}
            errors={errors}
            defaultValue={defaultValue}
            rules={rules}
            trigger={trigger}
            setValue={setValue}
            fieldName={name}
            register={register}
            openCustomAudienceModal={openCustomAudienceModal}
            campaignConfig={campaignConfig}
          />
        ) : (
          <SpecifyCustomCriteria
            control={control}
            errors={errors}
            defaultValue={defaultValue}
            rules={rules}
            trigger={trigger}
            setValue={setValue}
            fieldName={name}
            register={register}
            openCustomAudienceModal={openCustomAudienceModal}
            campaignConfig={campaignConfig}
          />
        )}
      </>
    );
  }

  if (get(triggerType, 'value') === 'consumersLinkedToCampaignIds') {
    return (
      <div className="ml-33 pt-0 cw-form-nested">
        <Controller
          name={`${name}.details.campaignIds`}
          defaultValue={get(defaultValue, 'details.campaignIds', [
            { value: '' }
          ])}
          control={control}
          render={({ field }) => (
            <CustomFormFieldArrayV2
              {...field}
              label={`${campaignConfig.LABEL_SIGN_UP_ID}(s)`}
              errorLabel={`${campaignConfig.LABEL_SIGN_UP_ID}`}
              toolTip={`Campaign IDs are now known as ${campaignConfig.LABEL_SIGN_UP_ID}s to better explain <br /> the purpose of the field to uniquely identify the initiative <br /> through which a consumer signed up`}
              type="text"
              errors={errors}
              defaultValue={get(defaultValue, 'details.campaignIds')}
              rules={rules}
              trigger={trigger}
            />
          )}
        />
      </div>
    );
  }

  return null;
};

export const SendEmailToInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  campaignConfig,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div>
      <FormReactSelect
        id={props.id}
        name={`${name}.type`}
        label={label}
        placeholder={props.placeholder}
        control={control}
        errors={errors}
        defaultValue={get(defaultValue, 'type')}
        options={props.options}
        setValue={setValue}
        extraOptions={props.extraOptions}
        showExtraOptions={props.showExtraOptions}
      />
      <Childfield
        control={control}
        watchInput={`${name}.type`}
        errors={errors}
        register={register}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        trigger={props.trigger}
        setValue={setValue}
        campaignConfig={campaignConfig}
        openCustomAudienceModal={props.openCustomAudienceModal}
      />
    </div>
  );
};

export const DisplaySendEmailToInput = data => {
  if (data && data.type) {
    if (get(data, 'type.value') === 'customCriteria') {
      return [
        {
          label: 'Send email to',
          value: 'Custom criteria'
        },
        {
          label: 'Custom criteria details',
          value: get(data, 'details.customCriteriaDetails')
        }
      ];
    }
    if (get(data, 'type.value') === 'consumersLinkedToCampaignIds') {
      const list = get(data, 'details.campaignIds');
      const text = list.map(item => item.value).join(', ');
      return [
        {
          label: 'Send email to',
          value: 'Consumers linked to campaign ID(s)'
        },
        {
          label: 'Campaign ID(s)',
          value: text
        }
      ];
    }
    if (get(data, 'type.value') === 'allConsentedContactableConsumers') {
      return [
        {
          label: 'Send email to',
          value: 'All consented contactable consumers'
        }
      ];
    }
  }
  return [
    {
      label: 'Send email to',
      value: ''
    }
  ];
};

const SpecifyCustomCriteria = ({
  control,
  rules,
  trigger,
  defaultValue,
  errors,
  setValue,
  fieldName,
  register,
  openCustomAudienceModal,
  campaignConfig
}) => {
  //const [selected, setSelected] = useState([]);
  const customCriteriaList = useWatch({
    control,
    name: `${fieldName}.details.customCriteriaList`,
    defaultValue: get(defaultValue, 'details.customCriteriaList', [])
  });
  const pastEmailCriteria = useWatch({
    control,
    name: `${fieldName}.details.pastEmailEngagementCriteria`,
    defaultValue: get(defaultValue, 'details.pastEmailEngagementCriteria', null)
  });
  const lastField = useWatch({
    control,
    name: `${fieldName}.details.last`,
    defaultValue: get(defaultValue, 'details.last', {
      label: '6',
      value: '6'
    })
  });
  const unitField = useWatch({
    control,
    name: `${fieldName}.details.unit`,
    defaultValue: get(defaultValue, 'details.unit', {
      label: 'Months',
      value: 'months'
    })
  });

  useEffect(() => {
    if (defaultValue) {
      setValue(
        `${fieldName}.details.customCriteriaList`,
        get(defaultValue, 'details.customCriteriaList')
      );
    }
  }, []);

  const checkForQnA = fieldName => {
    if (customCriteriaList.includes('questionAndAnswers')) {
      return false;
    } else if (customCriteriaList.includes('pastEmailEngagement')) {
      if (fieldName !== 'pastEmailEngagement' && pastEmailCriteria) {
        if (unitField?.value === 'years') {
          return false;
        } else if (unitField?.value === 'months') {
          return parseInt(lastField?.value) > 6 ? false : true;
        } else {
          return true;
        }
      }
      return true;
    }
    return true;
  };

  return (
    <div className="ml-33 pt-0 cw-form-nested mb-20">
      <Controller
        name={`${fieldName}.details.customCriteriaList`}
        control={control}
        defaultValue={get(defaultValue, 'details.customCriteriaList', [])}
        render={({ field }) => (
          <CustomCheckboxInputGroup
            {...field}
            campaignConfig={campaignConfig}
          />
        )}
      />

      {(customCriteriaList.includes('questionAndAnswers') ||
        !checkForQnA()) && (
        <div className="mt-20">
          <div className="form-group">
            <label htmlFor="audienceFileName">Audience name from ACT</label>
            <input
              className="form-control"
              type="text"
              //name="audienceFileName"
              {...(register &&
                register(`${fieldName}.details.audienceFileName`))}
              id="audienceFileName"
              maxLength="100"
              defaultValue={get(defaultValue, 'details.audienceFileName', '')}
            />
          </div>
          <div
            className="alert alert-primary mt-15 cw-color--saasheading d-flex"
            style={{ padding: '10px 20px', borderRadius: '10px' }}
            role="alert"
          >
            <i className="fas fa-info-circle align-self-start pt-1 mr-2"></i>
            <div className="alert-qna--info">
              <p>
                If you want to use Q&As or historical 121 engagement data for
                audience segmentation, you need to request your audience
                specialists to create audience in 'Audience Creation Tool
                (ACT)'. Please refer to{' '}
                <a
                  href="https://unilever.sharepoint.com/:p:/r/sites/DMCHub/Shared%20Documents/10.%20Playbooks,%20Guides%20%26%20Demos/03.%20Audience%20Segmentation/ACT/Audience%20Creation%20Tool%20Cookbook%20v3.3.pptx?d=w8ddc53fece114e17b8199d9443e46b7a&csf=1&web=1&e=vOnRBp"
                  target={'_blank'} rel="noreferrer"
                >
                  guidance on using ACT
                  <div className="external-link-wrapper">
                    <img
                      src={ExternalLinkIcon}
                      alt="External Link"
                      className="external-link-icon"
                    />
                  </div>
                </a>{' '}
                or{' '}
                <a
                  href="https://unilever.sharepoint.com/:v:/r/sites/DMCHub/Shared%20Documents/10.%20Playbooks,%20Guides%20%26%20Demos/03.%20Audience%20Segmentation/ACT/ACT%20v3.3/Audience%20Creation%20Tool%20New%20Updates%20Training%20Slot%202-20240327_150349-Meeting%20Recording.mp4?csf=1&web=1&e=oLqamG&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D"
                  target={'_blank'} rel="noreferrer"
                >
                  watch an ACT training recording
                  <div className="external-link-wrapper">
                    <img
                      src={ExternalLinkIcon}
                      alt="External Link"
                      className="external-link-icon"
                    />
                  </div>
                </a>
                .
              </p>
            </div>
          </div>
          <div className="info-grey--small">
            <ul>
              <li>
                Once an audience is created in ACT, it will sent to Adobe
                Campaign Standard (ACS) via an SFTP connection
              </li>
              <li>
                You will also receive an audience name from ACT that you need to
                enter above
              </li>
              <li>
                At the time of setup, our ACS execution team will use the
                audience name in your campaign brief to search for the correct
                audience file in ACS and use it to build your email audience
              </li>
            </ul>
          </div>
        </div>
      )}

      {customCriteriaList.includes('campaignIds') && checkForQnA() && (
        <div className="mt-20">
          <Controller
            name={`${fieldName}.details.criteriaCampaignIds`}
            control={control}
            shouldUnregister={true}
            defaultValue={get(defaultValue, 'details.criteriaCampaignIds', [
              { value: '' }
            ])}
            render={({ field }) => (
              <CustomFormFieldArrayV2
                {...field}
                label={`${campaignConfig.LABEL_SIGN_UP_ID}(s)`}
                type="text"
                errorLabel={`${campaignConfig.LABEL_SIGN_UP_ID}`}
                toolTip={`Campaign IDs are now known as ${campaignConfig.LABEL_SIGN_UP_ID}s to better explain <br /> the purpose of the field to uniquely identify the initiative <br /> through which a consumer signed up`}
                errors={errors}
                rules={rules}
                trigger={trigger}
              />
            )}
          />
        </div>
      )}
      {customCriteriaList.includes('standardProfile') && checkForQnA() && (
        <div className="mt-20 w-75">
          {/* <input type="range" name="rangeslider" id="rangeslider" />{' '} */}
          <Controller
            name={`${fieldName}.details.ageRange`}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <MultiRangeSlider
                {...field}
                id="ageRange"
                label="Age"
                min={13}
                max={99}
                defaultValue={get(defaultValue, 'details.ageRange', [16, 99])}
              />
            )}
          />

          <div className="w-50">
            <Controller
              name={`${fieldName}.details.gender`}
              control={control}
              defaultValue={get(defaultValue, 'details.gender')}
              shouldUnregister={true}
              render={({ field }) => (
                <CheckboxDropDown
                  {...field}
                  isMulti={true}
                  className={'react-multi-select-container'}
                  classNamePrefix="react-multi-select"
                  isEmailCampaign={true}
                  label={'Gender'}
                  hideSelectedOptions={false}
                  placeholder={'Please select gender'}
                  options={[
                    {
                      label: 'Male',
                      value: 'male'
                    },
                    {
                      label: 'Female',
                      value: 'female'
                    },
                    {
                      label: 'Not Applicable',
                      value: 'Not Applicable'
                    },
                    {
                      label: 'Other',
                      value: 'other'
                    },
                    {
                      label: 'Unknown',
                      value: 'Unknown'
                    }
                  ]}
                  isClearable={false}
                />
              )}
            />
          </div>
        </div>
      )}

      {customCriteriaList.includes('pastEmailEngagement') &&
        checkForQnA('pastEmailEngagement') && (
          <div className="mt-20">
            <div className="row">
              <div className="col-md-6">
                <FormReactSelect
                  id={'pastEmailEngagementCriteria'}
                  name={`${fieldName}.details.pastEmailEngagementCriteria`}
                  label={'Past email engagement criteria'}
                  placeholder={'Please select criteria'}
                  control={control}
                  errors={errors}
                  defaultValue={get(
                    defaultValue,
                    'details.pastEmailEngagementCriteria'
                  )}
                  options={[
                    {
                      label: 'Email opened (Retarget engaged users)',
                      value: 'emailOpened'
                    },
                    {
                      label: 'Email not opened (Re-engage inactive users)',
                      value: 'emailNotOpened'
                    },
                    {
                      label: 'Email clicked (Retarget engaged users)',
                      value: 'emailClicked'
                    }
                  ]}
                  setValue={setValue}
                  shouldUnregister={true}
                />
              </div>
              {pastEmailCriteria?.value && (
                <>
                  <div className="col-md-2">
                    <FormReactSelect
                      id={'last'}
                      name={`${fieldName}.details.last`}
                      label={'Last'}
                      placeholder={'-'}
                      control={control}
                      errors={errors}
                      defaultValue={get(defaultValue, 'details.last')}
                      defaultSelected={{
                        label: '6',
                        value: '6'
                      }}
                      options={createLastOptions()}
                      setValue={setValue}
                      shouldUnregister={true}
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="temp-box-push"></div>
                    <FormReactSelect
                      id={'unit'}
                      name={`${fieldName}.details.unit`}
                      label={''}
                      placeholder={'-'}
                      control={control}
                      errors={errors}
                      defaultValue={get(defaultValue, 'details.unit')}
                      defaultSelected={{
                        label: 'Months',
                        value: 'months'
                      }}
                      options={[
                        {
                          label: 'Days',
                          value: 'days'
                        },
                        {
                          label: 'Months',
                          value: 'months'
                        },
                        {
                          label: 'Years',
                          value: 'years'
                        }
                      ]}
                      setValue={setValue}
                      shouldUnregister={true}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export const CustomCheckboxInputGroup = forwardRef(
  ({ onChange, onBlur, value, name, campaignConfig }, ref) => {
    const handleBoxSelect = event => {
      const val = event.target.name;
      if (value.includes(val)) {
        const newList = [...value].filter(i => i !== val);
        //setSelected(newList);
        onChange(newList);
      } else {
        const newList = [...value];
        newList.push(val);
        // setSelected(newList);
        onChange(newList);
      }
    };

    const inputName = name?.split('.')[0];
    const options =
      inputName === 'sendSMSTo'
        ? SMS_CUSTOM_CRITERIA_OPTIONS
        : inputName === 'sendWATo'
        ? WA_CUSTOM_CRITERIA_OPTIONS
        : EMAIL_CUSTOM_CRITERIA_OPTIONS;

    return (
      <div className="form-group email-secondary-theme">
        <label>Specify custom criteria</label>
        <div className="themes-container d-flex flex-wrap">
          {options(campaignConfig).map((item, index) => {
            return (
              <label
                htmlFor={item.id}
                className={classNames('theme-item d-flex align-items-center', {
                  selected: value.includes(item.value)
                })}
                key={item.value}
              >
                <div className="mb-0">
                  <input
                    type="checkbox"
                    id={item.id}
                    name={item.value}
                    onChange={handleBoxSelect}
                    checked={value.includes(item.value)}
                  />

                  <div className="label-text d-flex justify-content-center align-items-center">
                    <img
                      src={item.iconImg}
                      alt={item.label}
                      className="theme-icon"
                    />
                    <p className="theme-label">{item.label}</p>
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      </div>
    );
  }
);
