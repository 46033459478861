import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { regenerateCoreTemplateThumbnail } from 'services/emailCoreTemplates';
import { regenerateEmailBriefingThumbnail } from 'services/emailTemplateEdit';

export default function Card({
  thumbnail,
  template,
  uid,
  hideOnError,
  title,
  gradient,
  style: styleProps = {},
  imageStyle,
  noShadow,
  button = {}
}) {
  const dispatch = useDispatch();
  const { label, onClick } = button;
  const [isLoadingError, setIsLoadingError] = useState(false);
  const style = {
    ...styleProps,
    ...(noShadow ? { boxShadow: 'none', borderRadius: 0 } : {})
  };

  const onError = () => {
    if (template && template._id) {
      regenerateCoreTemplateThumbnail(template._id, template);
    } else if (uid) {
      regenerateEmailBriefingThumbnail(uid);
    }
    setIsLoadingError(true);
  };

  if (isLoadingError) {
    return (
      <GradientWithMesssage
        style={style}
        message="Unable to load image preview"
      />
    );
  }

  if (!thumbnail && gradient) {
    return <GradientWithMesssage style={style} gradient={gradient} />;
  }

  if (hideOnError && !thumbnail) {
    return null;
  }
  return (
    <div className="email-card" style={style}>
      {label ? (
        <button className="email-card-button btn btn-primary" onClick={onClick}>
          {label}
        </button>
      ) : null}
      <img
        onError={onError}
        className="email-card-image"
        src={thumbnail}
        alt={title}
        style={imageStyle}
      />
    </div>
  );
}

const GradientWithMesssage = ({
  style,
  gradient,
  message = 'Preview will be available once designed'
}) => {
  return (
    <div
      className="email-card--blank"
      style={{
        ...style,
        background: gradient
      }}
    >
      <p className="preview-not-availabe card-error mt-10">{message}</p>
    </div>
  );
};
