import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useWatch } from 'react-hook-form';
import { Input } from 'components/atoms/Input';
import { FieldError } from 'components/atoms/FieldError';
import { Title } from 'components/atoms/Title';
import { get } from 'lodash';

const WATemplateNameInput = ({
  name,
  label,
  errors,
  register,
  control,
  customComponent: CustomComponent,
  rules,
  ...props
}) => {
  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(props.defaultValue, `templateUse`)
  });

  const isTemplateReuse = templateUse === 'reuse';

  const defaultTemplateName = get(props.defaultValue, 'templateName', '');
  const defaultTemplateID = get(props.defaultValue, 'templateId', '');

  useEffect(() => {
    props.setValue(name, defaultTemplateName);
  }, [defaultTemplateName]);

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <>
      <div
        className={classNames('form-group', props.formGroupWrapperClass)}
        aria-live="polite"
      >
        <div className={classNames('d-flex', props.labelWrapperClass)}>
          <label htmlFor={props.id}>{label}</label>
          {CustomComponent ? <CustomComponent /> : ''}
        </div>
        <Input
          id={props.id}
          name={name}
          {...(register && register(name, rules))}
          hasError={hasError}
          defaultValue={defaultTemplateName}
          disabled={isTemplateReuse}
          maxLength={props.maxLength}
        />
        {props.subText ? (
          <Title className="email-type-desc-font mb-3" title={props.subText} />
        ) : null}
        <FieldError errors={errors} name={name} />

        {isTemplateReuse && (
          <p className="mt-2">Reusing template ID {defaultTemplateID}</p>
        )}
      </div>

      {/* <div
      className={classNames('form-group')}
      aria-live="off"
    >
      {isTemplateReuse && <p>Reusing template ID {defaultTemplateID}</p>}
    </div> */}
    </>
  );
};

export default WATemplateNameInput;
