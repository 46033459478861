import React from 'react';
import parse from 'html-react-parser';
import { formatURLandPhoneNumber } from 'utilities/util';

const WAChatBody = ({ data, isHTML }) => {
  return data ? (
    <div className="message-body">
      {isHTML ? parse(formatURLandPhoneNumber(data)) : data}
    </div>
  ) : null;
};

export default WAChatBody;
