import * as yup from 'yup';

import { SendEmailToInput } from 'components/molecules/SendEmailToInput';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';

import { updateWAComms } from 'actions/wabriefings/waComms';

import {
  getYupSchema,
  getWAAudienceOptions,
  transformWAPayloadByAudience,
  WACampaignSubmitRedirectURL
} from 'selectors';
import constants from 'constants/config';

const formSchema = (dispatch, componentProps) => {
  const {
    history,
    match,
    defaultValues = {},
    campaignConfig,
    waCommsData,
    openCustomAudienceModal
  } = componentProps;
  const isEdited = getIsEdited({ history });
  const { brand, country, status } = waCommsData || {};

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const uid = match.params.id;

    const dataToSend = {
      uid,
      userEmail: user.emailId,
      userName: user.name,
      audienceDefinition: data
    };

    const transformedData = transformWAPayloadByAudience(dataToSend);

    dispatch(
      updateWAComms(uid, transformedData, (error, result) => {
        if (error) {
          console.log('OnSubmit error: ', error);
        } else {
          history.push(
            WACampaignSubmitRedirectURL({
              backToReview: isEdited && backToReview,
              status,
              uid,
              nextPage: `${constants.WA_ROUTES.WA_SELECT_TEMPLATE}`
            })
          );
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'sendWATo',
        component: SendEmailToInput,
        id: 'sendWATo',
        label: 'Send WhatsApp to',
        campaignConfig: campaignConfig,
        defaultValue: defaultValues.sendWATo,
        options: getWAAudienceOptions(
          brand?.label || '',
          country?.label || '',
          `${campaignConfig && campaignConfig.LABEL_SIGN_UP_ID}`
        ),
        placeholder: 'Please select',
        rules: yup
          .object({
            type: yup
              .object({
                label: yup.string(),
                value: yup.string()
              })
              .required('Please select send WhatsApp to')
              .default(undefined),
            details: yup
              .object()
              .when('type', {
                is: data =>
                  data && data.value === 'consumersLinkedToCampaignIds',
                then: yup.object({
                  campaignIds: yup
                    .array()
                    .test(
                      'is-min',
                      `Please enter ${campaignConfig &&
                        campaignConfig.LABEL_SIGN_UP_ID}`,
                      value => {
                        const lastElem = value[value.length - 1];
                        if (
                          value.length < 2 &&
                          (!lastElem || lastElem.value === '')
                        ) {
                          return false;
                        }
                        return true;
                      }
                    )
                    .test(
                      'is-campaignid-valid',
                      `Please enter a valid ${campaignConfig &&
                        campaignConfig.LABEL_SIGN_UP_ID} (example CN123456 or PN123456)`,
                      value => {
                        const lastElem = value[value.length - 1];
                        const customValidation = constants.regex.campaignId;
                        if (value.length >= 1) {
                          if (lastElem?.value.trim() !== '') {
                            if (
                              customValidation &&
                              !customValidation.test(lastElem?.value)
                            ) {
                              return false;
                            }
                          }
                          return true;
                        }
                        return true;
                      }
                    )
                })
              })
              .when('type', {
                is: data => data && data.value === 'customCriteria',
                then: yup.object({
                  customCriteriaList: yup.array().of(yup.string()),
                  criteriaCampaignIds: yup.array().when('customCriteriaList', {
                    is: data => data && data.includes('campaignIds'),
                    then: yup
                      .array()
                      .test(
                        'is-campaignid-valid',
                        `Please enter a valid ${campaignConfig &&
                          campaignConfig.LABEL_SIGN_UP_ID} (example CN123456 or PN123456)`,
                        value => {
                          if (value) {
                            const lastElem = value[value.length - 1];
                            const customValidation = constants.regex.campaignId;
                            if (value.length >= 1) {
                              if (lastElem?.value.trim() !== '') {
                                if (
                                  customValidation &&
                                  !customValidation.test(lastElem?.value)
                                ) {
                                  return false;
                                }
                              }
                              return true;
                            }
                            return true;
                          }
                          return true;
                        }
                      )
                  })
                })
              })
          })
          .required('Please select send WhatsApp to')
          .default({ type: undefined, details: undefined }),
        openCustomAudienceModal
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: defaultValues.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(300, 'Max 300 characters are allowed'),
        maxLength: '300'
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const uid = match.params.id;
      const dataToSend = {
        uid,
        userEmail: user.emailId,
        userName: user.name,
        audienceDefinition: data
      };

      const transformedData = transformWAPayloadByAudience(dataToSend);

      dispatch(
        updateWAComms(
          uid,
          transformedData,
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              history.push(
                `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
              );
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.SCHEDULE}`
      );
    },

    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },

    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default formSchema;
