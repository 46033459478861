import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WATemplatesPanel } from './WATemplatePanel';
import logo from 'images/CW_logo.svg';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';
import { getWACoreTemplates } from 'actions/wabriefings/waCoreTemplates';
import { min, get, isEmpty } from 'lodash';
import constants from 'constants/config';
import { LinkButton } from 'components/atoms/LinkButton';
import AppliedFilters from 'components/organisms/Filters/AppliedFilters/AppliedFilters';
import Modal from 'react-modal';
import WaDesignGuideline from 'components/WaDesignGuideline';

const WASelectTemplate = props => {
  const [isEdit, setEdit] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [visible, setIsVisible] = useState(true);
  const [enteredText, setEnteredText] = useState('');
  const [userinfo, setUserInfo] = useState({
    waType: [],
    countries: [],
    brands: [],
    languages: [],
    templateCards: [],
    templateType: [],
    isFirstLoad: true
  });
  const [filterPills, setFilterPills] = useState({
    waType: [],
    countries: [],
    brands: [],
    languages: [],
    templateCards: [],
    templateType: []
  });

  const waCampaignDetails = useSelector(state => state.waBriefings);
  const [openGuideLineModel, setOpenGuideLineModel] = useState(false);

  const { waCoreTemplates: waTemplates } = useSelector(state => ({
    waCoreTemplates: state.waCoreTemplates
  }));
  console.log('waTemplates: ', waTemplates);

  const previewRef = useRef();

  const { authorizedUserData } = useSelector(state => state.authorized);

  const { isLoading, waCommsData } = waCampaignDetails;
  const { waDesign, status } = waCommsData;

  const dispatch = useDispatch();
  useEffect(() => {
    //dispatch(getWACoreTemplates());
    const pathUid = props.match.params.id;
    if (pathUid) {
      setEdit(true);
      dispatch(getWAcommsByUid(pathUid));
    }
  }, []);

  /**
   * Setting default email type conditionally
   */
  useEffect(() => {
    let userInfo;
    if (!isEmpty(waCommsData)) {
      const country = get(waCommsData, 'country');
      const brand = get(waCommsData, 'brand');
      const language = get(waCommsData, 'language');
      userInfo = {
        waType: [],
        countries: [country.value],
        brands: [brand.value],
        languages: [language.value],
        templateCards: [],
        templateType: [],
        isFirstLoad: false
      };
      setUserInfo(userInfo);
      setFilterPills({
        waType: [],
        countries: [{ label: country.label, value: country.value }],
        brands: [{ label: brand.label, value: brand.value }],
        languages: [{ label: language.label, value: language.value }],
        templateCards: [],
        templateType: []
      });
    }
  }, [waCommsData]);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };
  if (!waCampaignDetails) return null;

  const { limit, page, total } =
    (waTemplates &&
      waTemplates.data &&
      waTemplates.data.data &&
      waTemplates.data.data.pagination) ||
    {};

  const recordShowStart = (page - 1) * limit + 1;
  const recordShowto = min([recordShowStart + limit - 1, total]);

  const handleFilterChange = (e, data) => {
    const { value, checked, name } = e.target;
    const {
      waType,
      countries,
      brands,
      languages,
      templateCards,
      templateType
    } = userinfo;
    if (checked) {
      setUserInfo({
        waType: name === 'waType' ? [...waType, value] : waType,
        countries: name === 'countries' ? [...countries, value] : countries,
        brands: name === 'brands' ? [...brands, value] : brands,
        languages: name === 'languages' ? [...languages, value] : languages,
        templateCards:
          name === 'templateCards' ? [...templateCards, value] : templateCards,
        templateType:
          name === 'templateType' ? [...templateType, value] : templateType
      });
      setFilterPills({
        waType:
          name === 'waType'
            ? [...filterPills.waType, { value: value, label: data.label }]
            : filterPills.waType,
        brands:
          name === 'brands'
            ? [...filterPills.brands, { value: value, label: data.name }]
            : filterPills.brands,
        languages:
          name === 'languages'
            ? [...filterPills.languages, { value: value, label: data.name }]
            : filterPills.languages,
        countries:
          name === 'countries'
            ? [...filterPills.countries, { value: value, label: data.name }]
            : filterPills.countries,
        templateCards:
          name === 'templateCards'
            ? [
                ...filterPills.templateCards,
                { value: value, label: data.label }
              ]
            : filterPills.templateCards,
        templateType:
          name === 'templateType'
            ? [...filterPills.templateType, { value: value, label: data.label }]
            : filterPills.templateType
      });
    } else {
      setUserInfo({
        waType: waType.filter(e => e !== value),
        countries: countries.filter(e => e !== value),
        brands: brands.filter(e => e !== value),
        languages: languages.filter(e => e !== value),
        templateCards: templateCards.filter(e => e !== value),
        templateType: templateType.filter(e => e !== value)
      });
      setFilterPills({
        waType: filterPills.waType.filter(e => e.value !== value),
        countries: filterPills.countries.filter(e => e.value !== value),
        brands: filterPills.brands.filter(e => e.value !== value),
        languages: filterPills.languages.filter(e => e.value !== value),
        templateCards: filterPills.templateCards.filter(e => e.value !== value),
        templateType: filterPills.templateType.filter(e => e.value !== value)
      });
    }
  };

  const resetFilterPills = () => {
    setFilterPills({
      waType: [],
      countries: [],
      brands: [],
      languages: [],
      templateCards: [],
      templateType: []
    });
  };

  const getFiltersCount = givenInfo => {
    const filters = [
      'countries',
      'brands',
      'languages',
      'waType',
      'templateCards',
      'templateType'
    ];

    let filterCount = filters.reduce(
      (acc, filterKey) => acc + get(givenInfo, `${filterKey}.length`, 0),
      0
    );

    // if (userinfo.lastUpdated !== '') {
    //   filterCount += 1;
    // }
    return filterCount;
  };

  const onPageChange = pageNumber => {
    console.log('pageNumber: ', pageNumber);
    dispatch(
      getWACoreTemplates({ filter: userinfo, text: enteredText }, pageNumber)
    );
  };

  const onReset = () => {
    dispatch(getWACoreTemplates());
    setIsReset(true);
  };

  const removeFilterPill = (value, filterKey) => {
    setFilterPills({
      ...filterPills,
      [filterKey]: filterPills[filterKey].filter(e => e.value !== value)
    });
    setUserInfo({
      ...userinfo,
      [filterKey]: userinfo[filterKey].filter(e => e !== value)
    });
  };

  const handleOpenGuideline = () => {
    setOpenGuideLineModel(true);
  };

  const handleCloseGuideline = () => {
    setOpenGuideLineModel(false);
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="toolbar">
        <div className="toolbar__left">
          <a
            href="/campaign-wizard"
            className={'logo-link'}
            title="Campaign Wizard"
          >
            <img src={logo} alt="Campaign Wizard logo" className={'logo'} />
          </a>

          <button
            className="btn btn btn-outline-secondary mr-3"
            type="button"
            onClick={() => {
              const uid = props.match.params.id;
              props.history.push(
                `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.AUDIENCE}`
              );
            }}
          >
            Back
          </button>
        </div>
        <div className="toolbar__center">
          <div className="col"></div>
        </div>
        <div className="toolbar__right">
          <div className="d-flex"></div>
        </div>
      </div>
      <div className="cw-section email-template-page-padding">
        <div className="cw-section--title mb-15">
          <h2 className="cw-heading--secondary cw-email-heading">
            Select WhatsApp template
          </h2>
        </div>
        {/* {userinfo?.isFirstLoad ? (
          <Loader
            style={{
              position: 'fixed',
              height: '100vh',
              left: 0
            }}
          />
        ) : null} */}

        <p className="email-summary-font pb-20 email-tab-text">
          Choose from META approved templates available in Unilever. You can use
          these templates as a starting point to create your own WhatsApp
          message template. If you choose a previously approved template created
          in {waCommsData?.language?.label} language for{' '}
          {waCommsData?.brand?.label}, {waCommsData?.country?.label} and do not
          modify it, it will not be sent to META for approval. In any other
          case, your template will be assigned a new ID and will eventually be
          sent to META for approval.{' '}
          <LinkButton onClick={handleOpenGuideline}>
            Learn more about WhatsApp templates
          </LinkButton>
        </p>
        <AppliedFilters
          removeFilter={removeFilterPill}
          filters={filterPills}
          filtersCnt={getFiltersCount(userinfo)}
          _clearAllFilters={() => {
            onReset();
            resetFilterPills();
            setEnteredText('');
          }}
          className="mb-10"
        />
        <div className="select-template-page">
          <WATemplatesPanel
            templates={waTemplates}
            onReset={onReset}
            userEmail={props.userEmail}
            userName={props.userName}
            total={total}
            recordShowStart={recordShowStart}
            recordShowto={recordShowto}
            handleTemplatePreview={() => {}}
            page={page}
            limit={limit}
            onPageChange={onPageChange}
            visible={visible}
            setIsVisible={setIsVisible}
            enteredText={enteredText}
            setEnteredText={setEnteredText}
            userinfo={userinfo}
            setUserInfo={setUserInfo}
            handleFilterChange={handleFilterChange}
            setIsReset={setIsReset}
            isReset={isReset}
            waCommsData={waCommsData}
            resetFilterPills={resetFilterPills}
          />
        </div>

        <Modal
          scrollable={true}
          isOpen={openGuideLineModel}
          onRequestClose={handleCloseGuideline}
          className="cw-modal cw-modal--qnadetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <WaDesignGuideline onClose={handleCloseGuideline} />
        </Modal>
      </div>
    </div>
  );
};

export default WASelectTemplate;
