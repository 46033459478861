import React from 'react';
import moment from 'moment';
import { get, compact } from 'lodash';

import {
  DisplayWaTriggerInput,
  DisplaySubmittedWaTriggerInput,
  sentAfterWa
} from 'components/molecules/WaTriggerInput';
import { audienceOverviewDisplay } from './audienceOverviewDisplay';
import { couponDisplay, CouponCodeWADisplay } from './CouponCodeWADisplay';
// import { SmsContentTableDisplay } from './SmsContentTableDisplay';
import CardDisplay, { getCaroCardProperties } from './CardDisplay';
import { formatURLandPhoneNumber } from 'utilities/util';
import parse from 'html-react-parser';

import constants from 'constants/config';
import {
  isConsentFieldEditable,
  userHasCouponCodePermission,
  hasPersmissionToUploadWACouponFile
} from 'selectors';

const parseRichText = text => {
  if (!text) return text;
  return parse(formatURLandPhoneNumber(text));
};

export function getWATabularData(
  waCampaign,
  campaignConfig = {},
  currentUser,
  review
) {
  const {
    brand,
    country,
    language,
    name,
    waType,
    communicationDetail = {}, // ?
    waOverview,
    dpcPraReferenceNo,
    submissionDate,
    waContentTheme,
    waContentSecondaryTheme,
    status,
    requestorsEmail,
    requestorsName,
    audienceDefinition,
    uid,
    deliverySchedule,
    abTesting = {},
    winningVariant = null,
    waAttributes,
    couponFileTrack = {},
    emailCollaborators = {},
    shareCostEstimate = {},
    sharePurchaseOrder = {},
    optOutText,
    nmiAssignedToRequestor,
    waDesign
  } = waCampaign;

  const { campaignIds, customCriteria } = audienceOverviewDisplay(
    audienceDefinition,
    campaignConfig
  );

  const { costFile, audienceCount } = shareCostEstimate;
  const { poFile } = sharePurchaseOrder;

  const showCostFileField =
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER ||
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP ||
    status === constants.WA_CAMPAIGN_STATUS.SCHEDULED ||
    status === constants.WA_CAMPAIGN_STATUS.LIVE ||
    (status === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO &&
      !nmiAssignedToRequestor);

  const showPOFileField =
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP ||
    status === constants.WA_CAMPAIGN_STATUS.SCHEDULED ||
    status === constants.WA_CAMPAIGN_STATUS.LIVE ||
    (status === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO &&
      !nmiAssignedToRequestor);

  const isFixedDateAndTime =
    deliverySchedule?.waTrigger?.type === 'fixedDateAndtime';

  const couponApplied = waAttributes?.waCoupon?.couponApplied;
  const couponType = waAttributes?.waCoupon?.type;
  const sendAfter = get(deliverySchedule, 'sendAfter.value', false);

  const isDontSend = sendAfter === 'DontSend';
  const isCouponCodeVisible = userHasCouponCodePermission(
    requestorsEmail,
    currentUser,
    emailCollaborators
  );
  const isDownloadAllowed = hasPersmissionToUploadWACouponFile(
    requestorsEmail,
    currentUser,
    emailCollaborators
  );

  const WASecondaryTheme =
    waContentSecondaryTheme?.map(theme => theme.label).join(', ') || '';

  const showTemplateStatusField =
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_COST_ESTIMATE ||
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_META_APPROVAL ||
    status === constants.WA_CAMPAIGN_STATUS.REJECTED_BY_META;

  const WAHeaderTypeLabel = {
    headerNone: 'None',
    headerText: 'Header text',
    headerImage: 'Header image',
    headerVideo: 'Header video',
    headerDocument: 'Header document',
    headerLocation: 'Header location'
  };

  const brandDetails = [];
  brandDetails.push({
    label: 'Country',
    value: country?.label || ''
  });
  brandDetails.push({
    label: 'Brand',
    value: brand?.label || ''
  });
  brandDetails.push({ label: 'WhatsApp language', value: language?.label });

  const WAType = [];
  WAType.push({
    label: 'WhatsApp Type',
    value: constants.WA_TYPE_LABELS[waType]
  });

  const emailCollaboratorsData = () => {
    let collabUserData = [
      {
        label: 'Collaborators permissions',
        value:
          constants.EMAIL_COLLABORATORS_PERMISSION_TYPE[
            emailCollaborators.type
          ],
        mustShow: true
      }
    ];
    if (emailCollaborators.type === 'requestorAndSelectedUsers') {
      collabUserData.push({
        label: 'Collaborators',
        value: compact(
          emailCollaborators.collaborators.map(item => item.name)
        ).join(', ')
      });
    }
    return collabUserData;
  };

  const campaignDetails = [];
  if (name) {
    campaignDetails.push({
      label: 'Campaign name',
      value: name,
      mustShow: true
    });
  }
  if (waOverview) {
    campaignDetails.push({
      label: 'Description',
      value: waOverview,
      mustShow: true
    });
  }

  if (waContentTheme?.label) {
    campaignDetails.push({
      label: 'Content theme',
      value: waContentTheme?.label,
      mustShow: true
    });
  }

  if (WASecondaryTheme) {
    campaignDetails.push({
      label: 'Secondary theme',
      value: WASecondaryTheme,
      mustShow: true
    });
  }

  if (!review) {
    campaignDetails.push({
      label: 'Brand',
      value: brand?.label || ''
    });
    campaignDetails.push({
      label: 'Country',
      value: country?.label || ''
    });
    campaignDetails.push({
      label: 'WhatsApp language',
      value: language?.label
    });
  }

  if (dpcPraReferenceNo) {
    campaignDetails.push({
      label: 'PRA reference',
      value: dpcPraReferenceNo,
      mustShow: true
    });
  }

  campaignDetails.push({
    label: 'Requestor',
    value: requestorsName,
    mustShow: true
  });
  if (showTemplateStatusField) {
    campaignDetails.push({
      label: 'Template status',
      value: constants.emailStatus[parseInt(status)],
      mustShow: true
    });
  }
  if (showCostFileField && costFile && costFile.originalname) {
    campaignDetails.push({
      label: 'Cost estimate',
      value: costFile.originalname,
      mustShow: true,
      tooltip:
        'Cost estimates are visible only to campaign requestor, collaborators, ACS team and admins',
      typeOfDownload: 'ce',
      isDownloadable: isDownloadAllowed
    });
  }
  if (showPOFileField && poFile && poFile.originalname) {
    campaignDetails.push({
      label: 'Purchase order',
      value: poFile.originalname,
      mustShow: true,
      tooltip:
        'POs are visible only to campaign requestor, collaborators, ACS team and admins',
      typeOfDownload: 'po',
      isDownloadable: isDownloadAllowed
    });
  }
  if (submissionDate && !review) {
    campaignDetails.push({
      label: 'Requested on',
      value: submissionDate ? moment(submissionDate).format('DD MMM YYYY') : '',
      mustShow: true
    });
  }

  campaignDetails.push(...emailCollaboratorsData());

  // if (communicationDetail && communicationDetail.additionalInfo) {
  //   campaignDetails.push({
  //     label: 'Additional information',
  //     value: communicationDetail.additionalInfo,
  //     mustShow: true
  //   });
  // }

  const audienceDetails = [];
  if (audienceDefinition?.sendWATo?.type?.label) {
    audienceDetails.push({
      label: 'Send WhatsApp to',
      value: audienceDefinition?.sendWATo?.type?.label,
      mustShow: true
    });
  }

  audienceDetails.push(...campaignIds);
  audienceDetails.push(...customCriteria);

  {
    audienceDefinition?.additionalInformation &&
      audienceDetails.push({
        label: 'Additional information',
        value: audienceDefinition?.additionalInformation,
        mustShow: true
      });
  }

  if (showCostFileField) {
    audienceDetails.push({
      label: 'Audience count',
      value: isFixedDateAndTime
        ? deliverySchedule?.consumerCount || audienceCount
          ? deliverySchedule?.consumerCount || audienceCount
          : 'ACS execution team will update the expected audience count when they setup the campaign'
        : 'Not available for WhatsApp with dynamic triggers'
    });
  }

  const designProperties = [];

  if (get(waDesign, 'templateCategory', '')) {
    designProperties.push({
      label: 'Template category',
      value: get(waDesign, 'templateCategory'),
      mustShow: true
    });
  }

  if (get(waDesign, 'templateName', '')) {
    designProperties.push({
      label: 'WhatsApp template name',
      value: get(waDesign, 'templateName'),
      mustShow: true
    });
  }

  if (get(waDesign, 'carousel.messageType.value') === 'carousel') {
    designProperties.push({
      label: 'Main message bubble text',
      value: parseRichText(get(waDesign, 'carousel.mainMessageBubbleText', '')),
      mustShow: true,
      isRichText: true
    });
    designProperties.push({
      label: 'Meta template',
      value: get(waDesign, 'carousel.mainMessageBubbleTextFormat', ''),
      mustShow: true,
      isRichText: false
    });
    const carouselCards = get(waDesign, 'carousel.carouselCards', []);
    if (carouselCards.length) {
      carouselCards.forEach((item, index) => {
        designProperties.push({
          label: `Carousal Card (${index + 1})`,
          value: (
            <CardDisplay data={item} WAHeaderTypeLabel={WAHeaderTypeLabel} />
          ),
          //isIndented: true
          //sections:[{section: { properties: getCaroCardProperties(item, WAHeaderTypeLabel)}}]
          valueSectionClass: 'mw-100 flex-grow-1'
        });
      });
    }
  } else {
    const headerType = get(waDesign, 'waHeader.type');
    console.log('headerType: ', headerType);
    if (headerType) {
      designProperties.push({
        label: 'Header',
        value: WAHeaderTypeLabel[headerType],
        mustShow: true
      });
    }

    if (
      headerType === 'headerText' &&
      get(waDesign, 'waHeader.headerText', '')
    ) {
      designProperties.push({
        label: 'Header text',
        value: get(waDesign, 'waHeader.headerText'),
        mustShow: true
      });

      designProperties.push({
        label: 'Meta template',
        value: get(waDesign, 'waHeader.headerTextSerialFormated'),
        mustShow: true
      });
    }

    if (
      headerType === 'headerImage' &&
      get(waDesign, 'waHeader.headerImage', '')
    ) {
      designProperties.push({
        label: 'Header image',
        value: get(waDesign, 'waHeader.headerImage'),
        mustShow: true,
        isImageUrl: true
      });
    }

    if (
      headerType === 'headerVideo' &&
      get(waDesign, 'waHeader.headerVideo', '')
    ) {
      designProperties.push({
        label: 'Header video',
        value: get(waDesign, 'waHeader.headerVideo'),
        mustShow: true
      });
    }

    if (
      headerType === 'headerDocument' &&
      get(waDesign, 'waHeader.headerDocument', '')
    ) {
      designProperties.push({
        label: 'Header document',
        value: get(waDesign, 'waHeader.headerDocument'),
        mustShow: true
      });
    }
    if (headerType === 'headerLocation') {
      designProperties.push({
        label: 'Location name',
        value: get(waDesign, 'waHeader.headerLocation.locationName'),
        mustShow: true
      });
      designProperties.push({
        label: 'Location address',
        value: get(waDesign, 'waHeader.headerLocation.locationAddress'),
        mustShow: true
      });
      designProperties.push({
        label: 'Location latitude',
        value: get(waDesign, 'waHeader.headerLocation.locationLatitude'),
        mustShow: true
      });
      designProperties.push({
        label: 'Location longitude',
        value: get(waDesign, 'waHeader.headerLocation.locationLongitude'),
        mustShow: true
      });
    }

    const waLtoDetails = get(waDesign, 'waOffer.waLtoDetails');

    if (waLtoDetails && get(waLtoDetails, 'ltoSummary', '')) {
      designProperties.push({
        label: 'LTO summary text',
        value: get(waLtoDetails, 'ltoSummary'),
        mustShow: true
      });
    }
    if (waLtoDetails && get(waLtoDetails, 'expirationDate', '')) {
      designProperties.push({
        label: 'LTO expiration date',
        value: get(waLtoDetails, 'expirationDate')
          ? moment(get(waLtoDetails, 'expirationDate')).format('DD MMM YYYY')
          : '',
        mustShow: true
      });
    } else if (
      waLtoDetails &&
      get(waLtoDetails, 'ltoSummary') &&
      !get(waLtoDetails, 'expirationDate')
    ) {
      designProperties.push({
        label: 'LTO expiration date',
        value: 'No expiration date',
        mustShow: true
      });
    }

    if (get(waDesign, 'waBodyText', '')) {
      designProperties.push({
        label: 'Body text',
        value: parseRichText(get(waDesign, 'waBodyText', '')),
        mustShow: true,
        isRichText: true
      });
      designProperties.push({
        label: 'Meta template',
        value: parseRichText(get(waDesign, 'waBodyTextSerialFormated', '')),
        mustShow: true,
        isRichText: true
      });
    }

    if (get(waDesign, 'waFooterText')) {
      designProperties.push({
        label: 'Footer',
        value: get(waDesign, 'waFooterText'),
        mustShow: true
      });
    }

    const waDesignButtons = get(waDesign, 'waButtons', []);

    if (waDesignButtons.length) {
      waDesignButtons.forEach((item, index) => {
        item.nestedArray.forEach((childItem, index) => {
          designProperties.push({
            label: `Button ${index + 1}`,
            value: `${get(childItem, 'type')} - ${get(
              childItem,
              'buttonName'
            ) || 'NA'} - ${get(childItem, 'buttonValue')}`,
            mustShow: true
          });
        });
      });
    }
  }

  let waTrigger =
    status === constants.WA_CAMPAIGN_STATUS.SUBMITTED ? (
      <></>
    ) : // DisplaySubmittedwaTriggerInput(
    //     communicationDetail && communicationDetail.smsDetails
    //   )
    deliverySchedule && deliverySchedule.waTrigger ? (
      DisplayWaTriggerInput(
        deliverySchedule.waTrigger,
        abTesting,
        winningVariant
      )
    ) : (
      [
        // {
        //   label: 'WhatsApp trigger',
        //   mustShow: true,
        //   value: ''
        // }
      ]
    );

  // removed to hide follow up for first release
  let sendAfterArr = deliverySchedule
    ? sentAfterWa(deliverySchedule)
    : [
        {
          label: 'Send follow up',
          mustShow: true,
          value: ''
        }
      ];

  const deliveryScheduleData = [
    ...waTrigger,
    // ...sendAfterArr,
    {
      label: 'Additional information',
      value: deliverySchedule && deliverySchedule.additionalInformation
    }
  ];

  const WAABTesting = [
    {
      label: 'A/B test',
      value: abTesting && abTesting.performABTests ? 'Yes' : 'No'
    }
  ];
  if (abTesting && abTesting.performABTests) {
    WAABTesting.push({
      label: 'A/B test elements',
      value: constants.WA_AB_TESTING_CONSTANTS[abTesting.abTestElements] || '',
      mustShow: true
    });
    WAABTesting.push({
      label: 'AB test split',
      value: constants.WA_AB_TESTING_CONSTANTS[abTesting.abTestSplits] || '',
      mustShow: true
    });
    if (
      constants.EMAIL_AB_TEST_DURATION_CHECK_LIST.includes(
        abTesting.abTestSplits
      )
    ) {
      WAABTesting.push({
        label: 'Test duration',
        value: abTesting.testDuration ? abTesting.testDuration.label : '',
        mustShow: true
      });
    }
    WAABTesting.push({
      label: 'Win criteria',
      value: constants.WA_AB_TESTING_CONSTANTS[abTesting.winCriteria] || '',
      mustShow: true
    });
  }

  const campaignAttributes = [
    {
      label: 'Coupons',
      value: couponDisplay(couponApplied, couponType)
    },
    {
      ...(couponApplied && {
        label: 'Coupon codes',
        value: (
          <CouponCodeWADisplay
            waAttributes={waAttributes}
            userPermitted={isCouponCodeVisible}
            couponFileTrack={couponFileTrack}
          />
        )
      })
    }
  ];

  if (constants.featureFlags.ENABLE_WA_ABTESTING) {
    campaignAttributes.push(...WAABTesting);
  }

  return {
    brandDetails,
    waType: WAType,
    campaignDetails,
    audienceDetails,
    designProperties,
    deliveryScheduleData,
    campaignAttributes
  };
}
