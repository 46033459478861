// Dashboard Reducer
import {
  GET_CAMPAIGNS_LIST,
  FETCHING_CAMPAIGNS,
  SERVICE_ERROR_DASHBOARD,
  SET_DASHBOARD_NOTIFICATION_MESSAGE,
  GET_DASHBOARD_BRAND_DETAILS,
  DASHBOARD_BRAND_DETAIL_SUCCESS,
  GET_DASHBOARD_BRAND_COUNTRIES,
  FETCHING_DASHBOARD_BRANDS,
  GET_DASHBOARD_BRAND_LIST,
  FETCHING_DASHBOARD_WEBSITE_TYPES,
  GET_DASHBOARD_WEBSITE_TYPE_LIST,
  GET_DASHBOARD_MATRIX_DATA,
  GET_BUSINESS_GROUP_LIST,
  GET_DASHBOARD_LANGUAGE_LIST,
  FETCH_CONSENT_SUCCESS,
  FETCH_CONSENT_REQUEST,
  FETCH_CONSENT_FAILURE,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_COUNTRY_SUCCESS
} from '../actionTypes';

const initialState = {
  asyncInProgress: false,
  totalCount: '',
  campaigns: [],
  pageNumber: 1,
  limit: 50,
  recordShowStart: 1,
  recordShowto: 10,
  serviceError: null,
  serviceErrorStatus: null,
  showLoader: true,
  notificationMessage: '',
  notificationLink: '',
  notificationType: '',
  notificationCampaign: '',
  countries: [],
  brands: [],
  languages: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_DASHBOARD_BRANDS: {
      return { ...state, ...{ asyncInProgress: action.asyncInProgress } };
    }
    case FETCHING_CAMPAIGNS: {
      return { ...state, ...{ showLoader: action.showLoader } };
    }
    case GET_CAMPAIGNS_LIST: {
      let campaigns = { campaigns: action.campaigns };
      let totalCount = { totalCount: action.totalCount };
      let pageNumber = { pageNumber: parseInt(action.pageNumber) };
      let recordShowStart = { recordShowStart: action.recordShowStart };
      let recordShowto = { recordShowto: action.recordShowto };
      let showloader = { showLoader: false };
      return {
        ...state,
        ...campaigns,
        ...totalCount,
        ...pageNumber,
        ...recordShowStart,
        ...recordShowto,
        ...showloader
      };
    }
    case SERVICE_ERROR_DASHBOARD: {
      let serviceError = {
        serviceError: true,
        serviceErrorStatus:
          (action.error.response && action.error.response.status) || 401,
        showLoader: false
      };
      return { ...state, ...serviceError };
    }
    case SET_DASHBOARD_NOTIFICATION_MESSAGE: {
      let notification = {
        notificationMessage: action.message,
        notificationLink: action.linkedTo,
        notificationType: action.notificationType,
        notificationCampaign: action.campaignName,
        notificationMessageComp: action.notificationMessageComp
      };

      return { ...state, ...notification };
    }
    case GET_DASHBOARD_BRAND_DETAILS: {
      let countries = { countries: action.brandDetails.countries };

      return {
        ...state,
        ...countries
      };
    }
    case DASHBOARD_BRAND_DETAIL_SUCCESS: {
      return {
        ...state,
        ...{ brandDetailSuccess: action.payload }
      };
    }
    case GET_DASHBOARD_BRAND_COUNTRIES: {
      let countries = {
        countries: action.countryDetailsSorted
      };

      return {
        ...state,
        ...countries
      };
    }

    case GET_DASHBOARD_BRAND_LIST: {
      let brands = {
        brands: action.brandsListSorted
      };

      return {
        ...state,
        ...brands
      };
    }

    case FETCHING_DASHBOARD_WEBSITE_TYPES: {
      return { ...state, ...{ asyncInProgress: action.asyncInProgress } };
    }

    case GET_DASHBOARD_WEBSITE_TYPE_LIST: {

      let websiteTypes = {};
      let websites = action.websiteListSorted && action.websiteListSorted;
      if(websites ){
        const excludeWeb = ['Adobe Classic','Adobe Templated']
        let updatedWebTypes = websites.filter(web=> web && web.name && !excludeWeb.includes(web.name))
        websiteTypes = { websiteTypes : updatedWebTypes}
      }
      
      return {
        ...state,
        ...websiteTypes
      };
    }

    case GET_DASHBOARD_MATRIX_DATA: {
      let campaignMatrix = {
        campaignMatrix: action.campaignMatrix
      };
      return {
        ...state,
        ...campaignMatrix
      };
    }

    case GET_BUSINESS_GROUP_LIST: {
      let businessGroup = {
        businessGroup: action.businessGroupListSorted
      };
      return {
        ...state,
        ...businessGroup
      };
    }

    case GET_DASHBOARD_LANGUAGE_LIST: {
      let languages = {
        languages: action.languageDetailsSorted
      };

      return {
        ...state,
        ...languages
      };
    }

    case FETCH_CONSENT_REQUEST: {
      let consent = {
        loading: true
      };
      return {
        ...state,
        consent
      };
    }

    case FETCH_CONSENT_SUCCESS: {
      let consent = {
        loading: false,
        data: action.payload
      };
      return {
        ...state,
        consent
      };
    }

    case FETCH_CONSENT_FAILURE: {
      let consent = {
        loading: false,
        error: action.payload
      };
      return {
        ...state,
        consent
      };
    }

    case FETCH_COUNTRY_SUCCESS: {
      return {
        ...state,
        countryList: action.payload
      };
    }

    case FETCH_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languageList: action.payload
      };
    }

    case GET_DASHBOARD_LANGUAGE_LIST: {
      let languages = {
        languages: action.languageDetailsSorted
      };

      return {
        ...state,
        ...languages
      };
    }

    default:
      return state;
  }
}
