import constants from 'constants/config';
import React from 'react';
import MultiGuideLines from './MultiGuideLines';
const MetaRejectGuideline = props => {
  return (
    <div style={{ maxHeight: '85vh' }}>
      <div className="guideline-wrapper--header">
        <p
          className={`title`}
        >{`Meta’s criteria for WhatsApp template approval or rejection`}</p>
        <i className="guideline-wrapper-close-icon" onClick={props.onClose}></i>
      </div>
      <p className="mb-10">
        Campaign Wizard sends each WhatsApp campaign template to META for
        approval, as per prescribed META guidelines. Campaign Wizard sends basic
        campaign info such as template name, labels, language, message
        components etc. to request META for approval. The template is usually
        approved in a few minutes or max in 48 hours but may also be rejected
        due to one or more of the following reasons.
      </p>
      <div className="col-sm-12 mt-10">
        <div className="overlay-content-wrapper">
          <div className="overlay-content">
            <MultiGuideLines
              data={constants.META_REJECT_GUIDELINE}
              qnaIndex={true}
              noLineBreak={true}
              ulStyle={{ listStyleType: 'circle', marginLeft: 20 }}
              liStyle={{ color: '#000000 !important' }}
              liClassName="brandidetails"
            />
          </div>
          <div className="bottom-cta mt-10">
            <div className="horizontal-line"></div>
            <div className="mt-3">
              <button
                type="submit"
                onClick={props.onClose}
                className="btn btn-block btn-outline-secondary mb-20 cw-print-hide text-nowrap"
              >
                Close
              </button>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default MetaRejectGuideline;
