import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormBuilder } from 'components/organisms/FormBuilder';
import ProgressBar from 'components/molecules/ProgressBar';
import Loader from 'components/Loader';
import NewUpdateAlert from 'components/molecules/NewUpdateAlert';
import { CustomButtons } from 'pages/NewEmailCampaign';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';
import OnboardingGuidanceWAModal from './OnboardingGuidanceWACamp';
import formSchema from './brandDetailsSchema';

import constants from 'constants/config';

const WACampaignBrandDetails = props => {
  const { isLoading, waCommsData } = useSelector(state => state.waBriefings);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const [isEdit, setEdit] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const dispatch = useDispatch();
  const { status } = waCommsData;

  useEffect(() => {
    const pathUid = props.match.params.id;
    console.log('pathUid: ', pathUid);
    if (pathUid) {
      setEdit(true);
    }
    if (pathUid) {
      dispatch(getWAcommsByUid(pathUid));
    }
  }, []);

  const handleModal = e => {
    if (e) {
      e.preventDefault();
    }
    setIsModelOpen(!isModalOpen);
  };

  return (
    <div className="col-sm-12 skypink email-campaign-brand-selection">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.NEW_CAMPAIGN}
          totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Enter Brand Details
          </h2>
          <p>
            <a
              href="#modal"
              onClick={handleModal}
              title="Onboarding guidance for SMS campaigns"
            >
              Onboarding guidance for WhatsApp campaigns
            </a>
          </p>
        </div>

        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(
                dispatch,
                {
                  history: props.history,
                  match: props.match,
                  isEdit,
                  setIsModelOpen,
                  status: status
                },
                waCommsData
              )}
              user={authorizedUserData}
              isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>

          <div className="col-md-4">
            <NewUpdateAlert
              className="mt-30"
              title="WhatsApp marketing is now inhouse!"
              content={
                <>
                  <p>
                    Empower yourself with complete control over your WhatsApp
                    campaigns using our new and improved WhatsApp capabilities.
                  </p>
                  <p>
                    Here's how it works: Simply submit your campaign, and our
                    dedicated market approvers will swiftly review and approve
                    it. Once approved, our Global ACS team will provide you with
                    a cost estimate, ensuring transparency and budget control.
                    Then, create your Purchase Order in Coupa and upload it to
                    Campaign Wizard, and watch as our global ACS team spring
                    into action, executing your campaign with precision and
                    efficiency.
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
      <OnboardingGuidanceWAModal
        isModalOpen={isModalOpen}
        closeModal={handleModal}
      />
    </div>
  );
};

export default WACampaignBrandDetails;
