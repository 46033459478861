import React, { useState, useRef, useEffect } from 'react';
export const AddButtonMenu = ({
  getValues,
  setValue,
  waCouponType,
  waCouponCode,
  buttonCount,
  props,
  disabled
}) => {
  const maxNumberOfButtonsAllowed = 10;
  const ButtonTypeOptions = [
    {
      type: 'Phone number button',
      label: 'Phone number button',
      disabled: false,
      max: 1
    },
    {
      type: 'Website URL',
      label: 'Website URL',
      disabled: false,
      max: 2
    },
    {
      type: 'Copy code button',
      label: 'Copy code button',
      disabled: false,
      max: 1
    },
    {
      type: 'Quick reply - Unsubscribe',
      label: 'Quick reply - Unsubscribe',
      disabled: false,
      max: 1
    },
    {
      type: 'Quick reply - Others',
      label: 'Quick reply - Others',
      disabled: false,
      max: 10
    }
  ];

  const [buttonOptions, setbuttonOptions] = useState(ButtonTypeOptions);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [isAddbuttonOptionsOpen, setIsAddbuttonOptionsOpen] = useState(false);
  const menuRef = useRef(null); // Ref to track the menu element

  const addQuickReplyRow = evt => {
    const currentVal = getValues();
    const indexOfQuickReply = findIndexById(currentVal, 'quickReply');
    const elementType = evt?.currentTarget?.id;
    const elementMaxVal = evt?.currentTarget?.dataset.max;
    const { elementTypeCount, totalNestedArrayCount } = countObjectsByType(
      currentVal.waButtons,
      elementType
    );

    updatedMenuOptions(elementType, elementTypeCount);
    // if current count is less than max allowed limit
    if (
      indexOfQuickReply >= 0 &&
      elementTypeCount < elementMaxVal &&
      totalNestedArrayCount < maxNumberOfButtonsAllowed
    ) {
      const oldState = currentVal.waButtons[indexOfQuickReply].nestedArray;
      setValue(`waButtons.${indexOfQuickReply}.nestedArray`, [
        ...oldState,
        {
          type: elementType,
          buttonName: '',
          buttonValue: ''
        }
      ]);
    }
  };

  const addNonQuickReplyRow = evt => {
    const currentVal = getValues();
    const indexOfNonQuickReply = findIndexById(currentVal, 'nonQuickReply');
    const elementType = evt?.currentTarget?.id;
    const elementMaxVal = evt?.currentTarget?.dataset.max;
    const { elementTypeCount, totalNestedArrayCount } = countObjectsByType(
      currentVal.waButtons,
      elementType
    );
    updatedMenuOptions(elementType, elementTypeCount);
    // if current count is less than max allowed limit
    if (
      indexOfNonQuickReply >= 0 &&
      elementTypeCount < elementMaxVal &&
      totalNestedArrayCount < maxNumberOfButtonsAllowed
    ) {
      const oldState = currentVal.waButtons[indexOfNonQuickReply].nestedArray;
      const buttonDefaultVal =
        elementType === 'Copy code button' && waCouponType === 'staticCoupon'
          ? waCouponCode
          : elementType === 'Copy code button' &&
            waCouponType === 'dynamicCoupon'
          ? 'COUPON123'
          : '';
      const buttonDefaultName =
        elementType === 'Copy code button' ? 'Coupon code' : '';
      setValue(`waButtons.${indexOfNonQuickReply}.nestedArray`, [
        ...oldState,
        {
          type: elementType,
          buttonName: buttonDefaultName,
          buttonValue: buttonDefaultVal
        }
      ]);
    }
  };

  function findIndexById(currentVal, id) {
    return currentVal.waButtons.findIndex(button => button?.id === id);
  }
  /**
   * function to count the occurrences of objects.
   * For example count "type" = "copyCode" within the "nestedArray" key
   * @param {*} sourceArray
   * @param {*} elementType
   * @returns
   */
  const countObjectsByType = (sourceArray, elementType) => {
    let elementTypeCount = 0;
    let totalNestedArrayCount = 0;

    sourceArray &&
      sourceArray.forEach(item => {
        totalNestedArrayCount += item.nestedArray.length;
        elementTypeCount += item.nestedArray.filter(
          nestedItem => nestedItem.type === elementType
        ).length;
      });
    return {
      elementTypeCount,
      totalNestedArrayCount
    };
  };

  const updatedMenuOptions = (elementType, elementTypeTotalCount) => {
    let updatedButtonOptions;

    if (elementTypeTotalCount === undefined) {
      // on remove; useEffect call
      const currentVal = getValues();
      updatedButtonOptions = buttonOptions.map(option => {
        const { elementTypeCount, totalNestedArrayCount } = countObjectsByType(
          currentVal.waButtons,
          option.type
        );
        return parseInt(elementTypeCount) + 1 > parseInt(option?.max)
          ? { ...option, disabled: true }
          : { ...option, disabled: false };
      });
    } else {
      // on click of menu item
      updatedButtonOptions = buttonOptions.map(option =>
        option?.type === elementType &&
        parseInt(elementTypeTotalCount) + 1 >= parseInt(option?.max)
          ? { ...option, disabled: true }
          : option
      );
    }
    setbuttonOptions(updatedButtonOptions); // update the state
    // console.log('---- after update ----', updatedButtonOptions, buttonOptions, elementType , elementTypeTotalCount, getValues())
  };

  const isButtonDisabled = type => {
    const optionObj = buttonOptions.find(option => option.type === type);
    return optionObj ? optionObj.disabled : false;
  };

  // Function to close the menu when clicked outside
  const handleClickOutside = event => {
    if (
      event &&
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !event.target.className.includes('dropdown-toggle')
    ) {
      setIsAddbuttonOptionsOpen(false);
    }
  };
  useEffect(() => {
    if (
      disabled ||
      parseInt(buttonCount) >= parseInt(maxNumberOfButtonsAllowed)
    ) {
      setAddButtonDisabled(true);
    } else {
      setAddButtonDisabled(false);
    }
    updatedMenuOptions();
  }, [buttonCount, disabled]);

  // add event listener for clicks outside the menu
  useEffect(() => {
    if (isAddbuttonOptionsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup
    };
  }, [isAddbuttonOptionsOpen]);

  return (
    <>
      <div className="btn-group dropup mt-30 mb-10">
        <button
          onClick={() => {
            setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
          }}
          type="button"
          className={
            addButtonDisabled
              ? 'btn btn-outline-secondary dropdown-toggle disabled'
              : 'btn btn-outline-secondary dropdown-toggle'
          }
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          disabled={addButtonDisabled}
        >
          Add button
        </button>
        <div
          className={
            isAddbuttonOptionsOpen ? 'dropdown-menu show' : 'dropdown-menu'
          }
          ref={menuRef}
        >
          <button
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addNonQuickReplyRow(e);
            }}
            id="Phone number button"
            data-max={1}
            href="#"
            disabled={isButtonDisabled('Phone number button')}
          >
            Phone number button{' '}
            <span className="email-label-subtext">(max 1)</span>
          </button>
          <button
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addNonQuickReplyRow(e);
            }}
            id="Website URL"
            data-max={2}
            href="#"
            disabled={isButtonDisabled('Website URL')}
          >
            Website URL <span className="email-label-subtext">(max 2)</span>
          </button>
          {waCouponType ? (
            <>
              <button
                className="dropdown-item"
                onClick={e => {
                  e.preventDefault();
                  setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
                  addNonQuickReplyRow(e);
                }}
                id="Copy code button"
                data-max={1}
                href="#"
                disabled={isButtonDisabled('Copy code button')}
              >
                Copy code button{' '}
                <span className="email-label-subtext">(max 1)</span>
              </button>
            </>
          ) : null}

          <div className="dropdown-divider"></div>
          <button
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addQuickReplyRow(e);
            }}
            id="Quick reply - Unsubscribe"
            data-max={1}
            href="#"
            disabled={isButtonDisabled('Quick reply - Unsubscribe')}
          >
            Quick reply - Unsubscribe{' '}
            <span className="email-label-subtext">(max 1)</span>
          </button>
          <button
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addQuickReplyRow(e);
            }}
            id="Quick reply - Others"
            data-max={10}
            href="#"
            disabled={isButtonDisabled('Quick reply - Others')}
          >
            Quick reply - Others{' '}
            <span className="email-label-subtext">(max 10)</span>
          </button>
        </div>
      </div>
    </>
  );
};
