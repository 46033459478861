export default [
  {
    value: '{{FirstName}}',
    label: 'First name',
    defaultValue: 'John',
    maxSelections: 1
  },
  {
    value: '{{LastName}}',
    label: 'Last name',
    defaultValue: 'Doe',
    maxSelections: 1
  },
  {
    value: '{{Prefix}}',
    label: 'Prefix',
    defaultValue: 'Mr.',
    maxSelections: 1
  },
  {
    value: '{{StaticCoupon}}',
    label: 'Static coupon',
    defaultValue: 'COUPON123',
    maxSelections: 1
  },
  {
    value: '{{DynamicCoupon}}',
    label: 'Dynamic coupon',
    defaultValue: 'DYNAMIC-COUPON',
    maxSelections: 1
  }
];
