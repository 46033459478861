import React, { useState, useEffect, useRef } from 'react';
import ActionEditIcon from '../../../images/action-edit.png';
import ReactTooltip from 'react-tooltip';
import Loader from 'components/Loader';
import { Input } from '../../atoms/Input';
import classNames from 'classnames';

export default function WAActionBar({ onClickEdit, showActions = false }) {
  const inputRef = useRef();
  const isEditFuc = typeof onClickEdit === 'function';
  return (
    <div className={`sms-action-bar ${showActions ? 'mb-15' : ''}`}>
      {isEditFuc && (
        <>
          <button onClick={onClickEdit} className="btn sms-action-btn">
            <img src={ActionEditIcon} alt="Edit SMS" className="mr-2" />
            Edit
          </button>
        </>
      )}
    </div>
  );
}
