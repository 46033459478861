import axios from 'axios';
import {
  GET_USER_DATA,
  NEW_USER_DATA_ADDED,
  UPDATE_USER_DATA,
  DELETE_USER,
  USER_CANNOT_DELETED,
  TAB_CHANGE_USER,
  ASYNC_IN_PROGRESS_USER_MANAGEMENT,
  USER_EMAIL_ALREADY_EXISTS,
  GET_APPROVER_COUNTRIES,
  APPROVER_COUNTRY_LOADED_SUCCESS,
  UPDATE_APPROVER_DETAILS,
  GET_RELEASE_NOTE,
  USER_APPROVER_EMAIL_VALIDATION,
  GET_BUSINESS_GROUP_LIST,
  UPDATE_BUSINESS_GROUP_DETAILS
} from '../../actionTypes';
import constant from '../../constants';
import _ from 'lodash';

import moment from 'moment';

export const updateApproverCountry = approverDetails => ({
  type: UPDATE_APPROVER_DETAILS,
  approverDetails
});

export const updateBusinessGroup = selectedBusinessGroup => ({
  type: UPDATE_BUSINESS_GROUP_DETAILS,
  selectedBusinessGroup
});

export function resetAllFlags() {
  return function(dispatch) {
    dispatch({
      type: TAB_CHANGE_USER,
      usercannotdeleted: false,
      userDeleted: false,
      deletedUsername: false,
      userDataUpdated: false,
      addedUsername: false,
      newUserAdded: false,
      deletionStatusCheck: false,
      emailAlreadyUse: false
    });
  };
}

export function fetchUserData(
  type,
  sortkey = '',
  sortBy = '',
  action = '',
  search = ''
) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: search ? false : true
    });

    axios
      .get(
        constant.serviceUrls.CAMPAIGN_GET_USER_URL +
          '?group=' +
          type +
          (sortkey ? '&sortBy=' + sortkey : '') +
          (sortBy ? '&orderBy=' + sortBy : '') +
          (search ? '&search=' + search : '')
      )
      .then(response => {
        let userList = response.data;
        dispatch({ type: ASYNC_IN_PROGRESS_USER_MANAGEMENT, isLoading: false });
        dispatch({
          type: GET_USER_DATA,
          userList
        });
        if (type === 'approver') {
          axios
            .get(constant.serviceUrls.SERVICE_URL.COUNTRIES)
            .then(response => {
              let countryDetails = response.data;
              let countryDetailsSorted = _.orderBy(
                countryDetails.countries,
                ['name'],
                ['asc']
              );

              dispatch({
                type: GET_APPROVER_COUNTRIES,
                countryDetailsSorted
              });
              dispatch({
                type: APPROVER_COUNTRY_LOADED_SUCCESS,
                isLoading: false,
                payload: true
              });
            });

          axios
            .get(
              constant.serviceUrls.SERVICE_URL.GET_AUTOPOPULATED_BUSINESS_GROUP
            )
            .then(response => {
              let businessGroupList = response.data;
              let businessGroupListSorted = _.orderBy(
                businessGroupList,
                ['name'],
                ['asc']
              );
              dispatch({
                type: GET_BUSINESS_GROUP_LIST,
                businessGroupListSorted
              });
              dispatch({
                type: APPROVER_COUNTRY_LOADED_SUCCESS,
                isLoading: false,
                payload: true
              });
            });
        }

        if (action === 'tabchange') {
          dispatch({
            type: TAB_CHANGE_USER,
            usercannotdeleted: false,
            userDeleted: false,
            deletedUsername: false,
            userDataUpdated: false,
            addedUsername: false,
            newUserAdded: false,
            deletionStatusCheck: false,
            emailAlreadyUse: false
          });
        }
      });
  };
}

export function fetchApproverFilterData(type, filter, search = '') {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: search ? false : true
    });

    axios
      .post(
        constant.serviceUrls.CAMPAIGN_GET_USER_URL +
          '/filter?group=' +
          type +
          (search ? '&search=' + search : ''),
        {
          filter: filter
        }
      )
      .then(response => {
        let userList = response.data;
        dispatch({ type: ASYNC_IN_PROGRESS_USER_MANAGEMENT, isLoading: false });
        dispatch({
          type: GET_USER_DATA,
          userList
        });
      });
  };
}

export function addUserData(userdata, sortByKey) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });
    userdata['emailNotificationAccess'] = userdata['emailaccess']
      ? userdata['emailaccess']
      : false;
    userdata['loginAccess'] = userdata['loginaccess']
      ? userdata['loginaccess']
      : false;
    userdata['emailId'] = userdata['email']?.trim()?.toLowerCase();
    userdata['creationDate'] = Date.now();
    if (userdata['group'] === 'approver' || userdata['group'] === 'acs') {
      userdata['accessTo'] = [
        {
          emailCampaign: userdata['isemailcampaign']
            ? userdata['isemailcampaign']
            : false
        },
        {
          smsCampaign: userdata['issmscampaign']
            ? userdata['issmscampaign']
            : false
        },
        {
          formCampaign: userdata['isdatacampaign']
            ? userdata['isdatacampaign']
            : false
        },
        {
          waCampaign: userdata['iswacampaign']
            ? userdata['iswacampaign']
            : false
        }
      ];
    }

    if (
      userdata['group'] !== 'approver' &&
      userdata.hasOwnProperty('country')
    ) {
      delete userdata['country'];
    }
    const sortBy = sortByKey ? sortByKey : 'creationDate';
    // Add User Data
    return axios
      .post(constant.serviceUrls.CAMPAIGN_ADD_EDIT_USER_URL, {
        userdata
      })
      .then(response => {
        if (
          response.data.message &&
          response.data.message === 'Email already exist'
        ) {
          dispatch({
            type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
            isLoading: false
          });
          dispatch({
            type: USER_APPROVER_EMAIL_VALIDATION,
            emailApproverValidation: {}
          });
          dispatch({
            type: NEW_USER_DATA_ADDED,
            newUserAdded: false,
            emailAlreadyUse: true
          });
          // return true;
        } else if (response.data.approverValidation === true) {
          dispatch({
            type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
            isLoading: false
          });
          dispatch({
            type: USER_EMAIL_ALREADY_EXISTS,
            emailAlreadyUse: false
          });
          dispatch({
            type: USER_APPROVER_EMAIL_VALIDATION,
            emailApproverValidation: response.data
          });
          dispatch({
            type: NEW_USER_DATA_ADDED,
            newUserAdded: false,
            emailAlreadyUse: false
          });
        } else {
          dispatch({
            type: USER_APPROVER_EMAIL_VALIDATION,
            emailApproverValidation: {}
          });
          dispatch({
            type: USER_EMAIL_ALREADY_EXISTS,
            emailAlreadyUse: false
          });
          return axios
            .get(
              constant.serviceUrls.CAMPAIGN_GET_USER_URL +
                '?group=' +
                userdata['group'] +
                '&sortBy=' +
                sortBy +
                '&orderBy=desc'
            )
            .then(res => {
              let userList = res.data;
              dispatch({
                type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
                isLoading: false
              });
              dispatch({ type: GET_USER_DATA, userList });
              dispatch({
                type: NEW_USER_DATA_ADDED,
                newUserAdded: true,
                addedUsername: userdata['emailId'],
                userDataUpdated: false,
                userDeleted: false,
                deletedUsername: '',
                usercannotdeleted: false,
                emailAlreadyUse: false,
                emailApproverValidation: {}
              });
              //return true;
            });
        }
      });
  };
}

export function editUserData(userdata) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });

    userdata['emailNotificationAccess'] = userdata['emailaccess']
      ? userdata['emailaccess']
      : false;
    userdata['loginAccess'] = userdata['loginaccess']
      ? userdata['loginaccess']
      : false;
    userdata['emailId'] = userdata['email'];
    if (
      userdata['group'] !== 'approver' &&
      userdata.hasOwnProperty('country')
    ) {
      delete userdata['country'];
    }

    if (userdata['group'] === 'approver' || userdata['group'] === 'acs') {
      userdata['accessTo'] = [
        {
          emailCampaign: userdata['isemailcampaign']
            ? userdata['isemailcampaign']
            : false
        },
        {
          smsCampaign: userdata['issmscampaign']
            ? userdata['issmscampaign']
            : false
        },
        {
          formCampaign: userdata['isdatacampaign']
            ? userdata['isdatacampaign']
            : false
        },
        {
          waCampaign: userdata['iswacampaign']
            ? userdata['iswacampaign']
            : false
        }
      ];
    }

    // Edit User Data
    return axios
      .post(constant.serviceUrls.CAMPAIGN_ADD_EDIT_USER_URL, {
        userdata
      })
      .then(response => {
        if (
          response.data.message &&
          response.data.message === 'Email already exist'
        ) {
          dispatch({
            type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
            isLoading: false
          });
          dispatch({
            type: USER_APPROVER_EMAIL_VALIDATION,
            emailApproverValidation: {}
          });
          dispatch({
            type: UPDATE_USER_DATA,
            newUserAdded: false,
            emailAlreadyUse: true
          });
          return true;
        } else if (response.data.approverValidation === true) {
          dispatch({
            type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
            isLoading: false
          });
          dispatch({
            type: USER_APPROVER_EMAIL_VALIDATION,
            emailApproverValidation: response.data
          });
          dispatch({
            type: UPDATE_USER_DATA,
            newUserAdded: false,
            emailAlreadyUse: false
          });
        } else {
          dispatch({
            type: USER_APPROVER_EMAIL_VALIDATION,
            emailApproverValidation: {}
          });
          dispatch({
            type: USER_EMAIL_ALREADY_EXISTS,
            emailAlreadyUse: false
          });
          //Fetch update User list
          dispatch({
            type: UPDATE_USER_DATA,
            userDataUpdated: true,
            addedUsername: userdata['emailId'],
            newUserAdded: false,
            userDeleted: false,
            deletedUsername: '',
            usercannotdeleted: false,
            emailAlreadyUse: false,
            emailApproverValidation: {}
          });
          return axios
            .get(
              constant.serviceUrls.CAMPAIGN_GET_USER_URL +
                '?group=' +
                userdata['group'] +
                '&sortBy=creationDate&orderBy=desc'
            )
            .then(res => {
              let userList = res.data;

              dispatch({
                type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
                isLoading: false
              });
              dispatch({ type: GET_USER_DATA, userList });
              return true;
            });
        }
      });
  };
}

export function deleteUserData(
  type,
  username,
  userBusinessGroup,
  userCountry,
  sortByKey
) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });

    // Delete User
    const sortBy = sortByKey ? sortByKey : 'creationDate';
    return axios
      .post(constant.serviceUrls.CAMPAIGN_DELETE_USER_URL, {
        group: type,
        emailId: username,
        action: 'delete',
        userBusinessGroup,
        userCountry
      })
      .then(response => {
        if (
          response.data.message &&
          response.data.message === 'User deleted successfully'
        ) {
          dispatch({
            type: DELETE_USER,
            userDeleted: true,
            deletedUsername: username
          });
        } else if (
          response.data.message &&
          response.data.message === 'User cannot be deleted'
        ) {
          dispatch({ type: USER_CANNOT_DELETED, usercannotdeleted: true });
        }
        //Fetch update User list

        return axios
          .get(
            constant.serviceUrls.CAMPAIGN_GET_USER_URL +
              '?group=' +
              type +
              '&sortBy=' +
              sortBy +
              '&orderBy=desc'
          )
          .then(res => {
            let userList = res.data;

            dispatch({
              type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
              isLoading: false
            });
            dispatch({ type: GET_USER_DATA, userList });
            return true;
          });
      });
  };
}

export function checkDeletionStatus(type, emailId) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });

    // Delete User
    return axios
      .post(constant.serviceUrls.CAMPAIGN_DELETE_USER_URL, {
        group: type,
        emailId: emailId,
        action: 'confirm'
      })
      .then(response => {
        if (response.data) {
          dispatch({
            type: USER_CANNOT_DELETED,
            usercannotdeleted: true,
            deletionStatusCheck: true,
            userDataUpdated: false,
            addedUsername: '',
            newUserAdded: false,
            userDeleted: false,
            deletedUsername: '',
            emailAlreadyUse: false
          });
          dispatch({
            type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
            isLoading: false
          });
          return true;
        } else {
          dispatch({
            type: USER_CANNOT_DELETED,
            usercannotdeleted: false,
            deletionStatusCheck: false,
            userDataUpdated: false,
            addedUsername: '',
            newUserAdded: false,
            userDeleted: false,
            deletedUsername: '',
            emailAlreadyUse: false
          });
          dispatch({
            type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
            isLoading: false
          });
          return true;
        }
      });
  };
}

/**
 * Function to Save new release notes data
 * @param {*} releaseData
 * @returns response
 */
export function addReleaseNoteData(releaseData) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });
    let releaseDataPost = {};
    releaseDataPost['description'] = releaseData.descriptionText;
    releaseDataPost['title'] = releaseData.featureSummaryText;
    releaseDataPost['releaseDate'] = moment(releaseData.releasedateText);
    releaseDataPost['status'] = releaseData.status;
    releaseDataPost['featureCategory'] = releaseData.featureCategory;
    return axios
      .post(constant.serviceUrls.RELEASE_NOTE_URL, releaseDataPost)
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS_USER_MANAGEMENT, isLoading: false });
        if (response && response.data && response.status === 200) {
          dispatch({ type: GET_RELEASE_NOTE, response });
        } else {
          dispatch({ type: GET_RELEASE_NOTE, response });
        }
        return response;
      })
      .catch(error => {
        return error;
      });
  };
}

/**
 * Function to edit release notes data
 * @param {*} releaseData
 * @returns response
 */
export function editReleaseNoteData(id, releaseData) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });
    let releaseDataPost = {};
    releaseDataPost['description'] = releaseData.descriptionText;
    releaseDataPost['title'] = releaseData.featureSummaryText;
    releaseDataPost['releaseDate'] = moment(releaseData.releasedateText);
    releaseDataPost['status'] = releaseData.status;
    releaseDataPost['featureCategory'] = releaseData.featureCategory;
    return axios
      .post(`${constant.serviceUrls.RELEASE_NOTE_URL}/${id}`, releaseDataPost)
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS_USER_MANAGEMENT, isLoading: false });
        if (response && response.data && response.status === 200) {
          dispatch({ type: GET_RELEASE_NOTE, response });
        } else {
          dispatch({ type: GET_RELEASE_NOTE, response });
        }
        return response;
      })
      .catch(error => {
        return error;
      });
  };
}

/**
 * Function to delete release notes data
 * @param {*} id
 * @returns response
 */
export function deleteReleaseNoteData(id) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_USER_MANAGEMENT,
      isLoading: true
    });
    return axios
      .get(`${constant.serviceUrls.RELEASE_NOTE_URL}/${id}/delete`)
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS_USER_MANAGEMENT, isLoading: false });
        return response;
      })
      .catch(error => {
        return error;
      });
  };
}
