import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import waLocationImage from '../../../../images/location-image.png';

const WAChatHeader = ({ data, hasLTOOffer }) => {
  const [hasImageError, setHasImageError] = useState(false);
  const prevImage = usePrevious(data);
  let waHeaderFormatted = '';

  const waReplaceTags = (waBodyTextDynamicVariables, waBodyTextFormated) => {
    waBodyTextDynamicVariables.forEach(variable => {
      const { tagValue, value } = variable;
      waBodyTextFormated = waBodyTextFormated.replace(
        new RegExp(tagValue, 'g'),
        value
      );
    });
    return waBodyTextFormated;
  };

  if (data?.headerTextDynamicVariables && data?.headerText) {
    waHeaderFormatted = waReplaceTags(
      data.headerTextDynamicVariables,
      data.headerText
    );
  }

  useEffect(() => {
    if (data && data.type === 'headerImage' && prevImage) {
      if (data.headerImage !== prevImage.headerImage) {
        if (hasImageError) {
          setHasImageError(false);
        }
      }
    }
  }, [data]);

  if (!data) {
    return null;
  }

  if (data && data.type === 'headerText') {
    return (
      <div className="header-text-container">
        <span className="header-text">
          {waHeaderFormatted || data.headerText}
        </span>
      </div>
    );
  }

  const handleError = () => {
    if (!hasImageError) {
      setHasImageError(true);
    }
  };

  if (data && data.type === 'headerImage') {
    return (
      <div
        className={classNames('header-image-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        {data.headerImage && !hasImageError ? (
          <img
            src={data.headerImage}
            className={classNames('img-fluid header-img', {
              'rounded-top': true,
              'rounded-bottom': !hasLTOOffer
            })}
            alt="header-image"
            onError={handleError}
          />
        ) : (
          <div className="image-placeholder">
            <span className="image-placeholder-icon">
              <i className="far fa-image fa-lg"></i>
            </span>
          </div>
        )}
      </div>
    );
  }
  if (data && data.type === 'headerLocation') {
    return (
      <div
        className={classNames('header-image-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        {data?.headerLocation?.locationLatitude &&
        data?.headerLocation?.locationLongitude ? (
          <img
            src={waLocationImage}
            className={classNames('img-fluid header-img', {
              'rounded-top': true,
              'rounded-bottom': !hasLTOOffer
            })}
            alt="header-image"
            onError={handleError}
          />
        ) : (
          <div className="image-placeholder">
            <span className="image-placeholder-icon">
              <i className="fas fa-map-marker-alt fa-lg"></i>
            </span>
          </div>
        )}
        {(data?.headerLocation?.locationName ||
          data?.headerLocation?.locationAddress) && (
          <div className="location-bottom-container">
            {data?.headerLocation?.locationName && (
              <p className="header-text">
                {data?.headerLocation?.locationName}
              </p>
            )}
            {data?.headerLocation?.locationAddress && (
              <p className="">{data?.headerLocation?.locationAddress}</p>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default WAChatHeader;
