import React, { forwardRef, useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';

import { FormDatePicker } from '../FormDatePicker';
import WAltoGuidelines from '../WAltoGuidelines';
import moment from 'moment';
import { UnstyledButton } from 'components/atoms/UnstyledButton';
import classNames from 'classnames';

export const DateTimeInput = ({
  name,
  currentDate,
  control,
  errors,
  defaultValue,
  setValue,
  disabledValidDateCheck,
  disabled
}) => {
  return (
    <div className="form-row row-col-padding">
      <FormDatePicker
        name={`${name}.waLtoDetails.expirationDate`}
        label="Select offer expiration date"
        control={control}
        errors={errors}
        dateFormat={'DD/MM/YYYY'}
        timeFormat={false}
        defaultDisabled={false}
        defaultValue={get(defaultValue, `waLtoDetails.expirationDate`)}
        isValidDate={givenDate => {
          return disabledValidDateCheck
            ? true
            : givenDate.isSameOrAfter(currentDate, 'day');
        }}
        setValue={setValue}
        placeholder="DD/MM/YYYY"
        inputGroupClassName="datetime-width"
        disabled={disabled}
      />
    </div>
  );
};

export const WaLtoInput = ({
  name,
  rules,
  register,
  label,
  subLabel,
  errors,
  setValue,
  control,
  defaultValue,
  defaultWatchValue,
  waType,
  abTesting,
  ...props
}) => {
  const [isGuidelinesModalOpen, setIsGuidelinesModalOpen] = useState(false);
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  // Watch the ltoToggle field value
  const ltoToggleValue = useWatch({
    control,
    name: `${name}.waLtoDetails.ltoEnable`,
    defaultValue: get(defaultValue, `${name}.waLtoDetails.ltoSummary`)
      ? true
      : false
  });

  if (ltoToggleValue) {
    setValue('waFooterText', '');
  }

  const ltoExpirationValue = useWatch({
    control,
    name: `${name}.waLtoDetails.expirationEnable`,
    defaultValue: get(defaultValue, `${name}.waLtoDetails.expirationDate`)
      ? true
      : false
  });

  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(defaultValue, `${'carousel'}.messageType`)
  });

  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(defaultValue, `templateUse`)
  });

  const isTemplateReuse = templateUse === 'reuse';

  if (messageType && messageType.value === 'carousel') return null;

  return (
    <div
      className={classNames(
        'form-group email-secondary-theme',
        props.formGroupWrapperClass
      )}
      aria-live="polite"
    >
      <label htmlFor={props.id}>
        {label}
        <div className="description mb-20">
          The Limited-Time Offer (LTO) component lets you display an offer
          expiration details section in time-sensitive WhatsApp messages.{' '}
          <UnstyledButton
            className="cw-link-btn"
            onClick={() => setIsGuidelinesModalOpen(true)}
            type="button"
          >
            Learn more
          </UnstyledButton>
        </div>
      </label>
      <div>
        <div className="form-group d-flex mt-20 mb-1">
          <label className="switch-saas">
            <Controller
              control={control}
              name={`${name}.waLtoDetails.ltoEnable`}
              defaultValue={
                get(defaultValue, `${name}.waLtoDetails.ltoSummary`)
                  ? true
                  : false
              }
              render={({ field: { onChange, onBlur, value, ref, name } }) => (
                <Input
                  ref={ref}
                  name={name}
                  checked={value}
                  type="checkbox"
                  id="ltoEnable"
                  onChange={e => {
                    onChange(e);
                  }}
                  disabled={isTemplateReuse}
                />
              )}
            />
            <div className="slider-saas round"></div>
          </label>
          <label className="ml-20" htmlFor="ltoEnable">
            {ltoToggleValue
              ? 'Include limited time offers'
              : 'Do not include limited time offers'}
          </label>
        </div>

        {ltoToggleValue && (
          <>
            <div className="ml-22 pt-0 cw-form-nested">
              {/* Limited Time Offer Summary Text Field */}
              <div className="form-group mt-20">
                <label htmlFor={`${name}.waLtoDetails.ltoSummary`}>
                  Limited time offer summary text
                </label>
                <Controller
                  control={control}
                  name={`${name}.waLtoDetails.ltoSummary`}
                  defaultValue={get(
                    defaultValue,
                    `${name}.waLtoDetails.ltoSummary`,
                    ''
                  )}
                  rules={{ maxLength: 16 }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      maxLength="16"
                      placeholder="Enter your offer text"
                      id={`${name}.waLtoDetails.ltoSummary`}
                      hasError={
                        !!get(errors, `${name}.waLtoDetails.ltoSummary`)
                      }
                      disabled={isTemplateReuse}
                    />
                  )}
                />
                <FieldError
                  errors={errors}
                  name={`${name}.waLtoDetails.ltoSummary`}
                />
              </div>

              {/* Offer Has Expiration Date Toggle */}
              <div className="form-group d-flex mt-20">
                <div>
                  <label className="switch-saas">
                    <Controller
                      control={control}
                      name={`${name}.waLtoDetails.expirationEnable`}
                      defaultValue={
                        get(defaultValue, `${name}.waLtoDetails.expirationDate`)
                          ? true
                          : false
                      }
                      render={({
                        field: { onChange, onBlur, value, ref, name }
                      }) => (
                        <Input
                          ref={ref}
                          name={name}
                          checked={value}
                          type="checkbox"
                          id="expirationEnable"
                          onChange={e => {
                            onChange(e);
                          }}
                          disabled={isTemplateReuse}
                        />
                      )}
                    />
                    <div className="slider-saas round"></div>
                  </label>
                </div>
                <div className="d-flex flex-column">
                  <label className="ml-20" htmlFor="expirationEnable">
                    {ltoExpirationValue
                      ? 'Offer has an expiration date'
                      : 'Offer does not expire'}
                  </label>
                  {ltoExpirationValue && (
                    <p className="description mb-20 ml-20">
                      A text string with expiration timer will be displayed to
                      consumers. The text will change to red color if the
                      message is viewed and the offer code is expiring within
                      the next hour. This string is a default from Meta and
                      cannot be customized.
                    </p>
                  )}
                </div>
              </div>
              {ltoExpirationValue && (
                <>
                  <div className="ml-80 pt-0 cw-form-nested mb-20">
                    {/* Offer Expiration Date Picker */}
                    <div className="pt-0">
                      <DateTimeInput
                        name={name}
                        currentDate={moment()}
                        control={control}
                        errors={errors}
                        defaultValue={get(defaultValue, `${name}`)}
                        setValue={setValue}
                        placeholder="DD/MM/YYYY"
                        disabledValidDateCheck={false}
                        disabled={isTemplateReuse}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <FieldError errors={errors} name={`${name}.type`} />
      <WAltoGuidelines
        isOpen={isGuidelinesModalOpen}
        handleCloseForm={() => setIsGuidelinesModalOpen(false)}
      />
    </div>
  );
};
