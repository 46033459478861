export const defaultFilters = {
  country: [],
  brand: [],
  waType: [],
  status: [],
  lastUpdatedDate: '',
  abTesting: [],
  waWithIncentives: [],
  waContentThemes: [],
  waContentSecondaryThemes: [],
  showMyCampaigns: false
};

const filterByLabelList = ['countries', 'brands'];

export const getRequestBodyFromFilters = (filters = {}, userData) => {
  let result = {};

  Object.keys(filters).forEach(filterKey => {
    if (Array.isArray(filters[filterKey]) && filters[filterKey].length) {
      result = {
        ...result,
        [filterKey]: filters[filterKey].flatMap(data => data.value)
      };
    }

    if (filters[filterKey] && filters[filterKey].value) {
      result = {
        ...result,
        [filterKey]: filters[filterKey].value
      };
    }

    if (
      filters[filterKey] &&
      (typeof filters[filterKey] == 'string' ||
        typeof filters[filterKey] == 'number')
    ) {
      result = {
        ...result,
        [filterKey]: filters[filterKey]
      };
    }

    if (typeof filters[filterKey] == 'boolean') {
      const isMyCampaignsFilter = filterKey === 'showMyCampaigns';

      result = {
        ...result,
        [filterKey]: isMyCampaignsFilter
          ? filters[filterKey]
            ? `${encodeURIComponent(userData.emailId)}|${userData.group}`
            : ''
          : filters[filterKey].value
      };
    }
  });

  return result;
};

export const getFiltersCount = filters => {
  let total = 0;

  Object.keys(filters).forEach(filterKey => {
    if (Array.isArray(filters[filterKey])) {
      total += filters[filterKey].length;
    }

    if (filters[filterKey] && filters[filterKey].value) {
      total = filters[filterKey].value.length ? total + 1 : total;
    }

    if (typeof filters[filterKey] == 'boolean') {
      total = filters[filterKey] ? total + 1 : total;
    }
  });

  return total;
};
