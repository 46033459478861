import React, { useMemo, useState, forwardRef } from 'react';
import classNames from 'classnames';
import { useWatch, Controller } from 'react-hook-form';
import { Input } from 'components/atoms/Input';
import Modal from 'react-modal';
import WaDesignGuideline from 'components/WaDesignGuideline';
import constants from 'constants/config';
import { LinkButton } from 'components/atoms/LinkButton';
import ReactTooltip from 'react-tooltip';
import { get } from 'lodash';

const hasSameBrandMarketCombo = item => {
  let flag = true;
  const brand = get(item, 'brand.value');
  const country = get(item, 'country.value');
  const language = get(item, 'language.value');
  const combo = get(item, 'waDesign.brandMarket', '');
  if (!combo.includes(brand)) flag = false;
  if (!combo.includes(country)) flag = false;
  if (!combo.includes(language)) flag = false;
  return flag;
};

const TemplateUseInput = forwardRef(
  ({ onChange, value, name, setValue, reset, templateInfo }, ref) => {
    const handleOnChange = event => {
      if (event.target.value === 'modify') {
        setValue('templateName', '');
      }
      if (event.target.value === 'reuse') {
        if (templateInfo?.waDesign) {
          console.log(
            'templateInfo?.waDesign?.templateName',
            templateInfo?.waDesign?.templateName
          );
          reset({
            ...templateInfo?.waDesign,
            templateUse: 'reuse',
            templateName: templateInfo?.waDesign?.templateName,
            _id: undefined
          });
        }
      }
      onChange(event);
    };
    return (
      <>
        <label>How do you want to use this template?</label>

        <div>
          <div className="form-check mb-10" aria-live="polite">
            <ReactTooltip
              place="right"
              id="reuseInputHelp"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
            <Input
              name={`${name}`}
              type="radio"
              id="reuse"
              value="reuse"
              checked={value === 'reuse'}
              onChange={handleOnChange}
              ref={ref}
            />
            <label htmlFor="reuse" className="form-check-label">
              Reuse approved template without modifying
            </label>
            <span
              className="cw-icon"
              data-for="reuseInputHelp"
              data-tip={
                'You will not be able to modify the template as it is already approved by META. You can reuse it as is and we will not send it to META again for approval'
              }
            >
              {' '}
              <i className="fas fa-info-circle"></i>
            </span>
          </div>
        </div>

        <div>
          <div className="form-check mb-10" aria-live="polite">
            <ReactTooltip
              place="right"
              id="modifyInputHelp"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
            <Input
              name={`${name}`}
              type="radio"
              id="modify"
              value="modify"
              checked={value === 'modify'}
              onChange={handleOnChange}
              ref={ref}
            />
            <label htmlFor="modify" className="form-check-label">
              Modify template to create a new one
            </label>
            <span
              className="cw-icon"
              data-for="modifyInputHelp"
              data-tip={
                'You can use this template as a starting point and modify it. This will be considered as a new template and we will send it to META for approval'
              }
            >
              {' '}
              <i className="fas fa-info-circle"></i>
            </span>
          </div>
        </div>
      </>
    );
  }
);

export const WATemplateUsageInput = ({
  name,
  register,
  defaultValue,
  ...props
}) => {
  const [openGuideLineModel, setOpenGuideLineModel] = useState(false);
  const templateUse = useWatch({
    control: props.control,
    name: name,
    defaultValue: get(defaultValue, `waDesign.templateUse`)
  });

  const hasSameCombo = useMemo(() => {
    return hasSameBrandMarketCombo(defaultValue);
  }, [defaultValue]);

  const defultTemplateUse = get(defaultValue, 'waDesign.templateUse');

  const handleOpenGuideline = () => {
    setOpenGuideLineModel(true);
  };

  const handleCloseGuideline = () => {
    setOpenGuideLineModel(false);
  };

  return (
    <div className={classNames('form-group', props.formGroupWrapperClass)}>
      {(templateUse === 'new' || templateUse === 'modify') && (
        <div className={`alert alert-info`} role="alert">
          You are creating a new message template. This template will be sent to
          META for approval once you submit the campaign and it is approved by
          an in-market approver. Only META approved templates can be sent to
          consumers.{' '}
          <LinkButton onClick={handleOpenGuideline}>
            Learn more about WhatsApp templates
          </LinkButton>
        </div>
      )}

      {hasSameCombo && (
        <Controller
          name={`${name}`}
          defaultValue={defultTemplateUse}
          control={props.control}
          render={({ field }) => (
            <TemplateUseInput
              {...field}
              setValue={props.setValue}
              templateInfo={props.templateInfo}
              reset={props.reset}
            />
          )}
        />
      )}

      <Modal
        scrollable={true}
        isOpen={openGuideLineModel}
        onRequestClose={handleCloseGuideline}
        className="cw-modal cw-modal--qnadetail"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <WaDesignGuideline onClose={handleCloseGuideline} />
      </Modal>
    </div>
  );
};
