import {
  FETCH_WA_CORE_TEMPLATES,
  FETCH_WA_CORE_TEMPLATES_SUCCESS,
  FETCH_WA_CORE_TEMPLATES_FAILURE,
  DELETE_WA_CORE_TEMPLATE,
  DELETE_WA_CORE_TEMPLATE_SUCCESS,
  DELETE_WA_CORE_TEMPLATE_FAILURE,
  SET_WA_CORE_TEMPLATES_LOADER
} from '../../actionTypes';
import {
  getWACoreTemplates as getWACoreTemplatesService,
  deleteWACoreTemplate as deleteWACoreTemplateService
} from '../../services/waBriefings';

export const getWACoreTemplates = (query, pageNumber = 1) => {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_WA_CORE_TEMPLATES });
      const result = await getWACoreTemplatesService(query, pageNumber);
      dispatch({ type: FETCH_WA_CORE_TEMPLATES_SUCCESS, payload: result });
    } catch (error) {
      if (error.message !== 'canceled') {
        dispatch({
          type: FETCH_WA_CORE_TEMPLATES_FAILURE,
          payload: { message: error.message }
        });
      }
    }
  };
};

export function deleteWACoreTemplate(id) {
  return async function(dispatch) {
    try {
      dispatch({ type: DELETE_WA_CORE_TEMPLATE });
      await deleteWACoreTemplateService(id);
      dispatch({ type: DELETE_WA_CORE_TEMPLATE_SUCCESS, payload: id });
    } catch (error) {
      dispatch({
        type: DELETE_WA_CORE_TEMPLATE_FAILURE,
        payload: { message: error.message }
      });
    }
  };
}

export const setWATemplateLoader = payload => ({
  type: SET_WA_CORE_TEMPLATES_LOADER,
  payload
});
