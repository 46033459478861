import React, { useRef, useState, useEffect } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import Input from './Input';

import constants from 'constants/config';
import { get } from 'lodash';
import variableOptionsData from './variableOptionsData';

export const EnhancedTextArea = ({
  name,
  id,
  defaultValue,
  control,
  label,
  errors,
  maxLength,
  helpText,
  subText,
  setValue,
  labelTooltip,
  flow,
  isDynamicCoupon,
  placeholderTooltipText,
  optOutTextLength,
  insertingURLFollowup,
  insertingURLMain,
  setInsertingURLMain,
  setInsertingURLFollowup,
  customHelpComponent,
  customHelpComponentProps,
  detectUnicodeContent,
  optOutText,
  hideInsertUrl,
  maxLengthDisplay,
  fnameOnlyOnce,
  watch,
  smsDetails,
  waDesign,
  formGroupWrapperClass,
  showEmojiButton,
  showAddVariables,
  showInput = false,
  placeholder,
  register,
  defaultValueDynamicVar,
  disabled
}) => {
  //--- tag add start -----
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name + 'DynamicVariables'}`
  });
  const mentionsContent = useWatch({ control, name: name });
  const editorRef = useRef(null);
  const [tagLimits, setTagLimits] = useState(
    variableOptionsData.reduce(
      (acc, tag) => ({ ...acc, [tag.value]: tag.maxSelections }),
      {}
    )
  );

  useEffect(() => {
    // sets the DB value
    setValue(name + 'DynamicVariables', defaultValueDynamicVar);
  }, [defaultValueDynamicVar]);
  console.log('-----------name2-----------', name);
  const handleTagInsert = selectedOption => {
    console.log('inside handleTagInsert 1', name);
    if (tagLimits[selectedOption.value] > 0) {
      const updatedContent = `${mentionsContent}${selectedOption.value} `;
      console.log('inside handleTagInsert 2', mentionsContent, updatedContent);

      // Update selection count
      setTagLimits(prevLimits => ({
        ...prevLimits,
        [selectedOption.value]: prevLimits[selectedOption.value] - 1
      }));

      // Update content and add to textBoxes array
      setValue(name, updatedContent);
      append({
        label: selectedOption.label,
        value: selectedOption.defaultValue,
        tagValue: selectedOption.value
      });
    }
  };

  const handleDeleteTextBox = (index, tagValue) => {
    remove(index); // Remove from textBoxes array
    setValue(
      name,
      mentionsContent.replace(new RegExp(`@${fields[index].label}`, 'g'), '') // Remove from mentions content
    );

    // Restore tag limit
    setTagLimits(prevLimits => ({
      ...prevLimits,
      [tagValue]: (prevLimits[tagValue] || 0) + 1
    }));
  };

  // Handles content change in the editor
  const handleEditorChange = content => {
    setValue(name, content);

    // Identify and remove tags no longer present in the editor
    fields.forEach((field, index) => {
      if (!content.includes(field.tagValue)) {
        remove(index); // Remove from textBoxes
        setTagLimits(prevLimits => ({
          ...prevLimits,
          [field.tagValue]: (prevLimits[field.tagValue] || 0) + 1
        }));
      }
    });
  };

  // Removes a tag directly from the editor content
  const removeTagFromEditor = tagValue => {
    const tag = fields.find(field => field.tagValue === tagValue);
    console.log('---- mail function called --- ', tagValue, tag);
    if (tag) {
      const updatedContent = mentionsContent.replace(tag.tagValue, '').trim();
      setValue(name, updatedContent);

      // Update tag limits
      setTagLimits(prevLimits => ({
        ...prevLimits,
        [tagValue]: (prevLimits[tagValue] || 0) + 1
      }));
    }
  };

  //---- tag add end -----

  const watchValues = useWatch({
    control,
    name: watch,
    defaultValue: { shortURL: constants.SAMPLE_BITLY_URL, fullURL: '' }
  });

  const ltoToggle = useWatch({
    control,
    name: `waOffer.waLtoDetails.ltoEnable`,
    defaultValue: get(waDesign, `waOffer.waLtoDetails.ltoSummary`)
      ? true
      : false
  });

  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(waDesign, `${'carousel'}.messageType`)
  });

  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(waDesign, `templateUse`)
  });

  if (!showInput && messageType && messageType.value === 'carousel')
    return null;
  const isDisbledDueToLTO = ltoToggle && name === 'waFooterText';
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          {...field}
          label={label}
          id={id}
          errors={errors}
          maxLength={maxLength}
          helpText={helpText}
          defaultValue={defaultValue}
          subText={subText}
          labelTooltip={labelTooltip}
          setValue={setValue}
          showEmojis={false}
          showCharactorCount={true}
          flow={flow}
          isDynamicCoupon={isDynamicCoupon}
          placeholderTooltipText={placeholderTooltipText}
          optOutTextLength={optOutTextLength}
          insertingURLMain={insertingURLMain}
          insertingURLFollowup={insertingURLFollowup}
          setInsertingURLMain={setInsertingURLMain}
          setInsertingURLFollowup={setInsertingURLFollowup}
          customHelpComponent={customHelpComponent}
          customHelpComponentProps={customHelpComponentProps}
          detectUnicodeContent={detectUnicodeContent}
          optOutText={optOutText}
          hideInsertUrl={hideInsertUrl}
          maxLengthDisplay={maxLengthDisplay}
          fnameOnlyOnce={fnameOnlyOnce}
          watchValues={watchValues}
          smsDetails={smsDetails}
          disabled={disabled || templateUse === 'reuse' || isDisbledDueToLTO}
          hasLTO={ltoToggle}
          formGroupWrapperClass={formGroupWrapperClass}
          showEmojiButton={showEmojiButton}
          showAddVariables={showAddVariables}
          placeholder={placeholder}
          variableOptionsData={variableOptionsData}
          tagLimits={tagLimits}
          handleTagInsert={handleTagInsert}
          handleEditorChange={handleEditorChange}
          removeTagFromEditor={removeTagFromEditor}
          fields={fields}
          remove={remove}
          handleDeleteTextBox={handleDeleteTextBox}
          register={register}
          defaultValueDynamicVar={defaultValueDynamicVar}
          control={control}
        />
      )}
    />
  );
};
