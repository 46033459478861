import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getWATabularData } from './data';
import EmailTabular from 'components/organisms/EmailTabular/EmailTabular';
import WARequestInfoSubmitComp from 'components/organisms/WARequestInfoSubmit';

import constants from 'constants/config';

function WAOverviewTables({
  waCampaign,
  editable,
  review,
  errors = {},
  match,
  history,
  handleRequestInfoAnswer,
  requestInfoAnswerFlag,
  downloadTrigger,
  downloadPOTrigger
}) {
  const campaignConfig = useSelector(state => state.app.campaignConfig);

  const { authorizedUserData } = useSelector(state => state.authorized);

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  };

  if (!waCampaign) return null;

  const {
    brandDetails,
    waType,
    campaignDetails,
    audienceDetails,
    designProperties,
    deliveryScheduleData,
    campaignAttributes
  } = getWATabularData(waCampaign, campaignConfig, currentUser, review);

  const onClickEdit = page => {
    const uid = match.params.id;
    history.push({
      pathname: `${constants.WA_BASE_ROUTE}/${uid}/${page}`,
      state: {
        flyoutOpen: true,
        isEdited: review
      }
    });
  };

  const editProps = redirectTo =>
    editable ? { onClickEdit: () => onClickEdit(redirectTo) } : {};

  /**
   * Download cost estimate on click of file name
   */
  const handleDownloadCostEstimateClick = (file, setLoader) => {
    downloadTrigger && downloadTrigger(file, setLoader);
  };
  const downloadCostEstimateProps = () => {
    return {
      handleDownloadCostEstimateClick: (file, setLoader) =>
        handleDownloadCostEstimateClick(file, setLoader)
    };
  };

  /**
   * Download PO on click of file name
   */
  const handleDownloadPOClick = (file, setLoader) => {
    downloadPOTrigger && downloadPOTrigger(file, setLoader);
  };
  const downloadPOProps = () => {
    return {
      handleDownloadPOClick: (file, setLoader) =>
        handleDownloadPOClick(file, setLoader)
    };
  };

  return (
    <div className="sms-overview-tables wa-overview-tables">
      {review ? (
        <>
          <EmailTabular
            data={brandDetails}
            title="Brand summary"
            {...editProps(`${constants.WA_ROUTES.BRAND_DETAILS}`)}
          />
          <EmailTabular
            data={waType}
            title="Campaign type"
            {...editProps(`${constants.WA_ROUTES.WA_TYPE}`)}
          />
        </>
      ) : null}

      <EmailTabular
        data={campaignDetails}
        title={'Campaign summary'}
        isError={errors.summary}
        {...editProps(`${constants.WA_ROUTES.CAMPAIGN_SUMMARY}`)}
        {...downloadCostEstimateProps()}
        {...downloadPOProps()}
      />
      <EmailTabular
        data={campaignAttributes}
        title={'Campaign attributes'}
        isError={errors.campaignAttributes}
        errorDetails={errors.dynamicCouponError}
        {...editProps(`${constants.WA_ROUTES.CAMPAIN_ATTRIBUTES}`)}
      />
      <EmailTabular
        data={deliveryScheduleData}
        title={'Delivery schedule'}
        isError={errors.deliverySchedule}
        errorDetails={errors.deliveryScheduleError}
        {...editProps(`${constants.WA_ROUTES.SCHEDULE}`)}
      />
      <EmailTabular
        data={audienceDetails}
        title={'Audience segment'}
        isError={errors.audienceDefinition}
        {...editProps(`${constants.WA_ROUTES.AUDIENCE}`)}
      />
      <EmailTabular
        data={designProperties}
        title={'WhatsApp design'}
        isError={errors.waDesign}
        {...editProps(`${constants.WA_ROUTES.DESIGN}`)}
      />
      {waCampaign && waCampaign.requestInfoText && requestInfoAnswerFlag ? (
        <WARequestInfoSubmitComp
          waDetails={waCampaign}
          title={'Response'}
          isError={errors.requestAnswererror}
          handleRequestInfoAnswer={handleRequestInfoAnswer}
        />
      ) : null}
    </div>
  );
}

export default withRouter(WAOverviewTables);
